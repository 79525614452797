export interface GuestUser {
    geoCode?: string
    name?: string
    email?: string
    phoneNumber?: string
    conditions?: boolean
    contact?: boolean
    companyName?: string
    firstName?: string
    lastName?: string
    annualRevenue?: string
    leadTypeId?: string
    primaryIndustryId?: string
    childIndustryId?: string
    yearEstablished?: number
    numberOfEmployees?: number
    software?: string
    sector?: string
    state?: string
    zipCode?: string
    address?: string
}
const GUEST_USER = 'guest_user'

export function setGuestUser(values: GuestUser) {
    localStorage.setItem(GUEST_USER, JSON.stringify(values))
}

export function getGuestUser(): GuestUser {
    return JSON.parse(localStorage.getItem(GUEST_USER) || '{}') as GuestUser
}

export function removeGuestUser() {
    localStorage.removeItem(GUEST_USER)
}
