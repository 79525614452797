import OptionElement from 'components/src/MultiStepForm/SelectStepForm/Option'
import React, { useCallback } from 'react'
import Page, { Graphics } from 'components/src/layout/Page'
import Heading from 'components/src/texts/Heading'
import Flex from 'components/src/layout/Flex'
import Text from 'components/src/texts/Text'
import { CircleFlag } from 'react-circle-flags'
import { useTranslation } from 'react-i18next'
import LoginForm from './SignUp/Login'

const markets = [ 'dk', 'se', 'no', 'nl', 'de', 'us' ]

function LogIn() {
    const { t } = useTranslation('logIn')
    const { t: countries } = useTranslation('countries')

    const onCountrySelected = useCallback((market: string) => () => {
        window.location.href = `/start?geo_code=${market}`
    }, [])

    return (
        <Page as={Flex} justifyContent="center" alignItems="center">
            <Graphics graphics="primary" />
            <Flex maxWidth="1000px" flexGrow={1}>
                <Flex flexDirection="column" flexGrow={2} alignItems="center">
                    <Flex as={Heading} size="medium">{t('continue_as_guest')}</Flex>
                    {markets.map(market => (
                        <Flex key={market} mt="20px">
                            <OptionElement
                                selected={false}
                                select={onCountrySelected(market)}
                                option={{
                                    id: 1,
                                    name: <Flex alignItems="flex-end" flexGrow={1} justifyContent="space-between">
                                        <CircleFlag
                                            countryCode={market}
                                            width={24}
                                            height={24}
                                        />
                                        <Text>{countries(market.toUpperCase())}</Text>
                                    </Flex>,
                                }}
                            />
                        </Flex>
                    ))}
                </Flex>
                <Flex as={Heading} size="medium" flexGrow={1} textAlign="center">{t('or')}</Flex>
                <Flex flexDirection="column" flexGrow={2}>
                    <Flex as={Heading} size="medium" textAlign="center" mb={40}>{t('log_in')}</Flex>
                    <LoginForm/>
                </Flex>
            </Flex>
        </Page>
    )
}

export default LogIn
