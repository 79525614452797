import React, { useCallback } from 'react'
import Select from 'components/src/inputs/Select'
import { useSearchParams } from 'react-router-dom'
import Flex from 'components/src/layout/Flex'
import { useTranslation } from 'react-i18next'
import { partnersSearchArrangeParam } from '../../utils'

const options: Record<string, string> = {
    'partner_rating-desc': 'Highest Rated',
    'TODO': 'Closest Proximity',
    'partner_rating_count-desc': 'Most Rated',
    'partner_rating-asc': 'Lowest Rated',
}

export interface ArrangeBySectionProps {
    handleArrangeChange: (selected: string) => void
}

const ArrangeBySection = (props: ArrangeBySectionProps) => {
    const { handleArrangeChange } = props
    const [ searchParams ] = useSearchParams()
    const arrangeValue: string = searchParams.get(partnersSearchArrangeParam) || ''
    const { t } = useTranslation('searchResultList')

    return <Flex width="300px">
        <Select
            items={Object.keys(options)}
            placeholder={t('arrange_filters.arrange_by')}
            selected={arrangeValue}
            onChange={handleArrangeChange}
            itemToLabel={useCallback(item => options[item], [])}
        />
    </Flex>
}

export default ArrangeBySection
