import styled from 'styled-components'
import { grid, GridProps, space, SpaceProps, AlignItemsProps, JustifyItemsProps, alignItems, justifyItems } from 'styled-system'

const Grid = styled.div<GridProps & SpaceProps & AlignItemsProps & JustifyItemsProps>`
    display: grid;
    ${grid};
    ${space};
    ${alignItems};
    ${justifyItems};
`

export default Grid
