import React from 'react'
import { Field, Form, Formik, FormikProps } from 'formik'
import Flex from 'components/src/layout/Flex'
import InputGroup from 'components/src/inputs/Input'
import Select from 'components/src/inputs/Select'
import Button from 'components/src/Button'
import { SchemaOf } from 'yup'
import * as yup from 'yup'
import { LeadResource } from 'ageras-api-client/src/api'
import Card from 'components/src/Card'
import Heading from 'components/src/texts/Heading'
import Text from 'components/src/texts/Text'
import { useTranslation } from 'react-i18next'

interface ContactInfoFormValues {
    customerInfo: {
        firstName: string | undefined
        lastName: string | undefined
        email: string | undefined
        phone: string | undefined
        customerType: {
            id: number | undefined
        }
    }
    geo: {
        zipCode: string | undefined
        address: string | undefined
    }
}

function ContactInfoForm(props: FormikProps<ContactInfoFormValues> & {
    lead: LeadResource
}) {
    const { t } = useTranslation('quoteDetail')
    const { lead } = props
    const customerTypeOption = {
        id: lead?.customerInfo?.customerType?.id,
        label: lead?.customerInfo?.customerType?.identifier,
    }

    const isChanged = JSON.stringify(props.initialValues) !== JSON.stringify(props.values)

    return (
        <Form>
            <Flex pt={8} px={8} pb={4} flexDirection="column">
                <Heading size="medium" color="impact" mb={3}>{t('your_details')}</Heading>
                <Text mb={4}>{t('we_are_on_your_way')}</Text>
                <Flex flexDirection="column">
                    <Field name="customerInfo.firstName" label="First name" as={InputGroup} error={props?.errors?.customerInfo?.firstName}/>
                    <Field name="customerInfo.lastName" label="Last name" as={InputGroup} error={props?.errors?.customerInfo?.lastName}/>
                    <Field name="customerInfo.email" label="Email" as={InputGroup} error={props?.errors?.customerInfo?.email}/>
                    <Field name="customerInfo.phone" label="Phone" as={InputGroup} error={props?.errors?.customerInfo?.phone}/>
                    <Field name="customerInfo.customerType.id" component={Select} items={[ customerTypeOption ]} disabled/>
                </Flex>
            </Flex>
            <Card.Line />
            <Flex px={8} pt={4} flexDirection="column">
                <Heading size="medium" color="impact" mb={3}>
                    {t('company_details')}
                </Heading>
                <Flex flexDirection="column">
                    <Field name="geo.address" label="Address" as={InputGroup} error={props?.errors?.geo?.address}/>
                    <Field name="geo.zipCode" label="Zip code" as={InputGroup} error={props?.errors?.geo?.zipCode} disabled/>
                </Flex>
                {isChanged && <Button variant="ternary" type="submit" mt={4}>
                    {t('save_details')}
                </Button>}
            </Flex>
        </Form>
    )
}

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

function handleSubmit(onSubmit: ContactInfoFormProps['onSubmit']) {
    return (data: ContactInfoFormValues) => {
        onSubmit(data)
    }
}

interface ContactInfoFormProps {
    lead: LeadResource
    onSubmit: (data: LeadResource) => void
}

function ContactInfoFormBlock(props: ContactInfoFormProps) {
    const { lead } = props
    const { t } = useTranslation('validation')

    const formValidationSchema: SchemaOf<ContactInfoFormValues> = yup.object().shape({
        customerInfo: yup.object().shape({
            firstName: yup.string().required(t('required')),
            lastName: yup.string().required(t('required')),
            email: yup.string().email(t('invalid_email')).required(t('required')),
            phone: yup.string().matches(phoneRegExp, t('not_valid_phone_number')).required(t('required')),
            customerType: yup.object().shape({
                id: yup.number().required().positive().integer(),
            }),
        }),
        geo: yup.object().shape({
            zipCode: yup.string().required(t('required')),
            address: yup.string().required(t('required')),
        }),
    })

    return (
        <Formik
            initialValues={{
                customerInfo: {
                    firstName: lead.customerInfo?.firstName,
                    lastName: lead.customerInfo?.lastName,
                    email: lead.customerInfo?.email,
                    phone: lead.customerInfo?.phone,
                    customerType: {
                        id: lead.customerInfo?.customerType?.id,
                    },
                },
                geo: {
                    zipCode: lead.geo?.zipCode,
                    address: lead.geo?.address,
                },
            }}
            onSubmit={handleSubmit(props.onSubmit)}
            validationSchema={formValidationSchema}
        >
            {(formProps) => <ContactInfoForm {...formProps} lead={lead} />}
        </Formik>
    )
}

export default ContactInfoFormBlock
