import styled from 'styled-components'
import { variant, space, SpaceProps } from 'styled-system'

type PageTypes = 'base' | 'grey'
type PageGraphicsTypes = 'primary' | 'left'

export interface PageProps extends SpaceProps {
    variant?: PageTypes
    graphics?: PageGraphicsTypes
}

export const Graphics = styled.div<PageProps>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    ${variant({
        prop: 'graphics',
        scale: 'pages.graphics',
    })}
`

const Page = styled.div<PageProps>`
    flex-grow: 1;
    height: 100%;
    ${space};

    ${variant({
        scale: 'pages.colors',
    })};

    & > *:not(${Graphics}) {
        z-index: 1;
    }
`

Page.defaultProps = {
    variant: 'base',
}

export default Page
