import React from 'react'
import styled from 'styled-components'
import { layout, LayoutProps, space, SpaceProps, variant, zIndex, ZIndexProps } from 'styled-system'
import { ModalProps } from './index'
import Card from '../Card'

const Container = styled.div<ZIndexProps>`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
    ${zIndex}
`

interface ModalContentProps extends LayoutProps, SpaceProps {
    size?: 'extraSmall' | 'small' | 'medium' | 'large'
}

const ModalContent = styled(Card)<ModalContentProps>`
    ${space};
    ${layout};
    ${variant({
        prop: 'size',
        scale: 'modals.variants',
    })}
`

ModalContent.defaultProps = {
    size: 'small',
}

function Modal(props: React.PropsWithChildren<ModalProps & ModalContentProps>) {
    return <Container zIndex={props.zIndex}>
        <ModalContent size={props.size}>
            {props.children}
        </ModalContent>
    </Container>
}

export default Modal
