import React, { InputHTMLAttributes, ReactElement, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Error, { ErrorProps } from './Error'
import { DeprecatedFormGroup } from './FormGroup'
import Flex from '../layout/Flex'
import { useTheme } from 'styled-components'
import Theme from '../themes/Theme'

interface StyledFormInputProps {
    min?: number
    type?: InputHTMLAttributes<typeof Input>['type']
    error?: boolean
    warning?: boolean
    tabIndex?: InputHTMLAttributes<typeof Input>['tabIndex']
}

const StyledIcon = styled(Flex)`
    & > img {
        width: auto;
    }
`

const StyledFormInput = styled.input.attrs(({ type }) => ({ type: type || 'text' }))<StyledFormInputProps>`
    width: 100%;
    border: none;
    outline: none;
    ${({ theme }) => theme.text.sizes.medium};
    padding: 0.66em 0.66em;
    color: ${({ theme }) => theme.inputs.colors.base};
    border-radius: inherit;
    background: inherit;
    z-index: 1;

    ::placeholder {
      color: ${({ theme }) => theme.inputs.colors.placeholder};
      opacity: 1;
      ${({ theme }) => theme.text.sizes.medium};
    }
    &:disabled {
        cursor: not-allowed;
        color: ${({ theme }) => theme.inputs.colors.disabled};
        border-color: ${({ theme }) => theme.inputs.colors.disabled};
    }

    ${({ error, warning, theme }) => (error || warning) && css`
        color: ${theme.inputs.colors.error};
    `}

    ${({ readOnly }) => (readOnly) && css`
        cursor: default;
    `}
`

StyledFormInput.defaultProps = {
    placeholder: ' ',
}

interface InputFuncProps {
    label?: string
    value?: string
    name?: string
    required?: boolean
    placeholder?: string
    onChange?: (event: SyntheticEvent<HTMLInputElement>) => void
    onFocus?: (event: SyntheticEvent<HTMLInputElement>) => void
    onBlur?: (event: SyntheticEvent<HTMLInputElement>) => void
    onClick?: (event: SyntheticEvent<HTMLInputElement>) => void
    icon?: ReactElement
    type?: InputHTMLAttributes<typeof Input>['type']
    readOnly?: InputHTMLAttributes<typeof Input>['readOnly']
    step?: string
    disabled?: InputHTMLAttributes<typeof Input>['disabled']
}

export type InputProps = InputFuncProps & StyledFormInputProps

export function Input(props: InputProps) {
    const theme = useTheme() as Theme
    return(
        <Flex
            border="1px solid"
            borderRadius={8}
            borderColor={props.error || props.warning ? theme.inputs.colors.error : props.disabled ? theme.inputs.colors.disabled :  theme.inputs.colors.active}
            position="relative"
            pl={props.icon ? 2 : undefined}
            alignItems="center"
            justifyContent="center"
            background={theme.inputs.colors.background}
            flexShrink="0"
            flexGrow="1"
        >
            {props.icon && <StyledIcon alignItems="center">{props.icon}</StyledIcon>}
            <StyledFormInput {...props}/>
        </Flex>
    )
}

export type InputGroupProps = InputFuncProps & ErrorProps

const InputGroup = ({
    error,
    warning,
    type,
    ...props
}: InputGroupProps) => {
    return (
        <DeprecatedFormGroup p={2} mt={2}>
            <Input
                type={type}
                error={Boolean(error)}
                {...props}
            />
            <Error error={error} warning={warning}/>
        </DeprecatedFormGroup>
    )
}

export default InputGroup
