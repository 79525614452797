import { useAgerasApi } from 'ageras-api-client/src'
import { IndustriesApiFetchParamCreator, IndustryResource, IndustryResult } from 'ageras-api-client/src/api'
import SelectStepForm from 'components/src/MultiStepForm/SelectStepForm'
import { Option } from 'components/src/MultiStepForm/SelectStepForm/Option'
import { useCallback } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { getQueryPreselectedOption, getStepUrl, SetupStepProps } from '.'
import { partnersSearchParams } from '../../utils'
import getBasicAuth from '../../utils/getBasicAuth'
import { useTranslation } from 'react-i18next'

const HIDDEN_SUBINDUSTRIES = {
    dk: [],
    no: [],
    se: [],
    de: [],
    nl: [
        538, // Ageras Inkomstenbelasting
    ],
}

const getVisibleSubindustries = (geoCode: string, subindustries: Array<IndustryResource> | undefined) => {
    if (subindustries == null) {
        return []
    }

    if (!HIDDEN_SUBINDUSTRIES.hasOwnProperty(geoCode)) {
        return subindustries
    }

    type availableGeoCodes = keyof typeof HIDDEN_SUBINDUSTRIES
    return subindustries.filter(subindustry => !(HIDDEN_SUBINDUSTRIES[geoCode as availableGeoCodes] as number[])
        .includes(Number(subindustry.id)))
}

function SubindustryStep(props: SetupStepProps) {
    const { step, geoCode } = props
    const [ queryParameters ] = useSearchParams()
    const navigate = useNavigate()
    const industryId = queryParameters.get(partnersSearchParams.industry)
    const { t } = useTranslation('searchSetup')

    const { isLoading, data: subindustries } = useAgerasApi<IndustryResult>(
        IndustriesApiFetchParamCreator().industriesIndex(
            undefined,
            undefined,
            geoCode,
            undefined,
            undefined,
            industryId || undefined,
        ),
        { meta: { authorization: getBasicAuth() } },
    )

    const handleSubmit = useCallback((option: Option) => {
        queryParameters.set(step.queryParameterName, option.id.toString())

        navigate({
            pathname: getStepUrl(step.nextStepId || 1),
            search: queryParameters.toString(),
        })
    }, [ queryParameters, step.queryParameterName, step.nextStepId, navigate ])

    const visibleSubindustries = getVisibleSubindustries(String(geoCode), subindustries?.data)
    const options = visibleSubindustries.map((subindustry: IndustryResource) => ({
        id: subindustry.id,
        name: subindustry.name,
    } as Option))

    const selectedOption = getQueryPreselectedOption(step, options, queryParameters)

    return (
        <SelectStepForm
            header={t('subindustry_header')}
            subheader={t('subindustry_subheader')}
            submit={handleSubmit}
            options={options}
            actionButtonText={t('select')}
            loading={isLoading}
            preselectedOption={selectedOption}
            columns={5}
        />
    )
}

export default SubindustryStep
