import React, {
    useState,
    useCallback, SyntheticEvent,
} from 'react'
import styled from 'styled-components'
import Flex from '../../layout/Flex'
import {
    useComponentVisible,
    Chevron,
    Dropdown,
    DropdownBody,
    Option,
    SelectProps,
} from '../Select'
import { Input } from '../Input'
import Text from '../../texts/Text'

const Checkbox = styled.input.attrs({ type: 'checkbox' })``

export interface MultiSelectProps<Value> extends Omit<SelectProps<Value>,  'selected' | 'onChange'> {
    selected: Value[]
    onChange: (selected: Value[]) => void
}

export type ComponentProps<Value> = MultiSelectProps<Value>

const MultiSelect = <Value,>({ itemToLabel, items, placeholder, selected, disabled, onChange }: ComponentProps<Value>) => {
    const [ isOpen, setOpen ] = useState<boolean>(false)
    const ref = useComponentVisible<HTMLDivElement>(setOpen)

    const counter: number = selected.length

    const toggleDropdown = useCallback((e: SyntheticEvent) => {
        !disabled && setOpen(!isOpen)
        e.stopPropagation()
        e.preventDefault()
    }, [ disabled, isOpen ])

    const onOptionClick = useCallback((value: Value) => () => {
        if (selected.includes(value)) {
            onChange(selected.filter(v => v !== value))
            return
        }
        onChange([ ...selected, value ])
    }, [ onChange, selected ])

    return (
        <Flex position="relative">
            <Dropdown ref={ref} disabled={disabled} open={isOpen}>
                <Input
                    icon={<Chevron size={16} onClick={toggleDropdown} open={isOpen}/>}
                    onClick={toggleDropdown}
                    readOnly
                    value={counter + ' ' + placeholder}
                />
                <DropdownBody open={isOpen}>
                    {items.map(item => (
                        <Option
                            key={itemToLabel?.(item) ?? String(item)}
                            onClick={onOptionClick(item)}
                            selected={selected.includes(item)}
                        >
                            <>
                                <Flex
                                    as={Checkbox}
                                    checked={selected.includes(item)}
                                    onChange={onOptionClick(item)}
                                    cursor="inherit"
                                    mr={1}
                                />
                                <Text size="small" ml={1}>{itemToLabel?.(item) ?? item}</Text>
                            </>
                        </Option>
                    ))}
                </DropdownBody>
            </Dropdown>
        </Flex>
    )
}

export default MultiSelect
