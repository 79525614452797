import React, {
    useState,
    useRef,
    useEffect,
    Dispatch,
    SetStateAction,
    InputHTMLAttributes,
    useCallback,
    SyntheticEvent,
    MouseEventHandler,
} from 'react'
import styled, { css } from 'styled-components'
import { ChevronDown, Search as FeatherSearch } from 'react-feather'
import Flex from '../../layout/Flex'
import { Input } from '../Input'

interface DropdownProps {
    disabled?: boolean
    open?: boolean
}

export const Dropdown = styled(Flex)<DropdownProps>`
    width: 100%;
`

interface ChevronProps {
    open?: boolean
}

export const Chevron = styled(ChevronDown)<ChevronProps>`
    ${({ theme }) => theme.inputs.selects.chevron};
    transform: rotate(0deg);
    transition: all .2s ease-in-out;

    ${({ open }) => open && css`
        transform: rotate(-180deg);
    `}
`

export const Search = styled(FeatherSearch)<ChevronProps>`
    ${({ theme }) => theme.inputs.selects.chevron};
`

interface DropdownBodyProps {
    open?: boolean
}

export const DropdownBody = styled(Flex)<DropdownBodyProps>`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow: hidden;
    border-top: 1px solid;
    z-index: 9999;
    ${({ theme }) => theme.inputs.selects.dropdown.styles};
    display: none;

    ${({ open }) => open && css`
        display: block;
    `}
`

interface OptionProps {
    onClick: () => void
    selected?: boolean
}

const NoOption = styled(Flex)`
    flex-direction: row;
    padding: 0 24px;
    height: 42px;
    align-items: center;
    font-size: 12px;
`

export const Option = styled(NoOption)<OptionProps>`
    transition: all .2s ease-in-out;

    &:hover {
        ${({ theme }) => theme.inputs.selects.dropdown.items.variants.hover};
    }

    ${({ selected, theme }) => selected && css`
        ${theme.inputs.selects.dropdown.items.variants.hover};
    `}
`

export function useComponentVisible<T extends HTMLElement>(setIsComponentVisible: Dispatch<SetStateAction<boolean>>) {
    const ref = useRef<T>(null)

    const handlePressEscape = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsComponentVisible(false)
        }
    }, [ setIsComponentVisible ])

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (!ref?.current?.contains(event.target as Node)) {
            setIsComponentVisible(false)
        }
    }, [ setIsComponentVisible ])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        document.addEventListener('keydown', handlePressEscape, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
            document.removeEventListener('keydown', handlePressEscape, true)
        }
    }, [ handleClickOutside, handlePressEscape ])

    return ref
}

export interface SelectProps<Value> {
    placeholder: string
    items: Value[]
    selected?: Value
    disabled?: boolean
    onChange: (value: Value) => void
    searchable?: boolean
    searchValue?: string
    itemToLabel?: (item: Value) => string
    onSearchChange?: (e: SyntheticEvent<HTMLInputElement>) => void
    name?: InputHTMLAttributes<typeof Input>['name']
}

export type SelectGroupProps<Value> = SelectProps<Value>

const Select = <Value,>({
    itemToLabel,
    items,
    placeholder,
    disabled,
    onChange,
    searchable,
    searchValue,
    selected,
    onSearchChange,
}: SelectGroupProps<Value>) => {
    const [ isOpen, setOpen ] = useState<boolean>(false)
    const ref = useComponentVisible<HTMLDivElement>(setOpen)

    const onSearchChangeCallback = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
        if (onSearchChange) {
            onSearchChange(e)
        }
    }, [ onSearchChange ])

    // TODO: Memoize inner function
    const onOptionClick = useCallback<(selected: Value) => MouseEventHandler<HTMLDivElement>>(selected => e => {
        setOpen(false)
        onChange(selected)
        e.stopPropagation()
        e.preventDefault()
    }, [ onChange ])

    const toggleDropdown = useCallback((e: SyntheticEvent) => {
        !disabled && setOpen(!isOpen)
        e.stopPropagation()
        e.preventDefault()
    }, [ disabled, isOpen ])

    const Icon = searchable ? Search : Chevron

    return (
        <Flex position="relative">
            <Dropdown ref={ref} disabled={disabled} open={isOpen}>
                <Input
                    readOnly={!searchable}
                    onFocus={searchable ? toggleDropdown : undefined}
                    onClick={searchable ? undefined : toggleDropdown}
                    placeholder={placeholder}
                    onChange={onSearchChangeCallback}
                    value={(isOpen && searchable ? searchValue : (selected ? (itemToLabel?.(selected) ?? String(selected)) : ''))}
                    icon={<Icon size={16} onClick={toggleDropdown} open={isOpen}/>}
                />
                <DropdownBody open={isOpen}>
                    <Flex flexDirection="column">
                        {items.map((item: Value, i) => (
                            <Option
                                key={i}
                                onClick={onOptionClick(item) as () => void}
                                selected={item === selected}
                            >
                                {itemToLabel?.(item) ?? item}
                            </Option>
                        ))}
                        {searchable && !items && <NoOption>No results</NoOption>}
                    </Flex>
                </DropdownBody>
            </Dropdown>
        </Flex>
    )
}

export default Select
