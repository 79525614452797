import { getLeadProgressStatus, leadProgressStatuses } from '../../../../utils'
import Flex from 'components/src/layout/Flex'
import ProgressBar from 'components/src/Progress'
import Heading from 'components/src/texts/Heading'
import Text from 'components/src/texts/Text'
import FormattedDate from 'components/src/Date'
import React from 'react'
import { LeadResource } from 'ageras-api-client/src/api'
import { useTranslation } from 'react-i18next'

const getProgressStep = (lead: LeadResource) => {
    const progressStatus = getLeadProgressStatus(lead)
    if ([ leadProgressStatuses.completed, leadProgressStatuses.allQuotesRejected ].includes(progressStatus)) {
        return 4
    }
    if (progressStatus === leadProgressStatuses.awaitingSelection) {
        return 3
    }
    if (progressStatus === leadProgressStatuses.awaitingQuotes) {
        return 2
    }
    if (lead.validatedAt) {
        return 1
    }
    return 0
}

interface StatusInfoProps {
    lead: LeadResource
}

function StatusInfo({ lead }: StatusInfoProps) {
    const { t } = useTranslation([ 'common', 'caseList' ])
    const step = getProgressStep(lead)
    const progressStatus = getLeadProgressStatus(lead)

    const array = lead.quotesInfo?.quotes?.filter(quote => quote.publishedAt).map(quote => quote.publishedAt && + new Date(quote.publishedAt))

    const latestQuotePublication = array && new Date(Math.max(...array.map(item => Number(item))))

    return <Flex flexDirection="column">
        <ProgressBar
            step={step}
        >
            <Flex flexDirection="column">
                <Heading size="small" color="impact">{t('caseList:status_info.case_has_been_validated')}</Heading>
                <Text size="small">
                    {lead.validatedAt && <FormattedDate datetime={lead.validatedAt} />}
                    {!lead.validatedAt && <span>{t('common:awaiting')}</span>}
                </Text>
            </Flex>
            <Flex flexDirection="column">
                <Heading size="small" color="impact">{t('caseList:status_info.case_awaiting_quotes')}</Heading>
                <Text size="small">
                    {step >= 2 && lead.validatedAt && <FormattedDate datetime={lead.validatedAt} />}
                    {!lead.validatedAt && <span>{t('common:awaiting')}</span>}
                </Text>
            </Flex>
            <Flex flexDirection="column">
                <Heading size="small" color="impact">{t('caseList:status_info.partners_awaiting_selection')}</Heading>
                <Text size="small">
                    {step >= 3 && latestQuotePublication && <FormattedDate datetime={latestQuotePublication.toString()} />}
                    {!latestQuotePublication && <span>{t('common:awaiting')}</span>}
                </Text>
            </Flex>
            <Flex flexDirection="column">
                <Heading size="small" color="impact">{t('caseList:status_info.client_given_decision')}</Heading>
                {step === 4 && lead.quotesInfo?.acceptedQuote && <Text size="small" color="success">{t('common:accepted')}</Text>}
                {step === 4 && progressStatus === leadProgressStatuses.allQuotesRejected && <Text size="small" color="danger">{t('caseList:status_info.all_rejected')}</Text>}
                {progressStatus !== leadProgressStatuses.allQuotesRejected && !lead.quotesInfo?.acceptedQuote && <Text size="small">{t('common:awaiting')}</Text>}
            </Flex>
        </ProgressBar>
    </Flex>
}

export default StatusInfo
