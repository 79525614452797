import React from 'react'
import * as yup from 'yup'
import { SchemaOf } from 'yup'
import { Field, Form, Formik, FormikProps } from 'formik'
import Text from 'components/src/texts/Text'
import Button from 'components/src/Button'
import Flex from 'components/src/layout/Flex'
import {
    TermsActionResource,
    TermsApiFetchParamCreator,
    TermsResource,
    TermsResult
} from 'ageras-api-client/src/api'
import { useAgerasApi, useAgerasApiMutation } from 'ageras-api-client/src'
import Checkbox from 'components/src/inputs/Checkbox'
import { UseMutationResult } from '@tanstack/react-query'
import useModal, { UseModal } from 'components/src/modals/useModal'
import TermsModal from './TermsModal'
import { useTranslation } from 'react-i18next'

interface ConfirmFormValues {
    accepted: boolean
}

function ConfirmForm(props: FormikProps<ConfirmFormValues> & {
    terms: TermsResource | undefined
    isActionLoading: boolean
    showModal: UseModal['show']
}) {
    const { t } = useTranslation([ 'quoteDetail', 'common' ])

    return (
        <Form>
            <Flex alignItems="center" justifyContent="space-between">
                <Field
                    name="accepted"
                    label={
                        <>
                            {t('quoteDetail:terms_prefix')}
                            {' '}
                            <Text color="accent" underline size="small" onClick={props.showModal(args => <TermsModal {...args} body={props.terms?.body} />)}>terms and conditions</Text>
                            {' '}
                            {t('quoteDetail:terms_suffix')}
                        </>
                    }
                    as={Checkbox}
                    error={props?.errors?.accepted}
                />
                <Button variant="primary" type="submit" disabled={props.isActionLoading}>{t('common:confirm')}</Button>
            </Flex>
        </Form>
    )
}

interface ConfirmFormBlockProps {
    geoCode: string
    onSubmit: () => void
    isActionLoading: boolean
}

function onAccept(terms: TermsResource, mutation: UseMutationResult<TermsActionResource, Error, TermsActionResource>) {
    return async () => {
        const termsActionData: TermsActionResource = {
            termsId: Number(terms.id),
            action: TermsActionResource.ActionEnum.Accept,
        }
        mutation.mutate(termsActionData)
    }
}

function ConfirmFormBlock(props: ConfirmFormBlockProps) {
    const { t } = useTranslation([ 'quoteDetail', 'common', 'validation' ])
    const { show } = useModal()
    const { isLoading, error, data } = useAgerasApi<TermsResult>(
        TermsApiFetchParamCreator().termsIndex(props.geoCode, 'client', 'general')
    )

    const mutation = useAgerasApiMutation<TermsActionResource>(
        (actionData: TermsActionResource) => TermsApiFetchParamCreator().termsActionsCreate(String(actionData.termsId), actionData),
        {
            onSuccess: () => {
                props.onSubmit()
            },
        }
    )

    const formValidationSchema: SchemaOf<ConfirmFormValues> = yup.object().shape({
        accepted: yup.bool().isTrue().required(t('validation:form_must_be_checked')),
    })

    if (isLoading) {
        return <Text>{t('common:loading')}</Text>
    }

    if (error) {
        return <Text>{error.message}</Text>
    }

    if (data?.data === undefined) {
        return null
    }

    return (
        <Formik
            initialValues={{
                accepted: false,
            }}
            onSubmit={onAccept(data.data[0], mutation)}
            validationSchema={formValidationSchema}
        >
            {(formProps) => (
                <ConfirmForm {...formProps} terms={data.data?.[0]} isActionLoading={props.isActionLoading || mutation.isLoading} showModal={show} />
            )}
        </Formik>
    )
}

export default ConfirmFormBlock
