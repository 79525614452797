import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

export const DeprecatedFormGroup = styled.div<SpaceProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 0px;
    flex-basis: 0;
    ${space};
`
