import React, { useCallback, useRef, useState } from 'react'
import { useAgerasApi, useAgerasApiMutation } from 'ageras-api-client/src'
import {
    ConversationResource,
    ConversationsApiFetchParamCreator,
    MessageResource, MessageResult
} from 'ageras-api-client/src/api'
import MessageForm from './MessageForm'
import MessagesContainer from './MessagesContainer'

interface MessengerProps {
    conversation: ConversationResource
}

function Messenger({ conversation }: MessengerProps) {
    const [ limit, setLimit ] = useState<number>(10)
    const { data, refetch } = useAgerasApi<MessageResult>(
        ConversationsApiFetchParamCreator().conversationsMessagesIndex(
            String(conversation.id),
            undefined,
            undefined,
            undefined,
            undefined,
            limit
        )
    )

    const messages = data?.data

    const loadMore: () => void = useCallback(() => {
        setLimit(prevLimit => prevLimit + 10)
    }, [])

    const mutation = useAgerasApiMutation<MessageResource>(
        (message: MessageResource) => ConversationsApiFetchParamCreator().conversationsMessagesCreate(message),
        {
            onSuccess: async () => {
                await refetch()
                scrollToBottom()
            },
        }
    )

    const handleMessageSend: (text: string) => void = useCallback(text => {
        const leadParticipant = conversation?.participants?.find(participant => participant.type === 'lead')
        const message = {
            conversationId: conversation?.id,
            text,
            participant: leadParticipant,
        }
        mutation.mutate(message)
    }, [ conversation, mutation ])

    const messagesEndRef = useRef<HTMLDivElement>(null)

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
        }
    }

    return (
        <>
            <MessagesContainer
                messages={messages || []}
                hasMore={Number(data?.page) < Number(data?.pages)}
                loadMore={loadMore}
                ref={messagesEndRef}
            />
            <MessageForm
                onSubmit={handleMessageSend}
            />
        </>
    )
}

export default Messenger
