import React from 'react'
import {
    ModalArray,
    ModalProps,
} from './index'

interface TModalContext {
    modals: ModalArray
    setModals: React.Dispatch<React.SetStateAction<TModalContext['modals']>>
    zIndex: number
}

export const ModalContext = React.createContext<TModalContext|undefined>(undefined)

export interface UseModal {
    show: (fun: (props: ModalProps) => React.ReactElement) => () => void
    hide: () => void
    zIndex: number
}

function useModal(): UseModal {
    const context = React.useContext(ModalContext)

    if (context === undefined) {
        throw new Error('No context')
    }

    const [ index ] = React.useState<number>(context.modals.length)

    const {
        modals,
        setModals,
        zIndex,
    } = context

    React.useEffect(() => {
        setModals([
            ...modals,
            undefined,
        ])
    }, [ index ])

    const hide = () => {
        setModals(modals.map((modal, i) => i !== index ? modal : undefined))
    }

    return {
        show: (modal) => () => {
            const Modal = modal({ onHide: hide, zIndex })
            setModals(modals.map((V, i) => (
                <React.Fragment key={i}>
                    {i !== index ? V : Modal}
                </React.Fragment>
            )))
        },
        hide,
        zIndex,
    }
}

export default useModal
