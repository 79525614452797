import React, { ForwardedRef, useEffect, useState } from 'react'
import Message from 'components/src/conversations/Message'
import Container from 'components/src/conversations/MessagesContainer'
import { MessageResource } from 'ageras-api-client/src/api'
import Flex from 'components/src/layout/Flex'

import Button from 'components/src/Button'

interface MessagesContainerProps {
    messages: MessageResource[]
    hasMore: boolean
    loadMore: () => void
}

const MessagesContainer = React.forwardRef(({
    messages,
    hasMore,
    loadMore,
}: MessagesContainerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [ array, setArray ] = useState<MessageResource[]>([])

    useEffect(() => {
        setArray(prevState => [ ...new Map([ ...messages, ...prevState ]
            .map(item => [ item.id, item ])).values() ]
            .sort((a, b) => Date.parse(b.createdAt as string) - Date.parse(a.createdAt as string)))
    }, [ messages ])

    return (
        <Container flexGrow={1} overflowY="scroll" flexDirection="column">
            <Flex flexDirection="column-reverse" justifyContent="flex-end" px={4} py={2}>
                {Boolean(array.length) && array.map(message =>
                    <Message
                        key={message.id}
                        text={message.text}
                        own={Boolean(message.participant?.type && [ 'lead', 'client' ].includes(message.participant.type))}
                        name={message.participant?.name}
                        updatedAt={message.updatedAt}
                    />,
                )}
                <Flex justifyContent="center">
                    <Button variant="neutral" disabled={!hasMore} onClick={loadMore}>Load more</Button>
                </Flex>
            </Flex>
            <div ref={ref}/>
        </Container>
    )
})

export default MessagesContainer
