import Button from 'components/src/Button'
import Icon from 'components/src/icons/Icon'
import Flex from 'components/src/layout/Flex'
import Text from 'components/src/texts/Text'
import React from 'react'
import { CheckCircle, ChevronRight } from 'react-feather'
import styled, { css, keyframes } from 'styled-components'
import { useTranslation } from 'react-i18next'

const backgroundAnimation = keyframes`
    0% {
        background-position: 100% 0%;
    }
    100% {
        background-position: 0% 0%;
    }
`

interface ContainerProps {
    floating?: boolean
    selectedCount?: number
}

const ApplyButton = styled(Button)`
    color: ${({ theme: { text } }) => text.colors.light.color};
    padding: 0;
`

const Container = styled(Flex)<ContainerProps>`
    ${({ selectedCount }) => selectedCount === 0 ? css`
        border-radius: 12px;
    ` : css`
        border-radius: 0 0 12px 12px;
    `}
    width: 100%;
    height: 88px;
    animation: ${backgroundAnimation} 2s linear infinite;
    background-image: linear-gradient(
        90deg,
        ${({ theme: { colors } }) => colors.electricGreen},
        ${({ theme: { colors } }) => colors.lightElectricGreen},
        ${({ theme: { colors } }) => colors.electricGreen},
        ${({ theme: { colors } }) => colors.lightElectricGreen}
    );
    background-size: 300% 100%;
    box-shadow: ${({ theme: { boxShadows } }) => boxShadows.base};
    color: ${({ theme: { text } }) => text.colors.light.color};
    font-weight: 600;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    ${({ floating }) => floating && css`
        position: fixed;
        bottom: 0;
        right: 10%;
        left: 10%;
        width: 80%;
        border-radius: 12px 12px 0 0;
        z-index: 999;
        margin-bottom: 0;
        opacity: .9;
        height: auto;
    `}

    &:hover ${ApplyButton} {
       text-decoration: underline;
    }
`

interface ApplyNowSectionProps {
    apply: () => void
    selectedCount: number
    floating?: boolean
    selectionsFull?: boolean
}

const ApplyNowSection = React.forwardRef<HTMLDivElement, ApplyNowSectionProps>((props, ref) => {
    const { selectedCount, floating = false, selectionsFull, apply } = props
    const { t } = useTranslation('searchResultList')

    return (
        <Container
            ref={ref}
            floating={floating}
            alignItems="center"
            justifyContent="space-between"
            px={4}
            py={2}
            onClick={apply}
        >
            <Flex alignItems="center">
                {selectionsFull && <Icon mr={3}>
                    <CheckCircle size={35} />
                </Icon>}
                <Text size="large">
                    {selectedCount}
                    {' '}
                    {t('apply_now.selected_offers')}
                </Text>
            </Flex>
            <ApplyButton variant="transparent">
                <Text size="large">{t('apply_now.apply')}</Text>
                <Icon ml={3}>
                    <ChevronRight size={35} />
                </Icon>
            </ApplyButton>
        </Container>
    )
})

export default ApplyNowSection
