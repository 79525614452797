import styled from 'styled-components'
import Image from '../Image'
import Text from '../texts/Text'
import Flex from '../layout/Flex'

export const MenuSpace = styled.div`
    display: inline-block;
    margin-bottom: 0;
    margin-top: auto;
`

export const MenuItem = styled.div`
    display: flex;
    padding-left: 30px;
    align-items: center;
    width: 100%;
    height: 80px;
    color: ${({ theme }) => theme.sideBar.colors.content};

    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.sideBar.colors.border};
    }

    &:first-of-type,
    ${MenuSpace} + & {
        border-top: 1px solid ${({ theme }) => theme.sideBar.colors.border};
    }

    & > * {
        flex: 0 0 auto;
    }

    &:hover {
        background-color: ${({ theme }) => theme.sideBar.colors.hover};
    }
`

export const StyledSidebar = styled(Flex)`
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    flex-direction: column;
    width: ${({ theme }) => theme.sideBar.dimensions.width};
    height: 100vh;
    padding-bottom: 80px;
    min-height: 800px;
    background-color: ${({ theme }) => theme.sideBar.colors.background};
    transition: width .3s ease-in-out;
    box-shadow: 0px 18px 20px rgba(0, 0, 0, 0.03);

    & > ${MenuItem} > ${Text} {
        overflow: hidden;
        width: 0;
        opacity: 0;
        transition: width 0ms 400ms, opacity 400ms 0ms;
    }

    &:hover {
        width: 216px;

        ${MenuItem} > ${Text} {
            width: auto;
            opacity: 1;
            transition: width 0ms 0ms, opacity 600ms 0ms;
        }
    }
`

export const SidebarContainer = styled.div`
    position: relative;
    z-index: 10;
    width: ${({ theme }) => theme.sideBar.dimensions.width};
    height: 100vh;

    @media only screen and (max-width: 600px) {
        bottom: 0;
        width: unset;
        height: unset;
        ${StyledSidebar} {
            top: unset !important;
            bottom: 0 !important;
            height: 0px !important;
            width: 100vw !important;
            min-height: unset !important;

            ${Image as any} {
                height: 0 !important;
            }
        }
    }
`
