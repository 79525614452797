import { IndustryResource, PartnerBadgeResource, PartnerBusinessUnitPartnerUserResource, PartnerBusinessUnitResource, PartnerUserCvLanguageResource } from 'ageras-api-client/src/api'
import Button from 'components/src/Button'
import Flex from 'components/src/layout/Flex'
import { Star as RatingStar } from 'components/src/Rating'
import Side, { SideProps } from 'components/src/Side'
import Text from 'components/src/texts/Text'
import Logo from 'components/src/Logo'
import { ReactElement, useCallback, useState } from 'react'
import { X, Star, ChevronLeft, ChevronRight } from 'react-feather'
import styled from 'styled-components'
// eslint-disable-next-line implicit-dependencies/no-implicit
import Carousel, { RenderArrowProps } from 'react-elastic-carousel'
// eslint-disable-next-line implicit-dependencies/no-implicit
import { GoogleMap, Marker } from '@react-google-maps/api'
import useGoogleMapsApiScript from '../../../utils/useGoogleMapsApiScript'
import { useEffect } from 'react'
import LanguagesList, { Language } from '../LanguagesList'
import { useTranslation } from 'react-i18next'

const Content = styled(Flex)`
`

const CloseButton = styled(Button)`
    position: absolute;
    right: 30px;
    top: 30px;
    padding: 6px;
    height: auto;
    width: auto;
`

const Header = styled(Flex)`
    height: 264px;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    background-color: #F1F5F7;
    position: relative;
`

const RatingBadge = styled(Flex)`
    padding: 12px;
    align-items: center;
    justify-content: space-between;
    border: solid 1px rgba(0, 0, 0, .1);
    border-radius: 4px;
    background-color: #fefefe;
    cursor: pointer;
    white-space: nowrap;
`

const NumberOfReviewsText = styled(Text)`
    text-decoration: underline;
    color: #042d52;
    opacity: .49;
`

const OpaqueText = styled(Text)`
    opacity: .5;
`

interface CarouselElementProps {
    children: ReactElement[]
    renderArrow: (props: RenderArrowProps) => void
    numberToShow?: number
}

const PartnerDetailsCarousel = (props: CarouselElementProps) =>
    <Carousel
        itemsToShow={props.numberToShow ?? 3}
        isRTL={false}
        enableAutoPlay
        autoPlaySpeed={5000}
        pagination={false}
        transitionMs={1500}
        enableSwipe
        enableMouseSwipe
        renderArrow={props.renderArrow}
    >
        {props.children}
    </Carousel>

interface PartnerDetailsSideProps extends SideProps {
    hide: () => void
    unit: PartnerBusinessUnitResource
    showRating: (unit: PartnerBusinessUnitResource) => void
}

const PartnerDetailsSide = (props: PartnerDetailsSideProps) => {
    const { unit, hide, showRating } = props
    const [ , setMap ] = useState(null)
    const [ languages, setLanguages ] = useState<Language[]>([])
    const [ isMapLoaded ] = useGoogleMapsApiScript()
    const { t } = useTranslation('searchResultList')

    const partner = unit.partner

    const onMapLoad = useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds()

        map.fitBounds(bounds)

        setMap(map)
    }, [])

    const onMapUnmount = useCallback(() => {
        setMap(null)
    }, [])

    const containerStyle = {
        width: '100%',
        height: '300px',
    }

    const getGoogleMapsPosition = (latitude: number, longitude: number) => ({
        lat: latitude,
        lng: longitude,
    })

    const handleHidePartnerDetails = useCallback(() => {
        hide()
    }, [ hide ])

    const buildAddressLine = (cityName: string | undefined, address: string | undefined): string | null => {
        let result = null

        if (cityName) {
            result = cityName
        }

        if (address) {
            result = `${result}, ${address}`
        }

        return result
    }

    const renderArrow = useCallback((props: RenderArrowProps) => {
        const { type, onClick } = props

        return type === 'PREV'
            ? <ChevronLeft size={20} onClick={onClick} />
            : <ChevronRight size={20} onClick={onClick} />
    }, [])

    const handleOpenRatingClick = useCallback(() => {
        if (unit == null) {
            return
        }

        showRating(unit)
    }, [ unit, showRating ])

    useEffect(() => {
        if (partner == null) {
            return
        }

        const result: Language[] = partner.publicEmployees?.reduce((result: Language[], employee: PartnerBusinessUnitPartnerUserResource) => {
            const languages = employee.languages

            if (languages == null) {
                return result
            }

            languages.forEach((language: PartnerUserCvLanguageResource) => {
                if (language.identifier == null || language.name == null) {
                    return result
                }

                if (result.find((currentLanguage: Language) => currentLanguage.code === language.identifier)) {
                    return result
                }

                result.push({
                    code: language.identifier,
                    name: language.name
                })
            })

            return result
        }, []) || []

        setLanguages(result)
    }, [ partner ])

    if (partner == null) {
        return <Side>
            <Text>Loading</Text>
        </Side>
    }

    return (
        <Side {...props}>
            <Content flexDirection="column">
                <Header px={4} py={2}>
                    <CloseButton variant="secondary" onClick={handleHidePartnerDetails}>
                        <X size={25} />
                    </CloseButton>
                    <Flex alignItems="center">
                        <Flex>
                            <Logo
                                imageUrl={partner.logo}
                                name={partner.companyName}
                                size="medium"
                            />
                        </Flex>
                        <Text ml={2} fontWeight="bold" size="large" color="impact">{partner.companyName}</Text>
                    </Flex>
                    <RatingBadge onClick={handleOpenRatingClick}>
                        <RatingStar state="full" size="medium" variant="base" />
                        <Flex flexDirection="column" ml={1}>
                            <Flex alignItems="flex-end">
                                <Text size="large" fontWeight="bold" color="impact">{partner.rating?.score}</Text>
                                <Text size="small" fontWeight="bold" color="impact">
                                    {'/5 '}
                                    {t('stars')}
                                </Text>
                            </Flex>
                            <NumberOfReviewsText size="small" underline>
                                {partner.rating?.count}
                                {' '}
                                {t('result_reviews')}
                            </NumberOfReviewsText>
                        </Flex>
                    </RatingBadge>
                </Header>
                <Flex py={2} px={4} flexDirection="column">
                    <Flex mb={4} flexDirection="column">
                        <OpaqueText size="medium" fontWeight="400" color="impact" mb={2}>
                            { buildAddressLine(unit.geo?.cityName, unit?.geo?.address) }
                        </OpaqueText>
                        <Text color="impact" mb={2}>
                            {partner.companyInformation}
                        </Text>
                        <Flex mb={3}>
                            <LanguagesList iconSize={20} languages={languages} />
                        </Flex>
                        {partner.badges && partner.badges.length > 0 &&
                            <PartnerDetailsCarousel
                                renderArrow={renderArrow}
                                numberToShow={partner.badges.length > 3 ? 3 : partner.badges.length}
                            >
                                {partner.badges.map((badge: PartnerBadgeResource, index: number) =>
                                    <Flex key={index} alignItems="center" justifyContent="center">
                                        <Flex>
                                            <Star width={25} color="#0A7AE0" />
                                        </Flex>
                                        <Text color="impact" size="small" ml={1}>{badge.badgeToken}</Text>
                                    </Flex>
                                )}
                            </PartnerDetailsCarousel>
                        }
                        {(!partner.badges || (partner.badges?.length === 0)) && <Flex minHeight="60px">
                            <OpaqueText size="small" fontWeight="bold">{t('details_side.no_badges')}</OpaqueText>
                        </Flex>}
                    </Flex>
                    <Flex mb={4} flexDirection="column">
                        <Text color="impact" fontWeight="bold" size="large" mb={2}>
                            {t('details_side.industry')}
                            {' / '}
                            {t('details_side.practise')}
                        </Text>
                        {unit.desiredIndustries && unit.desiredIndustries.length > 0 &&
                            <PartnerDetailsCarousel
                                renderArrow={renderArrow}
                            >
                                {unit.desiredIndustries.map((industry: IndustryResource, index: number) =>
                                    <Flex key={index} alignItems="center" justifyContent="center">
                                        <Flex>
                                            <Star width={25} color="#0A7AE0" />
                                        </Flex>
                                        <Text color="impact" size="small" ml={1}>{industry.name}</Text>
                                    </Flex>
                                )}
                            </PartnerDetailsCarousel>
                        }
                        {(!unit.desiredIndustries || unit?.desiredIndustries.length === 0) && <Flex minHeight="60px">
                            <OpaqueText size="small" fontWeight="bold">{t('details_side.no_industries')}</OpaqueText>
                        </Flex>}
                    </Flex>
                    <Flex mb={4} flexDirection="column">
                        <Text color="impact" fontWeight="bold" size="large" mb={3}>People / Partners</Text>
                        {partner.publicEmployees && partner.publicEmployees.length > 0 &&
                            <PartnerDetailsCarousel
                                renderArrow={renderArrow}
                            >
                                {partner.publicEmployees.map((employee: PartnerBusinessUnitPartnerUserResource, index: number) =>
                                    <Flex key={index} alignItems="center" justifyContent="center">
                                        <Flex>
                                            <Star width={25} color="#0A7AE0" />
                                        </Flex>
                                        <Flex ml={2} flexDirection="column" justifyContent="flex-start">
                                            <Text size="small" color="impact">{employee.name}</Text>
                                            <OpaqueText size="small">{employee.title}</OpaqueText>
                                        </Flex>
                                    </Flex>
                                )}
                            </PartnerDetailsCarousel>
                        }
                        {(!partner.publicEmployees || partner?.publicEmployees?.length === 0) && <Flex minHeight="60px">
                            <OpaqueText size="small" fontWeight="bold">{t('details_side.no_employees')}</OpaqueText>
                        </Flex>}
                    </Flex>
                    <Flex>
                        {unit?.geo?.point && isMapLoaded &&
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={getGoogleMapsPosition(unit.geo.point.lat || 0, unit.geo.point.lon || 0)}
                                zoom={10}
                                onLoad={onMapLoad}
                                onUnmount={onMapUnmount}
                            >
                                <Marker
                                    position={getGoogleMapsPosition(unit.geo.point.lat || 0, unit.geo.point.lon || 0)}
                                />
                            </GoogleMap>
                        }
                        {!unit?.geo?.point && <Flex minHeight="60px">
                            <OpaqueText size="small" fontWeight="bold">{t('details_side.no_map_location')}</OpaqueText>
                        </Flex>}
                    </Flex>
                </Flex>
            </Content>
        </Side>
    )
}

export default PartnerDetailsSide
