import React, { ChangeEvent } from 'react'
import styled, { css } from 'styled-components'
import { darken } from 'polished'
import Text from '../../texts/Text'

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`

interface CheckboxBoxProps {
    labelLeft?: string
    labelRight?: string
}

const CheckboxBox = styled.span<CheckboxBoxProps>`
    display: block;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    overflow: hidden;
    ${({ theme }) => theme.inputs.checkboxes.styles};
    ${({ labelLeft }) => labelLeft && css`
        float: right;
    `}
    ${({ labelRight }) => labelRight && css`
        float: left;
    `}

    &::after {
        content: "";
        display: block;
        left: 50%;
        top: 50%;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg) translate(150%, -25%);
    }
`

interface StyledCheckboxProps {
    width?: string
    error?: boolean
}

const CheckboxWrapper = styled.label<StyledCheckboxProps>`
    display: block;
    position: relative;
    user-select: none;
    font-size: ${({ theme }) => theme.inputs.fontSizes.input};
    color: ${({ theme }) => theme.inputs.colors.label};
    cursor: pointer;
    line-height: 32px;
    ${({ width }) => width && css`
        width: ${width};
    `}

    &:hover ${CheckboxInput} ~ ${CheckboxBox} {
        background-color: ${({ theme }) => darken(.1, theme.colors.lightGrey)};
    }

    & ${CheckboxInput}:checked ~ ${CheckboxBox} {
        background-color: ${({ theme }) => theme.colors.baseBlue};
    }

    & ${CheckboxInput}:checked ~ ${CheckboxBox}:after {
        display: block;
    }

    & ${CheckboxInput}:disabled ~ ${CheckboxBox} {
        opacity: .5;
        cursor: not-allowed;
    }

    ${({ error, theme }) => error && css`
        & ${CheckboxBox} {
            border: 1px solid ${theme.inputs.colors.error};
        }
    `}
`

const LabelLeft = styled(Text)`
    margin-top: 7px;
    float: left;
`

const LabelRight = styled(Text)`
    margin-top: 7px;
    float: right;
`

export interface CheckboxProps extends StyledCheckboxProps {
    labelLeft?: string
    labelRight?: string
    width?: string
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    name?: string
    required?: boolean
    disabled?: boolean
    value?: boolean
}

const Checkbox = ({
    name,
    onChange,
    labelLeft,
    labelRight,
    width = '32px',
    error,
    required,
    disabled,
    value,
    ...props
}: CheckboxProps) => {
    return (
        <CheckboxWrapper htmlFor={name} error={error} width={width} {...props}>
            {labelLeft && <LabelLeft>{labelLeft}</LabelLeft>}
            {labelRight && <LabelRight>{labelRight}</LabelRight>}
            <CheckboxInput
                {...props}
                name={name}
                required={required}
                onChange={onChange}
                id={name}
                disabled={disabled}
                checked={value}
            />
            <CheckboxBox labelLeft={labelLeft} labelRight={labelRight} />
        </CheckboxWrapper>
    )
}

export default Checkbox
