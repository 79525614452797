import React from 'react'
import styled from 'styled-components'
import { variant, space, border, SpaceProps, flexbox, FlexboxProps, BorderProps } from 'styled-system'

type ButtonVariantTypes = 'primary' | 'secondary' | 'ternary' | 'negative' | 'neutral' | 'transparent'

interface BaseButtonProps {
    type?: string
}

export interface StyledButtonProps extends SpaceProps, FlexboxProps, BorderProps {
    variant: ButtonVariantTypes
    grow?: boolean
    size?: 'default' | 'small'
}

const ButtonBase = styled.button.attrs(props => ({ type: props.type || 'button' }))<BaseButtonProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 10px;
    transition: all .3s ease;
    font-weight: ${({ theme }) => theme.buttons.font.weight || 'bold'};
    cursor: pointer;
    ${space};
    ${flexbox};
    ${border};

    &:disabled {
        cursor: not-allowed;
    }
`

export const StyledButton = styled(ButtonBase)<StyledButtonProps>`
    ${variant({
        scale: 'buttons.colors',
    })};

    ${variant({
        prop: 'size',
        scale: 'buttons.sizes',
    })};

    ${({ grow }) => grow && `
        display: flex;
        width: 100%;
    `}
`

StyledButton.defaultProps = {
    size: 'default',
}

interface ButtonFuncProps {
    onClick?: () => void
    disabled?: boolean
    type?: 'submit'
    children: React.ReactNode
}

export type ButtonProps = StyledButtonProps & ButtonFuncProps

const Button = (props: ButtonProps) => <StyledButton {...props}/>

export default Button
