import styled, { css } from 'styled-components'

export interface SideProps {
    open?: boolean
    openWidth?: number
}

const Side = styled.div<SideProps>`
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    max-width: 0;
    ${({ theme }) => theme.sides};

    & > * {
        flex: 0 0 auto;
    }

    ${({ open, openWidth }) => open && css`
        max-width: ${openWidth}px;
    `}
`

Side.defaultProps = {
    openWidth: 800,
}

export default Side
