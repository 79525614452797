import { TFunction } from 'i18next'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
    LeadQuoteResource,
    LeadQuoteResult,
    LeadResource,
    LeadsApiFetchParamCreator, PartnerResource, PartnersApiFetchParamCreator
} from 'ageras-api-client/src/api'
import Flex, { Spacer } from 'components/src/layout/Flex'
import Image from 'components/src/Image'
import Circle from 'components/src/icons/Circle'
import Icon from 'components/src/icons/Icon'
import { User, X } from 'react-feather'
import Heading from 'components/src/texts/Heading'
import Card from 'components/src/Card'
import Text from 'components/src/texts/Text'
import { useAuth } from 'ageras-api-client/src/auth'
import { useAgerasApi } from 'ageras-api-client/src'
import Button from 'components/src/Button'
import StatusInfo from './StatusInfo'
import QuoteInfo from './QuoteInfo'
import { useTranslation } from 'react-i18next'

interface LeadQuotesInfoProps {
    lead: LeadResource
    openPartnerDetail: (newPartner: PartnerResource) => Promise<void>
    openPartnerMessages: (newPartner: PartnerResource, newLead?: LeadResource) => Promise<void>
}

function handleClick(func: LeadQuotesInfoProps['openPartnerMessages'], args: Parameters<LeadQuotesInfoProps['openPartnerMessages']>) {
    return () => func(...args)
}

function selectQuote(quoteId: number | null, setQuoteId: Dispatch<SetStateAction<number | null>>) {
    return () => setQuoteId(quoteId)
}

const RenderQuoteRows = (quotesInfo: LeadResource['quotesInfo'], quoteData: LeadQuoteResult['data'], setQuoteId: Dispatch<SetStateAction<number | null>>, acceptedQuote: LeadQuoteResource | undefined, t: TFunction) => {
    if (!quotesInfo || !quotesInfo.max) {
        return
    }

    const quotes = []
    const sortedQuotes = quotesInfo.quotes?.length ? quotesInfo.quotes?.sort((a, b) => {
        if ((!a.publishedAt && !b.publishedAt) || !b.publishedAt) {
            return 1
        }
        if (!a.publishedAt) {
            return -1
        }
        return new Date(a.publishedAt) > new Date(b.publishedAt) ? 1 : -1
    }) : []

    for (let i = 0; i < quotesInfo.max; i++) {
        const quote = quoteData?.find(q => q.id === sortedQuotes[i]?.id)
        const isQuoteAccepted = quote && acceptedQuote && quote.id === acceptedQuote.id
        const partner = sortedQuotes?.[i]?.partner
        quotes.push(
            <React.Fragment key={'quoteLine_' + i}>
                <Flex alignItems="center" py={4} onClick={quote && selectQuote(quote.id ?? null, setQuoteId)}>
                    {partner?.logo &&
                    <Image background={partner.logo.endsWith('.jpg')} round={partner.logo.endsWith('.jpg')} src={partner.logo} variant="small"/>
                    }
                    {!partner?.logo &&
                    <Circle size="medium" variant={quote?.publishedAt ? 'primary' : 'base'}>
                        <Icon as={User} size={16} />
                    </Circle>
                    }
                    <Heading size="small" ml={3}>{quote?.partner?.companyName ?? t('caseList:lead_quotes_info.awaiting_offer')}</Heading>
                    {isQuoteAccepted && <Text color="success">{t('common:accepted')}</Text>}
                </Flex>
                {i !== quotesInfo.max - 1 && <Card.Line />}
            </React.Fragment>
        )
    }

    return quotes
}

interface TPartnerActionsBar {
    lead: LeadResource
    quote: LeadQuoteResource
    openPartnerDetail: LeadQuotesInfoProps['openPartnerDetail']
    openPartnerMessages: LeadQuotesInfoProps['openPartnerMessages']
}

function PartnerActionsBar({ lead, quote, openPartnerDetail, openPartnerMessages }: TPartnerActionsBar) {
    const { t } = useTranslation('caseList')
    const { data: partner } = useAgerasApi<PartnerResource>(
        PartnersApiFetchParamCreator().partnersGet(String(quote.partner?.id ?? 0))
    )

    return <Flex justifyContent="flex-end">
        <Button variant="ternary" mr={2} onClick={partner && handleClick(openPartnerDetail, [ partner ])}>{t('lead_quotes_info.view_partner_profile')}</Button>
        {lead.validatedAt && <Button variant="secondary" mr={2} onClick={partner && handleClick(openPartnerMessages, [ partner, lead ])}>
            {t('lead_quotes_info.message_partner')}
        </Button>}
    </Flex>
}

function LeadQuotesInfo({ lead, openPartnerDetail, openPartnerMessages }: LeadQuotesInfoProps) {
    const { t } = useTranslation([ 'common', 'caseList' ])

    const [ quoteId, setQuoteId ] = useState<number | null>(null)
    const auth = useAuth()
    const { data, isLoading } = useAgerasApi<LeadQuoteResult>(
        LeadsApiFetchParamCreator().leadsQuotesIndex_3(String(lead.id), String(auth?.client?.id))
    )

    useEffect(() => {
        setQuoteId(lead.quotesInfo?.acceptedQuote?.id || null)
    }, [ lead.quotesInfo?.acceptedQuote?.id ])

    if (isLoading) {
        return <Card.Content px={4}>
            <Text>{t('common:loading')}</Text>
        </Card.Content>
    }

    const leadPrimaryIndustry = lead.industries?.primary?.identifier
    const quote = data?.data?.find(q => q.id === quoteId)
    const acceptedQuote = lead.quotesInfo?.acceptedQuote

    const detailHeading = quoteId ? t('common:details') : t('common:status')

    return <Card.Content px={4}>
        {!acceptedQuote && <Flex>
            <Flex flexDirection="column" flexBasis={0} flexGrow={2} mr={4}>
                {RenderQuoteRows(lead.quotesInfo, data?.data, setQuoteId, acceptedQuote, t)}
            </Flex>
            <Card.VerticalDivider/>
            <Flex flexDirection="column" flexBasis={0} flexGrow={3} ml={4} mt={4} pb={2}>
                <Flex justifyContent="space-between">
                    <Heading mb={3} color="impact">{detailHeading}</Heading>
                    {quoteId &&
                        <Button variant="transparent" size="small">
                            <X size={18} onClick={selectQuote(null, setQuoteId)}/>
                        </Button>
                    }
                </Flex>
                {quoteId && <>
                    <QuoteInfo
                        quote={quote}
                        industry={leadPrimaryIndustry}
                        showPricesWithVat={lead.customerInfo?.showPricesIncludingVat}
                    />
                    <Spacer />
                    {quote && (
                        <PartnerActionsBar
                            lead={lead}
                            quote={quote}
                            openPartnerDetail={openPartnerDetail}
                            openPartnerMessages={openPartnerMessages}
                        />
                    )}
                </>
                }
                {!quoteId &&
                    <StatusInfo lead={lead}/>
                }
            </Flex>
        </Flex>}
        {acceptedQuote && <Flex>
            <Flex flexDirection="column" flexBasis={0} flexGrow={2} mr={4} py={3}>
                <Heading mb={3} color="impact">{detailHeading}</Heading>
                <StatusInfo lead={lead}/>
            </Flex>
            <Card.VerticalDivider/>
            <Flex flexDirection="column" flexBasis={0} flexGrow={3} ml={4} mt={4} py={3}>
                {quoteId &&
                    <>
                        <QuoteInfo
                            quote={quote}
                            industry={leadPrimaryIndustry}
                            showPricesWithVat={lead.customerInfo?.showPricesIncludingVat}
                        />
                        <Spacer />
                        {quote && (
                            <PartnerActionsBar
                                lead={lead}
                                quote={quote}
                                openPartnerDetail={openPartnerDetail}
                                openPartnerMessages={openPartnerMessages}
                            />
                        )}
                    </>
                }
            </Flex>
        </Flex>}
    </Card.Content>
}

export default LeadQuotesInfo
