import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle<{apply?: boolean}>`
    #root {
        height: 100vh;
        position: relative;
    }

    * {
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    *::-webkit-scrollbar {
        width: 0;
    }

    @font-face {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 100;
        src: url('/fonts/Mont-Hairline.otf') format('opentype');
    }

    @font-face {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 200;
        src: url('/fonts/Mont-Thin.otf') format('opentype');
    }
    @font-face {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 300;
        src: url('/fonts/Mont-Light.otf') format('opentype');
    }
    @font-face {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 400;
        src: url('/fonts/Mont-Regular.otf') format('opentype');
    }
    @font-face {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 500;
        src: url('/fonts/Mont-Book.otf') format('opentype');
    }
    @font-face {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 600;
        src: url('/fonts/Mont-SemiBold.otf') format('opentype');
    }
    @font-face {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        src: url('/fonts/Mont-Bold.otf') format('opentype');
    }
    @font-face {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 900;
        src: url('/fonts/Mont-Heavy.otf') format('opentype');
    }

    ${props => props.apply && `
        * {
            font-family: Mont, sans-serif;
        }
    `}
`

export default GlobalStyle
