import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
    LeadResult,
    LeadResource,
    LeadsApiFetchParamCreator, PartnerResource,
} from 'ageras-api-client/src/api'
import { useAgerasApi } from 'ageras-api-client/src'
import { useAuth } from 'ageras-api-client/src/auth'
import Page, { Graphics } from 'components/src/layout/Page'
import Flex from 'components/src/layout/Flex'
import Heading from 'components/src/texts/Heading'
import Text from 'components/src/texts/Text'
import Header from 'components/src/Header'
import Date from 'components/src/Date'
import LeadCard from './LeadCard'
import { WithSidebar } from '../../Sidebar'
import { Outlet } from 'react-router-dom'
import PartnerInfoCard from '../QuoteList/PartnerInfoCard'
import Side from 'components/src/Side'
import { Tab, TabsContainer } from 'components/src/Tabs'
import PartnerMessagesCard from '../QuoteList/PartnerMessagesCard'

const getHasAcceptedQuoteByStatus = (status: string | null) => {
    switch (status) {
        case 'pending':
            return 0
        case 'successful':
            return 1
        case 'rejected':
            return 0
        default:
            return undefined
    }
}

const getHasRejectedQuoteByStatus = (status: string) => {
    switch (status) {
        case 'pending':
            return 0
        case 'rejected':
            return 1
        default:
            return undefined
    }
}

function CaseList() {
    const auth = useAuth()
    const [ partner, setPartner ] = React.useState<null | PartnerResource>(null)
    const [ activeLead, setActiveLead ] = React.useState<null | LeadResource>(null)
    const [ status, setStatus ] = React.useState<string>('all')
    const [ sideOpen, setSideOpen ] = React.useState<boolean>(false)
    const { t } = useTranslation([ 'common', 'caseList' ])
    const { isLoading, error, data } = useAgerasApi<LeadResult>(
        LeadsApiFetchParamCreator().leadsIndex(
            undefined,
            undefined,
            undefined,
            undefined,
            // @ts-ignore
            '-created_at',
            undefined,
            auth.client?.id,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            getHasAcceptedQuoteByStatus(status),
            getHasRejectedQuoteByStatus(status),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            50
        ),
        {
            refetchOnMount: true,
        }
    )

    const tabs = [
        'all',
        'successful',
        'pending',
        'rejected',
    ]

    const closeSide = useCallback(() => {
        setSideOpen(false)
        setPartner(null)
        setActiveLead(null)
    }, [])

    const openPartnerDetail: (newPartner: PartnerResource) => Promise<void> = useCallback(async (newPartner) => {
        if (!sideOpen) {
            setSideOpen(true)
            await new Promise(resolve => setTimeout(resolve, 300))
        }
        setPartner(newPartner)
        setActiveLead(null)
    }, [ sideOpen ])

    const openPartnerMessages = useCallback<(newPartner: PartnerResource, newLead?: LeadResource) => Promise<void>>(async (newPartner, newLead) => {
        if (!sideOpen) {
            setSideOpen(true)
            await new Promise(resolve => setTimeout(resolve, 300))
        }
        setPartner(newPartner)
        setActiveLead(newLead ?? null)
    }, [ sideOpen ])

    const changeTab = useCallback(tab => {
        return () => {
            if (tab !== status) {
                setStatus(tab)
            }
        }
    }, [ status ])

    return (
        <WithSidebar>
            <Page>
                <Graphics graphics="primary" />
                <Header />
                <Flex px={6} pt={6} pb={5} maxWidth={1300} mx="auto" flexDirection="column">
                    <Heading size="large">
                        {t('caseList:header', { count: data?.total || 0 })}
                    </Heading>
                </Flex>
                <Flex maxWidth={1300} px={6} pb={6} mx="auto" flexDirection="column">
                    <TabsContainer>
                        {tabs.map(tab => (
                            <Tab
                                px={3}
                                py={2}
                                key={tab}
                                active={status === tab}
                                onClick={changeTab(tab)}
                            >
                                {tab}
                            </Tab>
                        ))}
                    </TabsContainer>
                </Flex>
                <Flex px={6} maxWidth={1300} mx="auto" flexDirection="column">
                    {isLoading && <Text>{t('common:loading')}</Text>}
                    {error && <Text>{t('common:error_occurred', { message: error.message })}</Text>}
                    {data?.data?.map((lead: LeadResource) => <React.Fragment key={lead.id}>
                        {lead.createdAt && <Text size="small" mb={2}>
                            <Date datetime={lead.createdAt} />
                        </Text>}
                        <LeadCard
                            key={lead.id}
                            lead={lead}
                            openPartnerDetail={openPartnerDetail}
                            openPartnerMessages={openPartnerMessages}
                        />
                    </React.Fragment>)}
                </Flex>
                <Side open={sideOpen}>
                    {!activeLead && <PartnerInfoCard partner={partner} closeSide={closeSide}/>}
                    {activeLead && <PartnerMessagesCard lead={activeLead} partner={partner} closeSide={closeSide} />}
                </Side>
                <Outlet />
            </Page>
        </WithSidebar>
    )
}

export default CaseList
