import { useCallback } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { SetupStepProps } from '..'
import RevenueForm from './Form'
import { useTranslation } from 'react-i18next'

function RevenueStep(props: SetupStepProps) {
    const { step } = props
    const [ queryParameters ] = useSearchParams()
    const navigate = useNavigate()
    const { t } = useTranslation('searchSetup')

    const handleRevenueFormSubmit = useCallback((revenue: number) => {
        queryParameters.set(step.queryParameterName, revenue.toString())

        navigate({
            pathname: '/partners-search',
            search: queryParameters.toString(),
        })
    }, [ navigate, step.queryParameterName, queryParameters ])

    return (
        <RevenueForm
            header={t('revenue_header')}
            subheader={t('revenue_subheader')}
            submit={handleRevenueFormSubmit}
        />
    )
}

export default RevenueStep
