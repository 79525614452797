import { darken, lighten } from 'polished'
import {
    colors,
    fontSizes,
    boxShadows,
    breakpoints,
    lineHeights,
} from './styles'
import Theme from './Theme'

export default {
    colors,
    boxShadows,
    lineHeights,
    base: {
        bodyColor: colors.darkGrey,
    },
    pages: {
        colors: {
            base: {
                background: colors.white,
                color: colors.darkGrey,
            },
            grey: {
                background: colors.lightGrey,
                color: colors.darkGrey,
            },
        },
        graphics: {
            primary: {
                '&::before': {
                    content: 'url(/svg/Backgroundpanel.svg)',
                    position: 'fixed',
                    left: '-100px',
                    top: '600px',
                    transform: 'translateY(-50%) scale(-1, 0.9)',
                    pointerEvents: 'none',
                },
                '&::after': {
                    content: 'url(/svg/Backgroundpanel2.svg)',
                    position: 'fixed',
                    right: '-120px',
                    top: '300px',
                    transform: 'translateY(-50%) rotate(-90deg)',
                    pointerEvents: 'none',
                },
            },
            left: {
                '&::after': {
                    content: 'url(/svg/Backgroundpanel3.svg)',
                    position: 'fixed',
                    right: '-245px',
                    top: '350px',
                    transform: 'translateY(-50%) rotate(-180deg)',
                    pointerEvents: 'none',
                },
            },
        },
    },
    headers: {
        variants: {
            base: {
                height: '80px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                borderBottom: '1px solid',
                borderColor: colors.baseBlue + '25',
            },
            primary: {
                backgroundColor: colors.white,
                height: '164px',
                boxShadow: '0px 18px 20px rgba(0, 0, 0, 0.03)',
            },
        },
    },
    buttons: {
        font: {
            weight: 700,
        },
        colors: {
            primary: {
                'background': colors.electricGreen,
                'borderColor': colors.electricGreen,
                'color': colors.white,
                '&:hover': {
                    background: lighten(0.12, colors.electricGreen),
                    borderColor: lighten(0.12, colors.electricGreen),
                },
                '&:active': {
                    background: darken(0.12, colors.electricGreen),
                    borderColor: darken(0.12, colors.electricGreen),
                },
                '&:disabled': {
                    background: `${colors.electricGreen}40`,
                    borderColor: `${colors.electricGreen}00`,
                },
            },
            secondary: {
                'background': colors.baseBlue,
                'borderColor': colors.baseBlue,
                'color': colors.white,
                '&:hover': {
                    background: lighten(0.12, colors.baseBlue),
                    borderColor: lighten(0.12, colors.baseBlue),
                },
                '&:active': {
                    background: darken(0.12, colors.baseBlue),
                    borderColor: darken(0.12, colors.baseBlue),
                },
                '&:disabled': {
                    background: `${colors.baseBlue}40`,
                    borderColor: `${colors.baseBlue}00`,
                },
            },
            ternary: {
                'background': colors.skyBlueDarker,
                'borderColor': colors.skyBlueDarker,
                'color': colors.baseBlue,
                '&:hover': {
                    background: darken(0.12, colors.skyBlueDarker),
                    borderColor: darken(0.12, colors.skyBlueDarker),
                },
                '&:active': {
                    background: colors.skyBlueDarker,
                    borderColor: colors.skyBlueDarker,
                },
                '&:disabled': {
                    background: `${colors.skyBlueDarker}80`,
                    color: `${colors.baseBlue}80`,
                    borderColor: `${colors.skyBlueDarker}00`,
                },
            },
            negative: {
                'background': colors.brightRed,
                'borderColor': colors.brightRed,
                'color': colors.white,
                '&:hover': {
                    background: lighten(0.12, colors.brightRed),
                    borderColor: lighten(0.12, colors.brightRed),
                },
                '&:active': {
                    background: darken(0.12, colors.brightRed),
                    borderColor: darken(0.12, colors.brightRed),
                },
                '&:disabled': {
                    background: `${colors.brightRed}40`,
                    borderColor: `${colors.brightRed}00`,
                },
            },
            neutral: {
                'background': lighten(0.02, colors.lightGrey),
                'borderColor': lighten(0.02, colors.lightGrey),
                'color': colors.darkGrey,
                '&:hover': {
                    background: colors.lightGrey,
                    borderColor: colors.lightGrey,
                },
                '&:active': {
                    background: darken(0.12, colors.lightGrey),
                    borderColor: darken(0.12, colors.lightGrey),
                },
                '&:disabled': {
                    color: `${colors.darkGrey}80`,
                },
            },
            transparent: {
                background: 'transparent',
                borderColor: 'transparent',
                color: 'currentColor',
            },
        },
        sizes: {
            default: {
                height: '42px',
                padding: '0 24px',
                size: fontSizes[2] + 'px',
            },
            small: {
                height: '32px',
                padding: '0 7px',
                size: fontSizes[2] + 'px',
            },
        },
    },
    logos: {
        sizes: {
            small: {
                fontSize: fontSizes[0] + 'px',
                height: 35,
                width: 35,
            },
            medium: {
                fontSize: fontSizes[2] + 'px',
                height: 52,
                width: 52,
            },
            large: {
                fontSize: fontSizes[4] + 'px',
                height: 65,
                width: 65,
            },
        },
        squareBorderRadius: '4px',
        defaultBackgroundColor: '#484848',
        defaultTextColor: '#fff',
    },
    badges: {
        colors: {
            default: {
                backgroundColor: colors.lightGrey,
                color: colors.darkGrey,
            },
            success: {
                backgroundColor: colors.greyCyan,
                color: colors.electricGreen,
            },
            warning: {
                backgroundColor: colors.dawnYellow,
                color: colors.brightYellow,
            },
            danger: {
                backgroundColor: colors.lightPink,
                color: colors.brightRed,
            },
            hint: {
                backgroundColor: colors.skyBlue,
                color: colors.baseBlue,
            },
        },
        sizes: {
            small: {
                fontSize: fontSizes[1] + 'px',
                paddingLeft: 8,
                paddingRight: 8,
                height: 24,
                borderRadius: 12,
            },
            medium: {
                fontSize: fontSizes[1] + 'px',
                paddingLeft: 12,
                paddingRight: 12,
                height: 36,
                borderRadius: 16,
            },
            large: {
                fontSize: fontSizes[3] + 'px',
                paddingLeft: 28,
                paddingRight: 28,
                height: 56,
                borderRadius: 32,
            },
        },
    },
    appliedSearchFilters: {
        colors: {
            backgroundColor: colors.white,
            color: colors.baseBlue,
        },
    },
    text: {
        sizes: {
            extraSmall: {
                fontSize: fontSizes[0] + 'px',
                lineHeight: lineHeights[6],
            },
            small: {
                fontSize: fontSizes[1] + 'px',
                lineHeight: lineHeights[5],
            },
            medium: {
                fontSize: fontSizes[2] + 'px',
                lineHeight: lineHeights[4],
            },
            large: {
                fontSize: fontSizes[3] + 'px',
                lineHeight: lineHeights[4],
            },
            extraLarge: {
                fontSize: fontSizes[6] + 'px',
                lineHeight: lineHeights[4],
            },
            largeWithSpacing: {
                fontSize: fontSizes[3] + 'px',
                lineHeight: lineHeights[6],
            },
        },
        colors: {
            default: {
                color: 'inherit',
            },
            impact: {
                color: colors.darkBlue,
            },
            accent: {
                color: colors.baseBlue,
            },
            light: {
                color: colors.white,
            },
            success: {
                color: colors.electricGreen,
            },
            warning: {
                color: colors.brightYellow,
            },
            danger: {
                color: colors.brightRed,
            },
        },
    },
    headings: {
        sizes: {
            small: {
                fontSize: fontSizes[3] + 'px',
                lineHeight: lineHeights[6],
            },
            medium: {
                fontSize: fontSizes[5] + 'px',
                lineHeight: lineHeights[3],
            },
            large: {
                fontSize: fontSizes[7] + 'px',
                lineHeight: lineHeights[2],
            },
        },
    },
    inputs: {
        colors: {
            base: colors.darkBlue,
            active: colors.baseBlue,
            error: colors.brightRed,
            disabled: colors.darkGrey,
            placeholder: colors.darkGrey,
            label: colors.darkGrey,
            background: colors.lightGrey,
        },
        fontSizes: {
            label: fontSizes[0] + 'px',
            input: fontSizes[2] + 'px',
            units: fontSizes[0] + 'px',
        },
        ranges: {
            colors: {
                base: colors.lightGrey,
                thumb: colors.baseBlue,
                track: '#B7B7B7',
                label: colors.darkGrey,
            },
        },
        selects: {
            styles: {
                color: colors.darkGrey,
                backgroundColor: colors.lightGrey,
            },
            chevron: {
                color: colors.baseBlue,
            },
            dropdown: {
                styles: {
                    borderColor: colors.baseBlue + '20',
                    backgroundColor: colors.lightGrey,
                    zIndex: 2,
                    maxHeight: 200,
                    overflow: 'auto',
                },
                items: {
                    variants: {
                        hover: {
                            backgroundColor: colors.baseBlue + '20',
                            color: colors.darkGrey,
                        },
                        selected: {
                            backgroundColor: colors.baseBlue + '20',
                            color: colors.darkGrey,
                        },
                    },
                },
            },
        },
        checkboxes: {
            styles: {
                backgroundColor: darken(0.1, colors.lightGrey),
            },
        },
    },
    cards: {
        variants: {
            colors: {
                base: {
                    background: colors.white,
                    borderColor: colors.white,
                },
                impact: {
                    background: colors.darkBlue,
                    borderColor: colors.darkBlue,
                },
            },
            shadows: {
                base: {
                    boxShadow: boxShadows.light,
                },
                loan: {
                    'boxShadow': boxShadows.light,
                    '&:hover': {
                        boxShadow: boxShadows.active,
                    },
                },
            },
        },
        line: {
            variants: {
                default: {
                    backgroundColor: colors.lightGrey,
                },
                primary: {
                    backgroundColor: colors.baseBlue,
                },
            },
        },
        content: {
            variants: {
                default: {
                    text: {
                        color: colors.darkGrey,
                    },
                    heading: {
                        color: colors.darkBlue,
                    },
                },
                accent: {
                    color: colors.white,
                    backgroundColor: colors.baseBlue,
                },
            },
        },
    },
    space: [ 0, 4, 12, 24, 36, 48, 80, 96, 112, 160, 192 ],
    icons: {
        colors: {
            base: {
                backgroundColor: colors.darkGrey,
                color: colors.white,
            },
            primary: {
                backgroundColor: colors.baseBlue,
                color: colors.white,
            },
            success: {
                backgroundColor: colors.electricGreen,
                color: colors.white,
            },
            danger: {
                backgroundColor: colors.brightRed,
                color: colors.white,
            },
            warning: {
                backgroundColor: colors.brightYellow,
                color: colors.white,
            },
        },
        sizes: {
            small: {
                width: '18px',
                height: '18px',
            },
            medium: {
                width: '30px',
                height: '30px',
            },
            large: {
                width: '50px',
                height: '50px',
            },
        },
    },
    images: {
        sizes: {
            small: {
                width: '30px',
                height: '30px',
            },
            medium: {
                width: '40px',
                height: '40px',
            },
            large: {
                width: '50px',
                height: '50px',
            },
            logo: {
                width: '34px',
                height: 'auto',
            },
            inherit: {
                width: 'auto',
                height: 'auto'
            }
        },
    },
    links: {
        color: colors.baseBlue,
        textDecoration: 'underline',
    },
    sides: {
        boxShadow: boxShadows.base,
        backgroundColor: colors.white,
        transition: 'max-width .3s ease-in-out',
    },
    sideBar: {
        dimensions: {
            width: '80px',
        },
        colors: {
            background: colors.white,
            border: colors.lightGrey,
            content: colors.darkGrey,
            hover: colors.lightGrey,
        },
        boxShadows: {
            base: boxShadows.light
        },
        styles: {
            boxShadow: boxShadows.light,
            backgroundColor: colors.white,
            transition: 'width .3s ease-in-out',
        },
        items: {
            'color': colors.darkGrey,
            'borderColor': colors.lightGrey,
            '&:hover': {
                backgroundColor: colors.lightGrey,
            },
        },
    },
    topBar: {
        colors: {
            background: colors.white,
            borderColor: colors.lightGrey,
        },
        dropDown: {
            colors: {
                background: colors.skyBlue,
                backgroundHover: colors.white,
                borderColor: colors.baseBlue,
            },
        },
    },
    modals: {
        variants: {
            extraSmall: {
                width: '680px', // This is strange. This isn't responsive at all
                maxHeight: '80vh',
            },
            small: {
                width: '60vw',
                maxHeight: '80vh',
            },
            medium: {
                width: '80vw',
                maxHeight: '80vh',
            },
            large: {
                width: '100vw',
                height: '100vh',
            },
        },
    },
    actionBars: {
        boxShadow: boxShadows.base,
        backgroundColor: colors.white,
        height: '74px',
    },
    ratings: {
        starWrappers: {
            sizes: {
                extraSmall: {
                    marginRight: '2px',
                },
                small: {
                    marginRight: '3px',
                },
                medium: {
                    marginRight: '5px',
                },
                large: {
                    marginRight: '9px',
                },
            },
        },
        stars: {
            colors: {
                base: {
                    fill: colors.white,
                    stroke: colors.white,
                },
            },
            sizes: {
                extraSmall: {
                    width: '12px',
                    height: '12px',
                },
                small: {
                    width: '18px',
                    height: '18px',
                },
                medium: {
                    width: '26px',
                    height: '26px',
                },
                large: {
                    width: '35px',
                    height: '35px',
                },
            },
        },
        starBackgrounds: {
            colors: {
                base: {
                    backgroundColor: colors.lightGreen,
                },
            },
            sizes: {
                extraSmall: {
                    borderRadius: '1px',
                },
                small: {
                    borderRadius: '2px',
                },
                medium: {
                    borderRadius: '3px',
                },
                large: {
                    borderRadius: '4px',
                },
            },
            states: {
                full: {
                    width: '100%',
                },
                half: {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    width: '50%',
                },
                empty: {
                    width: '0%',
                },
            },
        },
    },
    breakpoints,
    chevron: {
        styles: {
            default: {
                color: colors.white,
                backgroundColor: colors.baseBlue,
            },
            inactive : {
                color: colors.white,
                backgroundColor: lighten(.15, colors.darkGrey),
            },
        },
        variants: {
            disabled: {
                backgroundColor: colors.baseBlue + 40,
            },
        },
    },
    progress: {
        colors: {
            inactive: colors.lightGrey,
            active: colors.electricGreen,
            content: colors.white,
        },
    },
    tabs: {
        colors: {
            active: colors.baseBlue,
            containerBorder: colors.baseBlue + '40',
        },
    },
    messages: {
        colors: {
            accent: colors.baseBlue + '80',
            white: colors.white,
            background: colors.lightGrey + '80',
        },
        shadow: boxShadows.light,
    },
    loader: {
        colors: {
            background: `${colors.baseBlue} linear-gradient(${colors.offsetBlue}, ${colors.baseBlue})`
        },
        styles: {
            squareOne: {
                width: 26,
                height: 32,
                left: 15,
                bottom: 20,
                borderRadius: '0 6px 6px 6px',
            },
            squareTwo: {
                width: 15,
                height: 18,
                left: 0,
                bottom: 51,
                borderRadius: '3px 3px 0 3px',
            },
            squareThree: {
                width: 29,
                height: 35,
                left: 20,
                bottom: 58,
                borderRadius: '6px 6px 6px 0px',
            },
        }
    },
    timer: {
        colors: {
            background: colors.lightGrey
        }
    },
    tooltip: {
        colors: {
            background: colors.white
        },
        shadow: boxShadows.active
    }
} as Theme
