import React from 'react'
import ReactDOM from 'react-dom'
import { datadogRum } from '@datadog/browser-rum'
import App from './App'

datadogRum.init({
    applicationId: '969bcd27-8b3a-40d0-bcee-3c3199cdf2f4',
    clientToken: 'pub36df8b2a5a838161af3eb64ddf4ae774',
    site: 'datadoghq.com',
    service: 'client-portal',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    env: process.env.REACT_APP_ENV,
    sampleRate: 100,
    trackInteractions: true,
})

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
)
