import React from 'react'
import styled, { keyframes } from 'styled-components'
import Flex from '../layout/Flex'

const Square = styled(Flex)`
    position: absolute;
    opacity: 0;
    background: ${({ theme }) => theme.loader.colors.background};
`

const jump = keyframes`
    0% {
        transform: translateY(0px); opacity: 0;
    }
    50% {
        transform: translateY(10px); opacity: 1;
    }
    100% {
        transform: translateY(20px); opacity: 0;
    }
`

const Square1 = styled(Square)`
    ${({ theme }) => theme.loader.styles.squareOne};
    animation: ${jump} 2.5s infinite;
`

const Square2 = styled(Square)`
    ${({ theme }) => theme.loader.styles.squareTwo};
    animation: ${jump} 2.5s .3s infinite;
`

const Square3 = styled(Square)`
    ${({ theme }) => theme.loader.styles.squareThree};
    animation: ${jump} 2.5s .6s infinite;
`

function Loader() {
    return (
        <Flex width={52} height={94} position="relative">
            <Square1 />
            <Square2 />
            <Square3 />
        </Flex>
    )
}

export default Loader
