import StepForm, { StepFormWrapperProps, SubmitButton } from 'components/src/MultiStepForm/StepForm'
import { useTranslation } from 'react-i18next'
import React, { SyntheticEvent, useCallback, useState } from 'react'
import { useAgerasApi } from 'ageras-api-client/src'
import getBasicAuth from '../../../utils/getBasicAuth'
import {
    LocationResource,
    LocationResult,
    LocationsApiFetchParamCreator,
} from 'ageras-api-client/src/api'
import { FormControl } from 'components/src/inputs/FormControl'
import Select from 'components/src/inputs/Select'
import Flex from 'components/src/layout/Flex'

function useLocations(zipCode: string, geoCode?: string) {
    const { data } = useAgerasApi<LocationResult>(
        LocationsApiFetchParamCreator().locationsIndex(
            undefined,
            geoCode,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            zipCode,
            50,
            undefined
        ),
        {
            enabled: zipCode.length ? zipCode.length > 3 : false,
            meta: { authorization: getBasicAuth() }
        }
    )
    return data?.data ?? []
}

interface PartnerSearchStepZipCodeFormProps extends StepFormWrapperProps {
    geoCode: string
    answer?: string
    handleSubmit: () => void
    handleChange: (value?: LocationResource) => void
}

function ZipCodeStepForm(props: PartnerSearchStepZipCodeFormProps) {
    const { handleSubmit, handleChange, header, geoCode, answer } = props
    const { t } = useTranslation('searchSetup')
    const [ searchZipCode, setSearchZipCode ] = useState<string>('')
    const locationOptions = useLocations(searchZipCode, geoCode)

    const populateZipCodeSelect = useCallback(() => {
        return locationOptions.find(value => value.zipCode === answer)
    }, [ locationOptions, answer ])

    const handleSearchZipCode = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
        setSearchZipCode(event.currentTarget.value)
        handleChange()
    }, [ handleChange ])

    return (
        <StepForm
            header={header}
            subheader={''}
            maxWidth="500px"
        >
            <FormControl label={t('zipcode_label')}>
                <Select
                    placeholder=""
                    items={locationOptions}
                    itemToLabel={useCallback(item => item.zipCode + ', ' + item.cityName, [])}
                    searchValue={searchZipCode}
                    onSearchChange={handleSearchZipCode}
                    onChange={handleChange}
                    selected={populateZipCodeSelect()}
                    searchable
                />
            </FormControl>
            <Flex mt={3} justifyContent="center" alignItems="center">
                <SubmitButton
                    onClick={handleSubmit}
                    variant="secondary"
                    disabled={!answer}
                >
                    {t('select')}
                </SubmitButton>
            </Flex>
        </StepForm>
    )
}

export default ZipCodeStepForm
