import React, { useCallback, useMemo } from 'react'
import * as yup from 'yup'
import { SchemaOf } from 'yup'
import { Spinner } from 'components/src/Spinner'
import Flex from 'components/src/layout/Flex'
import Heading from 'components/src/texts/Heading'
import Text from 'components/src/texts/Text'
import Page, { Graphics } from 'components/src/layout/Page'
import { Field, Form, Formik } from 'formik'
import { Input } from 'components/src/inputs/Input'
import { FormControl } from 'components/src/inputs/FormControl'
import Button from 'components/src/Button'
import { useAgerasApiMutation } from 'ageras-api-client/src'
import getBasicAuth from '../../utils/getBasicAuth'
import {
    AuthApiFetchParamCreator, AuthResource,
    CustomersApiFetchParamCreator,
    PhoneVerificationResource,
} from 'ageras-api-client/src/api'
import { useNavigate } from 'react-router-dom'
import { WithSidebar } from '../../Sidebar'
import TopBar from 'components/src/TopBar'
import useLocalStorage from '../../utils/useLocalStorage'
import { useTranslation } from 'react-i18next'
import { getGuestUser } from '../../utils/guestUser'

interface PhoneVerificationFormProps {
    phoneNumber: string
    geoCode: string
    email: string
}

interface PhoneVerificationFormValues {
    code?: string
    phoneNumber: string
    geoCode: string
}

interface phoneVerificationSendValues {
    sentAt: Date
    message: 'alreadySent'|'newCode'| ''
}

export function PhoneVerificationForm(props: PhoneVerificationFormProps) {
    const [ leadId ] = useLocalStorage<string>('storedLeadId', '')
    const [ send, setSend ] = useLocalStorage<phoneVerificationSendValues>('lastPhoneVerification', { sentAt: new Date(), message: '' })
    const [ , setSubmitLeadForm ] = useLocalStorage<string>('submitLeadForm', '')
    const { t } = useTranslation([ 'validation', 'phoneVerification' ])

    const initialValues = useMemo<PhoneVerificationFormValues>(() => ({
        code: '',
        geoCode: props.geoCode,
        phoneNumber: props.phoneNumber,
    }), [ props.geoCode, props.phoneNumber ])

    const mutation = useAgerasApiMutation<PhoneVerificationResource>(
        (actionData: PhoneVerificationResource) => CustomersApiFetchParamCreator()
            .customersPhoneverificationsCreate(actionData),
        { meta: { authorization: getBasicAuth() } }
    )

    const authMutation = useAgerasApiMutation<AuthResource>(
        (actionData: AuthResource) => AuthApiFetchParamCreator().authCreate(actionData),
    )

    const sendCode = useCallback(() => {
        const date = new Date()
        date.setMinutes(date.getMinutes() - 1)

        if (send.sentAt < date) {
            setSend({ sentAt: new Date(), message: 'newCode' })
            mutation.mutate(initialValues)
        } else {
            setSend({ sentAt: send.sentAt, message: 'alreadySent' })
        }

    }, [ initialValues, mutation, setSend, send.sentAt ])

    const onSubmit = useCallback(async (values: PhoneVerificationFormValues) => {
        setSend({ sentAt: send.sentAt, message: '' })
        const actionData: PhoneVerificationResource = {
            phoneNumber: values.phoneNumber,
            geoCode: values.geoCode,
            verificationCode: values.code,
        }
        try {
            const response = await mutation.mutateAsync(actionData)

            if (response?.client?.token) {
                const authData: AuthResource = {
                    token: response.client.token,
                    email: props.email,
                    userType: 'client',
                }
                await authMutation.mutateAsync(authData)
                setSubmitLeadForm('1')

                if (leadId) {
                    window.location.replace(`/cases/${leadId}/submission`)
                }
            }
        } catch (error) {
            window.console.log(error)
        }
    }, [ mutation, props.email, authMutation, setSubmitLeadForm, leadId, setSend, send.sentAt ])

    const phoneVerificationFormSchema: SchemaOf<PhoneVerificationFormValues> = yup.object().shape({
        phoneNumber: yup.string().required(),
        geoCode: yup.string().required(),
        code: yup.string()
            .min(6, t('validation:code_min_length'))
            .max(6, t('validation:code_max_length'))
            .required(t('validation:required')),
    })

    return (
        <Flex mt={4} flexWrap="wrap" flexDirection="column">
            {mutation.isError && <Text color="danger">{t('phoneVerification:error_code_is_wrong')}</Text>}
            {send.message === 'alreadySent' && <Text color="accent">{t('phoneVerification:already_sent')}</Text>}
            {send.message === 'newCode' && <Text color="accent">{t('phoneVerification:new_code')}</Text>}
            <Formik
                {...{ initialValues, onSubmit }}
                validationSchema={phoneVerificationFormSchema}
                validateOnChange={true}
                validateOnBlur={false}
            >
                {(formikProps) => <Form>
                    <Field name="phoneNumber" type="hidden"/>
                    <Field name="geoCode" type="hidden"/>
                    <FormControl
                        error={formikProps.touched.code ? formikProps.errors.code : undefined}
                    >
                        <Field
                            placeholder={t('phoneVerification:enter_code')}
                            name="code"
                            as={Input}
                        />
                    </FormControl>
                    <Button variant="secondary" type="submit" mt={2} disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting} grow>
                        <Flex alignItems="center">
                            {formikProps.isSubmitting && <Spinner size={18}/>}
                            <Flex flexGrow={1} justifyContent="center" mr={formikProps.isSubmitting ? 18 : 0}>
                                {t('phoneVerification:confirm_registration')}
                            </Flex>
                        </Flex>
                    </Button>
                    <Text textAlign="center" mt={3} color="accent" onClick={sendCode} cursor={formikProps.isSubmitting ? 'not-allowed' : 'pointer'}>
                        {t('phoneVerification:resend_message')}
                    </Text>
                </Form>}
            </Formik>
        </Flex>
    )
}

function PhoneVerification() {
    const navigate = useNavigate()
    const { geoCode, email, phoneNumber } = getGuestUser()
    const { t } = useTranslation('phoneVerification')

    if (!email || !phoneNumber) {
        navigate('/signup')
    }

    return (
        <WithSidebar>
            <Page>
                <Graphics graphics="primary" />
                <TopBar/>
                {geoCode && email && phoneNumber &&
                <Flex p={6} maxWidth={600} mx="auto" flexWrap="wrap" flexDirection="column">
                    <Heading size="large" textAlign="center" mb={3}>{t('check_your_phone')}</Heading>
                    <Text textAlign="center">{t('sms_sent')}</Text>
                    <PhoneVerificationForm
                        phoneNumber={phoneNumber}
                        geoCode={geoCode}
                        email={email}
                    />
                </Flex>
                }
            </Page>
        </WithSidebar>
    )
}

export default PhoneVerification
