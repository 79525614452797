import React, { useCallback } from 'react'
import Page, { Graphics } from 'components/src/layout/Page'
import Flex from 'components/src/layout/Flex'
import Heading from 'components/src/texts/Heading'
import SignUpForm from './SignUp'
import { useAuth } from 'ageras-api-client/src/auth'
import { useNavigate } from 'react-router-dom'
import { WithSidebar } from '../../Sidebar'
import TopBar from 'components/src/TopBar'
import { MenuItem } from 'components/src/TopBar/MenuItem'
import Icon from 'components/src/icons/Icon'
import { ChevronLeft } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { getGuestUser } from '../../utils/guestUser'

function SignUp() {
    const auth = useAuth()
    const navigate = useNavigate()
    const { t } = useTranslation([ 'common', 'signUp' ])
    const { geoCode, primaryIndustryId } = getGuestUser()

    const onBackClick = useCallback((): void => {
        navigate(-1)
    }, [ navigate ])

    if (auth?.client?.id) {
        navigate('/')
    }

    if (!geoCode || !primaryIndustryId) {
        navigate('/start')
    }

    return (
        <WithSidebar>
            <Page>
                <Graphics graphics="primary" />
                <TopBar>
                    <MenuItem
                        label={t('common:back')}
                        onClick={onBackClick}
                        iconLeft={<Icon mr={2} variant="accent">
                            <ChevronLeft size={22}/>
                        </Icon>}
                    />
                </TopBar>
                {geoCode && primaryIndustryId &&
                    <Flex p={6} maxWidth={600} mx="auto" flexWrap="wrap" flexDirection="column">
                        <Heading size="large" textAlign="center">
                            {t('signUp:welcome')}
                        </Heading>
                        <SignUpForm geoCode={geoCode} primaryIndustryId={primaryIndustryId}/>
                    </Flex>
                }
            </Page>
        </WithSidebar>
    )
}

export default SignUp
