import { useAgerasApi } from 'ageras-api-client/src'
import { LeadsApiFetchParamCreator, LeadTypeResource, LeadTypeResult } from 'ageras-api-client/src/api'
import SelectStepForm from 'components/src/MultiStepForm/SelectStepForm'
import { Option } from 'components/src/MultiStepForm/SelectStepForm/Option'
import { useCallback } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { getQueryPreselectedOption, getStepUrl, SetupStepProps } from '.'
import getBasicAuth from '../../utils/getBasicAuth'
import { useTranslation } from 'react-i18next'

function LeadTypeStep(props: SetupStepProps) {
    const { step, geoCode } = props
    const [ queryParameters ] = useSearchParams()
    const navigate = useNavigate()
    const { t } = useTranslation('searchSetup')

    const { isLoading, data: leadTypes } = useAgerasApi<LeadTypeResult>(
        LeadsApiFetchParamCreator().leadsTypesIndex(
            undefined,
            undefined,
            geoCode,
        ),
        { meta: { authorization: getBasicAuth() } }
    )

    const handleSubmit = useCallback((option: Option) => {
        queryParameters.set(step.queryParameterName, option.id.toString())

        navigate({
            pathname: getStepUrl(step.nextStepId || 1),
            search: queryParameters.toString(),
        })
    }, [ navigate, step.queryParameterName, step.nextStepId, queryParameters ])

    const options = leadTypes?.data == null
        ? []
        : leadTypes.data.map((type: LeadTypeResource) => ({ id: type.id, name: type.title } as Option))

    const selectedOption = getQueryPreselectedOption(step, options, queryParameters)

    return (
        <SelectStepForm
            header={t('lead_type_header')}
            subheader={t('lead_type_subheader')}
            submit={handleSubmit}
            options={options}
            actionButtonText={t('select')}
            loading={isLoading}
            preselectedOption={selectedOption}
        />
    )
}

export default LeadTypeStep
