import { AuthResource } from 'ageras-api-client/src/api'
import { useAuth } from 'ageras-api-client/src/auth'
import Flex from 'components/src/layout/Flex'
import { Link } from 'react-router-dom'
import Image from 'components/src/Image'
import Text from 'components/src/texts/Text'
import { MenuItem, MenuSpace, StyledSidebar, SidebarContainer } from 'components/src/Sidebar'
import { CreditCard, LogOut } from 'react-feather'
import { TFunction, useTranslation } from 'react-i18next'

interface SidebarContentProps {
    children: React.ReactNode
}

export function WithSidebar(props: SidebarContentProps) {
    const auth = useAuth()
    const { t } = useTranslation('menu')
    const { children } = props

    return (
        <>
            <Sidebar client={auth.client} t={t} />
            { children }
        </>
    )
}

interface SidebarProps {
    client: AuthResource['client']
    t: TFunction
}

const Sidebar = ({ client, t }: SidebarProps) => <SidebarContainer>
    <StyledSidebar>
        <Flex pt={2} pl={3} pb={8}>
            <Link to={'/'}>
                <Image variant="logo">
                    <svg width="33" height="38" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.24751 11.0469H2.83426C1.26749 11.0469 0 12.0375 0 13.2499V20.3266C0 21.547 1.26749 22.5296 2.83426 22.5296H11.0818V13.2499C11.0818 12.0375 9.81427 11.0469 8.24751 11.0469Z" fill="#0A7AE0"/>
                        <path d="M19.533 0H28.3614C30.9228 0 33.0001 1.6087 33.0001 3.59777V15.1598C33.0001 17.1489 30.9228 18.7575 28.3614 18.7575H14.8943V3.59777C14.9031 1.6087 16.9716 0 19.533 0Z" fill="#0A7AE0"/>
                        <path d="M22.8769 22.498H11.0029V35.6371C11.0029 36.8574 12.2704 37.8401 13.8372 37.8401H22.8769C24.4436 37.8401 25.7111 36.8495 25.7111 35.6371V24.709C25.7199 23.4886 24.4524 22.498 22.8769 22.498Z" fill="#0A7AE0"/>
                    </svg>
                </Image>
            </Link>
        </Flex>
        {client && <MenuItem as={Link} to={'/cases'}>
            <CreditCard size={20}/>
            <Text ml={16}>{t('current_cases')}</Text>
        </MenuItem>}
        <MenuSpace />
        {client && <MenuItem as={Link} to={'/logout'}>
            <LogOut size={20}/>
            <Text ml={16}>{t('logout')}</Text>
        </MenuItem>}
    </StyledSidebar>
</SidebarContainer>

export default Sidebar
