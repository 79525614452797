import styled from 'styled-components'

const ActionBar = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    ${({ theme }) => theme.actionBars};
`

export default ActionBar
