import styled from 'styled-components'
import { variant, space, SpaceProps } from 'styled-system'

export interface IconProps extends SpaceProps {
    onClick?: (event: any) => void
    variant?: 'default' | 'impact' | 'accent' | 'light' | 'success' | 'warning' | 'danger'
}

const Icon = styled.span<IconProps>`
    display: inline-flex;
    align-items: center;
    ${props => props.onClick && 'cursor: pointer;'}

    ${space};

    ${variant({
        prop: 'variant',
        scale: 'text.colors',
    })}
`

export default Icon
