import { useEffect, useState } from 'react'

interface Options {
    detectOffset?: string
    stopTrackingAfterFirstVisible?: boolean
}

function useIntersectionObserver(element: HTMLElement | null, options: Options): IntersectionObserverEntry | undefined {
    const [ entry, setEntry ] = useState<IntersectionObserverEntry>()
    const { detectOffset, stopTrackingAfterFirstVisible } = options

    const stopTracking = entry?.isIntersecting && stopTrackingAfterFirstVisible

    const updateEntry = ([ entry ]: IntersectionObserverEntry[]): void => {
        setEntry(entry)
    }

    useEffect(() => {
        const supportsObserver = window.IntersectionObserver != null

        if (!supportsObserver || stopTracking || !element) {
            return undefined
        }

        const observer = new IntersectionObserver(updateEntry, {
            rootMargin: detectOffset
        })

        observer.observe(element)

        return () => observer.disconnect()
    }, [ detectOffset, stopTracking, element ])

    return entry
}

export default useIntersectionObserver
