import styled from 'styled-components'
import { variant, space, SpaceProps, typography, TypographyProps, flexbox, FlexboxProps } from 'styled-system'
import Flex from '../layout/Flex'

export type BadgeVariantTypes = 'default' | 'success' | 'warning' | 'danger' | 'hint'
export type BadgeSizeTypes = 'medium' | 'large' | 'small'

export interface BadgeProps extends SpaceProps, TypographyProps, FlexboxProps {
    variant?: BadgeVariantTypes
    size?: BadgeSizeTypes
}

const Badge = styled(Flex)<BadgeProps>`
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    width: max-content;

    ${typography};
    ${flexbox};
    ${space};

    ${variant({
        scale: 'badges.colors',
    })}

    ${variant({
        prop: 'size',
        scale: 'badges.sizes',
    })}
`

Badge.defaultProps = {
    variant: 'default',
    size: 'medium',
}

export default Badge
