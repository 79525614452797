import React, { useCallback } from 'react'
import { Spinner } from 'components/src/Spinner'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { SchemaOf } from 'yup'
import Flex from 'components/src/layout/Flex'
import Grid from 'components/src/layout/Grid'
import PhoneNumber, { getDefaultSelectedCountry } from 'components/src/inputs/PhoneNumber'
import { useFormik } from 'formik'
import { Input } from 'components/src/inputs/Input'
import Button from 'components/src/Button'
import { isValidNumber } from 'libphonenumber-js'
import { useAgerasApiMutation } from 'ageras-api-client/src'
import {
    CustomersApiFetchParamCreator,
    LeadResource,
    LeadsApiFetchParamCreator,
    PhoneVerificationResource,
} from 'ageras-api-client/src/api'
import getBasicAuth from '../../utils/getBasicAuth'
import { useTranslation } from 'react-i18next'
import useLocalStorage from '../../utils/useLocalStorage'
import { getCountryNames } from '../../utils'
import { getGuestUser, setGuestUser } from '../../utils/guestUser'
import { FormControl } from 'components/src/inputs/FormControl'

interface CampaignKeys {
    [countryCode: string]: {
        [industryId: string]: string
    }
}

const campaignKeys: CampaignKeys = {
    dk: {
        1: 'uhuf5xdve0x8fl07t04ayo5',
        2: 'dddfd-9b25e-04a97-b09c0',
    },
    se: {
        202: 'c7j6ziab39aeuunetc2fewy',
    },
    no: {
        224: 'evpahszc0i89idu5e8xcjx2',
    },
    us: {
        466: '5d2bs0d7mgf5jmn0q4y3d9w',
    },
    nl: {
        288: '3ziwifvpyyow2u62jo5p31o',
    },
    de: {
        309: 'y6cg5cmtdpbor9m41siagfe',
    },
}

interface SignUpFormValues {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
}

interface SignUpFormProps {
    geoCode: string
    primaryIndustryId: string
}

function SignUpForm({ geoCode, primaryIndustryId }: SignUpFormProps) {
    const { t, } = useTranslation([ 'common', 'signUp', 'validation', 'countries' ])
    const { t: countries } = useTranslation('countries')
    const countryNames = getCountryNames(countries)

    const guestUser = getGuestUser()
    const [ , setStoredLeadId ] = useLocalStorage<string>('storedLeadId', '')
    const [ , setLeadCreatedAt ] = useLocalStorage<string>('storedLeadCreatedAt', '')
    const navigate = useNavigate()
    const [ selectedCountry, setSelectedCountry ] = React.useState<typeof countryNames[0]>(
        getDefaultSelectedCountry(String(guestUser.geoCode), countryNames)
    )

    const initialValues = {
        firstName: guestUser.firstName || '',
        lastName: guestUser.lastName || '',
        email: guestUser.email || '',
        phoneNumber: '',
    }

    const validationSchema: SchemaOf<SignUpFormValues> = yup.object().shape({
        firstName: yup.string().required(t('validation:required')),
        lastName: yup.string().required(t('validation:required')),
        email: yup.string().email(t('validation:invalid_email')).required(t('validation:required')),
        phoneNumber: yup.string().min(6, ' ').test(
            'validPhoneNumber',
            t('validation:not_valid_phone_number'),
            async value => {
                return isValidNumber(value ? value : '')
            },
        ).required(t('validation:required')),
    })

    const phoneVerificationMutation = useAgerasApiMutation<PhoneVerificationResource>(
        (actionData: PhoneVerificationResource) => CustomersApiFetchParamCreator()
            .customersPhoneverificationsCreate(actionData),
        { meta: { authorization: getBasicAuth() } }
    )

    const leadMutation = useAgerasApiMutation<LeadResource>(
        (actionData: LeadResource) => LeadsApiFetchParamCreator().leadsCreate(actionData),
        { meta: { authorization: getBasicAuth() } }
    )

    const onSubmit = useCallback(async (values: SignUpFormValues) => {
        const leadData: LeadResource = {
            client: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phoneNumber,
                mobile: values.phoneNumber,
            },
            geo: {
                zipCode: guestUser.zipCode,
            },
            affiliateCampaign: {
                key: campaignKeys[geoCode][primaryIndustryId],
            },
        }

        if (guestUser.childIndustryId) {
            leadData.industries = {
                secondary: {
                    id: guestUser.childIndustryId as unknown as number,
                },
            }
        }

        if (guestUser.leadTypeId) {
            leadData.client = { ...leadData.client, customerType: { id: guestUser.leadTypeId as unknown as number } }
        }

        guestUser.email = values.email
        guestUser.firstName = values.firstName
        guestUser.lastName = values.lastName
        guestUser.phoneNumber = values.phoneNumber
        setGuestUser(guestUser)

        try {
            const leadResponse = await leadMutation.mutateAsync(leadData)
            setStoredLeadId(leadResponse.id as unknown as string)
            setLeadCreatedAt(leadResponse.createdAt as string)
            const phoneVerificationData: PhoneVerificationResource = {
                phoneNumber: values.phoneNumber,
                geoCode: guestUser.geoCode,
                verificationCode: '',
            }
            phoneVerificationMutation.mutate(phoneVerificationData)
            navigate('/phone-verification')
        } catch (e) {
            window.console.log(e)
        }
    }, [ guestUser, geoCode, primaryIndustryId, leadMutation, setStoredLeadId, setLeadCreatedAt, phoneVerificationMutation, navigate ])

    const formik = useFormik<SignUpFormValues>({
        validateOnChange: true,
        initialValues,
        onSubmit,
        validationSchema,
    })

    const itemToLabel = useCallback(item => item.label, [])
    const itemToCode = useCallback(item => item.code, [])

    const onCountryChange = useCallback(selected => {
        if (selected) {
            setSelectedCountry(selected)
        }
    }, [ setSelectedCountry ])

    const onChange = useCallback(phone => {
        formik.setFieldValue('phoneNumber', phone)
        formik.setFieldTouched('phoneNumber', true, false)
    }, [ formik ])

    return (
        <Flex mt={4} flexDirection="column">
            <form onSubmit={formik.handleSubmit}>
                <Grid gridTemplateColumns="1fr 1fr" gridGap="30px">
                    <FormControl
                        error={formik.touched.firstName ? formik.errors.firstName : undefined}
                        label={t('signUp:first_name')}
                    >
                        <Input
                            name="firstName"
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                        />
                    </FormControl>
                    <FormControl
                        error={formik.touched.lastName ? formik.errors.lastName : undefined}
                        label={t('signUp:last_name')}
                    >
                        <Input
                            name="lastName"
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                        />
                    </FormControl>
                </Grid>
                <FormControl
                    error={formik.touched.email ? formik.errors.email : undefined}
                    label={t('signUp:email')}
                >
                    <Input
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                </FormControl>
                <FormControl error={formik.touched.phoneNumber ? formik.errors?.phoneNumber : undefined} label={t('signUp:phone_number_enter')}>
                    <PhoneNumber
                        name="phoneNumber"
                        placeholder={t('signUp:phone_number_enter')}
                        value={formik.values.phoneNumber}
                        items={countryNames}
                        selected={selectedCountry}
                        itemToCode={itemToCode}
                        itemToLabel={itemToLabel}
                        onCountryChange={onCountryChange}
                        onChange={onChange}
                    />
                </FormControl>
                <Flex justifyContent="center" mt={3}>
                    <Button variant="secondary" type="submit" mt={2} disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting} grow>
                        <Flex alignItems="center">
                            {formik.isSubmitting && <Spinner size={18}/>}
                            <Flex flexGrow={1} justifyContent="center" mr={formik.isSubmitting ? 18 : 0}>
                                {t('signUp:sign_in')}
                            </Flex>
                        </Flex>
                    </Button>
                </Flex>
            </form>
        </Flex>
    )
}

export default SignUpForm
