import React, { ChangeEvent, useCallback, useRef, MouseEvent, useEffect } from 'react'
import styled, { css } from 'styled-components'

export interface RangeInputProps {
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    onMouseUp?: (event: MouseEvent<HTMLInputElement>) => void
}

const RangeInput = styled.input.attrs({ type: 'range' })<RangeInputProps>`
    height: 10px;
    cursor: pointer;
    width: 100%;
    background-color: transparent;
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0px;
        height: 0px;
        background-color: transparent;
        cursor: pointer;
        margin-top: -20px;
        border-style: solid;
        border-width: 20px 10px 0 10px;
        border-color: ${({ theme }) => theme.inputs.ranges.colors.thumb} transparent transparent transparent;
    }

    &::-moz-range-thumb {
        width: 0px;
        height: 0px;
        background-color: transparent;
        cursor: pointer;
        padding-bottom: 5px;
        border-style: solid;
        border-width: 12px 10px 10px 10px;
        border-color: ${({ theme }) => theme.inputs.ranges.colors.thumb} transparent transparent;
    }

    &::-webkit-slider-runnable-track {
        width: 100%;
        cursor: pointer;
        border-bottom: 2px solid ${({ theme }) => theme.inputs.ranges.colors.track};
    }

    &::-moz-range-track {
        width: 100%;
        cursor: pointer;
        border-bottom: 2px solid ${({ theme }) => theme.inputs.ranges.colors.track};
    }
`

interface RangeWrapperProps {
    width: string
}

const RangeWrapper = styled.label<RangeWrapperProps>`
    width: ${({ width }) => width};
    display: block;
    position: relative;
    padding-left: 2px;
    user-select: none;
    font-size: ${({ theme }) => theme.inputs.fontSizes.input};
    color: ${({ theme }) => theme.inputs.ranges.colors.base};
    cursor: pointer;
    line-height: 32px;
`

const Left = styled.span`
    position: absolute;
    top: -10px;
    left: 0px;
    color: ${({ theme }) => theme.inputs.ranges.colors.label};
`

const Right = styled.span`
    position: absolute;
    top: -10px;
    right: -7px;
    color: ${({ theme }) => theme.inputs.ranges.colors.label};
`

interface OutputProps {
    left: string
}

const Output = styled.output<OutputProps>`
    color: ${({ theme }) => theme.inputs.ranges.colors.label};
    position: absolute;

    top: 10px;
    transform: translateX(-50%);
    ${({ left }) => left && css`
        left: ${left};
    `}
`

interface OutputUnitProps {
    defaultValue: any
}

const OutputUnit = styled.span<OutputUnitProps>`
    display: none;
    font-size: ${({ theme }) => theme.inputs.fontSizes.units};
    ${({ defaultValue }) => defaultValue && css`
        display: inline;
    `}
`

export interface RangeProps {
    onChange: (value: any) => void
    width: string
    min: number
    max: number
    unit?: string
    step?: number
    disabled?: boolean
    value?: number
}

const Range = ({ min, max, value, step, disabled, width, unit, onChange }: RangeProps) => {
    const refOutput = useRef<HTMLOutputElement>(null)
    const refOutputValue = useRef<HTMLSpanElement>(null)
    const refOutputUnit = useRef<HTMLSpanElement>(null)
    const getThumbPosition = useCallback((newValue: number) => 12 - (newValue * 0.2), [])
    const getThumbValue = useCallback((newValue: string|number) =>
        Number((parseInt(newValue as string, 10) - min) * 100 / (max - min)), [ max, min ]
    )

    const refreshThumb = useCallback ((newValue: any) => {
        if (parseInt(newValue, 10) === max) {
            refOutputValue!.current!.innerHTML = '∞'
        } else {
            refOutputValue!.current!.innerHTML = newValue
        }

        if (parseInt(newValue, 10) === max && refOutputUnit.current) {
            refOutputUnit.current.style.display = 'none'
        } else if (refOutputUnit.current) {
            refOutputUnit.current.style.display = 'inline'
        }

        const recalculatedValue = getThumbValue(newValue)
        const newPosition = getThumbPosition(recalculatedValue)
        refOutput!.current!.style.left = `calc(${recalculatedValue}% + (${newPosition}px))`
    }, [ max, refOutputValue, refOutputUnit, getThumbValue, getThumbPosition ])

    useEffect(() => {
        refreshThumb(value ?? max)
    }, [ max, refreshThumb, value ])

    const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        refreshThumb(event.currentTarget.value)
    }, [ refreshThumb ])

    const handleOnMouseUp = useCallback((event: MouseEvent<HTMLInputElement>) => {
        onChange((event.target as HTMLInputElement).value)
    }, [ onChange ])

    const thumbValue = getThumbValue(value ?? max)
    const thumbPosition = getThumbPosition(thumbValue)
    const left = `calc(${thumbValue}% + (${thumbPosition}px))`
    return (
        <RangeWrapper width={width}>
            <Left>
                {min}
            </Left>
            <Right>
                ∞
            </Right>
            <RangeInput
                type="range"
                step={step}
                min={min}
                max={max}
                defaultValue={value}
                disabled={disabled}
                onChange={handleOnChange}
                onMouseUp={handleOnMouseUp}
            />
            <Output ref={refOutput} left={left}>
                <span ref={refOutputValue}>
                    {value}
                </span>
                {unit && <OutputUnit ref={refOutputUnit} defaultValue={value}>
                    {unit}
                </OutputUnit>}
            </Output>
        </RangeWrapper>
    )
}

export default Range
