import React, { useCallback } from 'react'
import { useAgerasApi } from 'ageras-api-client/src'
import {
    LeadQuoteResult,
    LeadQuoteResource,
    LeadsApiFetchParamCreator,
    PartnerResource, LeadResource,
} from 'ageras-api-client/src/api'
import { useNavigate, useParams } from 'react-router-dom'
import Text from 'components/src/texts/Text'
import Heading from 'components/src/texts/Heading'
import Flex from 'components/src/layout/Flex'
import { useAuth } from 'ageras-api-client/src/auth'
import Page, { Graphics } from 'components/src/layout/Page'
import Header from 'components/src/Header'
import Badge from 'components/src/Badge'
import Side from 'components/src/Side'
import Amount from 'components/src/Amount'
import OfferCard from './OfferCard'
import PartnerInfoCard from './PartnerInfoCard'
import { configCurrencies, GeoCodes, leadProgressStatuses, getLeadProgressStatus } from '../../utils'
import { WithSidebar } from '../../Sidebar'
import { useTranslation } from 'react-i18next'

interface QuoteListRouteParams {
    caseId: string
}

function QuoteList() {
    const [ partner, setPartner ] = React.useState<null | PartnerResource>(null)
    const [ sideOpen, setSideOpen ] = React.useState<boolean>(false)
    const { caseId } = useParams<keyof QuoteListRouteParams>() as QuoteListRouteParams
    const auth = useAuth()
    const navigate = useNavigate()
    const { t } = useTranslation([ 'quoteList', 'common' ])

    const { data: lead } = useAgerasApi<LeadResource>(
        LeadsApiFetchParamCreator().leadsGet(caseId)
    )

    const { isLoading, error, data } = useAgerasApi<LeadQuoteResult>(
        LeadsApiFetchParamCreator().leadsQuotesIndex_3(caseId, String(auth?.client?.id))
    )

    const closeSide: () => void = useCallback(() => {
        setSideOpen(false)
        setPartner(null)
    }, [])

    const progressStatus = lead ? getLeadProgressStatus(lead) : ''
    if (![ leadProgressStatuses.awaitingQuotes, leadProgressStatuses.awaitingSelection, leadProgressStatuses.inProgress ].includes(progressStatus)) {
        navigate('/cases', { replace: true })
        return null
    }

    const leadAttributes = lead?.attributes ? Object.values(lead.attributes) : []
    const leadPrimaryIndustry = lead?.industries?.primary?.identifier
    const showPricesWithVat = lead?.customerInfo?.showPricesIncludingVat

    const formatLeadAttributeValue = (value: string | undefined, identifier: string | undefined, geoCode: string | undefined) => {
        if (!value || !identifier || !geoCode) {
            return null
        }
        switch (identifier) {
            case 'loan_amount':
                return <Amount number={Number(value)} currency={configCurrencies[geoCode as GeoCodes]} />
            case 'loan_duration_months':
                return `${value} months`
        }
    }

    return (
        <WithSidebar>
            <Page>
                <Graphics graphics="primary" />
                <Header variant="primary">
                    <Flex px={6} py={3} flexDirection="column">
                        <Heading size="large" mb={2}>
                            {t('quoteList:compare_offers')}
                        </Heading>
                        <Text size="large">{t('quoteList:showing_offers_for')}</Text>
                        {Boolean(leadAttributes.length) && <Flex mt={3}>
                            {leadAttributes.filter(attr => attr.identifier && [ 'loan_amount', 'loan_duration_months' ].includes(attr.identifier)).map(attr => <Badge mr={3} size="medium" key={attr.id}>
                                {formatLeadAttributeValue(attr.selected?.[0].title, attr.identifier, lead?.geo?.code)}
                            </Badge>)}
                        </Flex>}
                    </Flex>
                </Header>
                <Flex p={6} maxWidth={1300} mx="auto" flexDirection="column">
                    {isLoading && <Text>{t('common:loading')}</Text>}
                    {error && <Text>{t('common:error_occurred', { message: error.message })}</Text>}
                    {data?.data && !Boolean(data.data.length) && <Text>{t('quoteList:no_offers')}</Text>}
                    {data?.data && Boolean(data.data.length) && <Flex flexDirection="column">
                        {data.data.map((quote: LeadQuoteResource) => (
                            <OfferCard
                                industry={leadPrimaryIndustry}
                                showPricesWithVat={showPricesWithVat}
                                key={'quote_' + quote.id}
                                quote={quote}
                                caseId={caseId}
                                isOpen={sideOpen}
                                displayedPartner={partner}
                                setIsOpen={setSideOpen}
                                setPartner={setPartner}
                            />
                        ))}
                    </Flex>}
                </Flex>
                <Side open={sideOpen}>
                    <PartnerInfoCard partner={partner} closeSide={closeSide} />
                </Side>
            </Page>
        </WithSidebar>
    )
}

export default QuoteList
