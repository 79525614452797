import { useCallback, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SetupStepProps } from '..'
import { useTranslation } from 'react-i18next'
import { LocationResource } from 'ageras-api-client/src/api'
import ZipCodeForm from './Form'

function ZipCodeStep(props: SetupStepProps) {
    const { step, geoCode } = props
    const navigate = useNavigate()
    const { t } = useTranslation('searchSetup')
    const [ answer, setAnswer ] = useState<string | undefined>(undefined)
    const [ queryParameters ] = useSearchParams()

    const handleSubmit = useCallback(() => {
        queryParameters.set(step.queryParameterName, String(answer))
        navigate({
            pathname: '/start', // TODO: add logic for when the partner search will be used again
            search: queryParameters.toString(),
        })
    }, [ navigate, answer, queryParameters, step.queryParameterName ])

    const handleChange = useCallback((value?: LocationResource) => {
        setAnswer(value?.zipCode ? String(value?.zipCode) : undefined)
    }, [ setAnswer ])

    return (
        <ZipCodeForm
            answer={answer}
            geoCode={String(geoCode)}
            header={t('zipcode_header')}
            subheader={t('zipcode_subheader')}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
        />
    )
}

export default ZipCodeStep
