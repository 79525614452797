import styled from 'styled-components'
import { SpaceProps, variant, space } from 'styled-system'

export interface CircleProps extends SpaceProps {
    size?: 'small' | 'medium' | 'large'
    variant?: 'base' | 'primary' | 'success' | 'danger' | 'warning'
}

const Circle = styled.div<CircleProps>`
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    display: inline-block;
    ${space};

    & > * {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: inherit;
    }

    ${variant({
        prop: 'size',
        scale: 'icons.sizes',
    })}

    ${variant({
        scale: 'icons.colors',
    })}
`

Circle.defaultProps = {
    size: 'small',
    variant: 'primary',
}

export default Circle
