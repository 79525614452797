// eslint-disable-next-line implicit-dependencies/no-implicit
import { useJsApiLoader } from '@react-google-maps/api'

interface Options {
    overwriteGoogleMapsApiKey?: string
    overwriteScriptId?: string
}

function useGoogleMapsApiScript(options?: Options): [boolean, Error | undefined] {
    const { overwriteScriptId, overwriteGoogleMapsApiKey } = options || {}

    const { isLoaded: isMapLoaded, loadError: error } = useJsApiLoader({
        id: overwriteScriptId || 'google-map-script',
        googleMapsApiKey: overwriteGoogleMapsApiKey || '',
    })

    return [ isMapLoaded, error ]
}

export default useGoogleMapsApiScript
