import React from 'react'
import styled, { css } from 'styled-components'
import { variant as styledVariant, space, SpaceProps } from 'styled-system'

interface StyledImageProps extends SpaceProps {
    variant?: 'small' | 'medium' | 'large' | 'logo' | 'inherit'
    round?: boolean
    background?: boolean
}

const StyledImage = styled.div<StyledImageProps>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    ${space};

    & > img,
    & > svg {
        max-width: 100%;
        height: auto;
    }

    ${styledVariant({
        scale: 'images.sizes',
    })}

    ${({ round }) => round && css`
        overflow: hidden;
        border-radius: 32%;
    `}

    ${({ background, theme }) => background && css`
        background: ${theme.colors.white};
    `}
`

StyledImage.defaultProps = {
    variant: 'small',
}

type RequireAtLeastOne<T> = { [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>; }[keyof T]

interface ContentProps {
    src?: string
    children?: any
}

interface ExtendedImageProps {
    alt?: string
}

type ImageProps = StyledImageProps & RequireAtLeastOne<ContentProps> & ExtendedImageProps

const Image = ({
    src,
    alt,
    children,
    variant,
    round,
    background,
}: ImageProps) => <StyledImage variant={variant} round={round} background={background}>
    {src ? <img src={src} alt={alt}/> : children}
</StyledImage>

export default Image
