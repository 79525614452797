import React from 'react'
import Flex from '../layout/Flex'

export interface MultiStepFormProps {
    currentStepNumber: number
    children: React.ReactNode[]
}

function MultiStepForm(props: MultiStepFormProps) {
    const { currentStepNumber, children } = props

    return (
        <Flex justifyContent="center" alignItems="center" height="inherit" width="90%" minWidth={300}>
            { children.map((child: React.ReactNode, index: number) => {
                if (React.isValidElement(child) && (index === currentStepNumber - 1)) {
                    return React.cloneElement(child, {
                        ...child.props,
                        key: `multi-step-form-element-${index}`
                    })
                }

                return null
            }) }
        </Flex>
    )
}

export default MultiStepForm
