import React, { useCallback } from 'react'

import Flex from 'components/src/layout/Flex'
import Heading from 'components/src/texts/Heading'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AppliedSearchFilter from 'components/src/AppliedSearchFilter'
import RemovableSearchFilter from 'components/src/AppliedSearchFilter/RemovableSearchFilter'
import { configCurrencies, GeoCodes, partnersSearchParams, getDistanceUnitByGeoCode } from '../../utils'
import Amount from 'components/src/Amount'
import { useAgerasApi } from 'ageras-api-client/src'
import { IndustriesApiFetchParamCreator, IndustryResult } from 'ageras-api-client/src/api'
import getBasicAuth from '../../utils/getBasicAuth'
import { useTranslation } from 'react-i18next'

const VISIBLE_PARAMS = [
    partnersSearchParams.revenue,
    partnersSearchParams.childIndustry,
    partnersSearchParams.distance,
    partnersSearchParams.search,
]

interface AppliedSearchFiltersSectionProps {
    handleRevenueFilterRemoval: () => void
}

function isFilterSectionVisible(searchParams: URLSearchParams): boolean {
    const searchParamsEntries = searchParams.entries()
    for (const [ key ] of Array.from(searchParamsEntries)) {
        if (VISIBLE_PARAMS.includes(key)) {
            return true
        }
    }

    return false
}

function RenderSubindustryFilter(paramName: string, geoCode: string, parentIndustryId: string) {
    const [ searchParams ] = useSearchParams()
    const paramValue = searchParams.get(paramName)

    const { data: industries } = useAgerasApi<IndustryResult>(
        IndustriesApiFetchParamCreator().industriesIndex(
            undefined,
            undefined,
            geoCode || undefined,
            undefined,
            undefined,
            parentIndustryId || undefined
        ),
        { meta: { authorization: getBasicAuth() } },
    )

    if (!paramValue || !geoCode || !industries || !industries.data) {
        return null
    }

    const industry = industries.data.find(
        entry => entry.id === parseInt(paramValue, 10)
    )
    if (!industry) {
        return null
    }

    return <AppliedSearchFilter label={industry.name || ''} />
}

function RenderDistanceFilter(paramName: string, geoCode: string) {
    const { t } = useTranslation('searchResultList')
    const [ searchParams ] = useSearchParams()

    const paramValue = searchParams.get(paramName)
    if (!paramValue) {
        return null
    }

    const label = t('applied_filters.distance', { distance: paramValue || '' })
    const unit = getDistanceUnitByGeoCode(geoCode)
    return <AppliedSearchFilter label={label + ' ' + unit} />
}

function RenderRevenueFilter(paramName: string, geoCode: string, handleRevenueFilterRemoval: () => void) {
    const { t } = useTranslation('searchResultList')
    const [ searchParams ] = useSearchParams()
    const paramValue: number = parseInt(searchParams.get(paramName) || '', 10)
    const currency = configCurrencies[geoCode as GeoCodes]

    if (!paramValue) {
        return null
    }

    const label = <>
        {t('applied_filters.revenue')}
        "
        <Amount number={paramValue} currency={currency} />
        "
    </>

    return <RemovableSearchFilter label={label} onRemove={handleRevenueFilterRemoval} />
}

function RenderSearchFilter(paramName: string) {
    const { t } = useTranslation('searchResultList')
    const [ searchParams ] = useSearchParams()
    const paramValue = searchParams.get(paramName)
    const navigate = useNavigate()

    const handleClick = useCallback(() => {
        searchParams.set(paramName, '')

        navigate({
            search: searchParams.toString(),
        })
    }, [ navigate, paramName, searchParams ])

    if (!paramValue) {
        return null
    }

    const label = t('applied_filters.search', { term: paramValue })
    return <RemovableSearchFilter label={label} onRemove={handleClick} />
}

function AppliedSearchFiltersSection(props: AppliedSearchFiltersSectionProps) {
    const { handleRevenueFilterRemoval } = props
    const { t } = useTranslation('searchResultList')
    const [ searchParams ] = useSearchParams()

    if (!isFilterSectionVisible(searchParams)) {
        return null
    }

    const geoCode = searchParams.get(partnersSearchParams.geoCode) || ''
    const parentIndustryId = searchParams.get(partnersSearchParams.industry)  || ''

    return (
        <Flex width={1} height={80} mb={3} flexDirection="column" >
            <Heading size="medium" >{t('applied_filters.heading')}</Heading>
            <Flex pt={2}>
                {RenderSubindustryFilter(partnersSearchParams.childIndustry, geoCode, parentIndustryId)}
                {RenderDistanceFilter(partnersSearchParams.distance, geoCode)}
                {RenderRevenueFilter(partnersSearchParams.revenue, geoCode, handleRevenueFilterRemoval)}
                {RenderSearchFilter(partnersSearchParams.search)}
            </Flex>
        </Flex>
    )
}

export default AppliedSearchFiltersSection
