import styled from 'styled-components'
import { space, SpaceProps, layout, LayoutProps } from 'styled-system'

const anchorAttributes = {
    target: '_blank',
    rel: 'noreferrer',
}

const Link = styled.a.attrs(anchorAttributes)<SpaceProps & LayoutProps>`
    display: inline-block;
    ${({ theme }) => theme.links};
    ${space};
    ${layout};

    &:hover {
        cursor: pointer;
    }
`

export default Link
