import React from 'react'
import { AppliedSearchFilterProps, AppliedSearchFilterWrapper } from '../index'
import Text from '../../texts/Text'
import Icon from '../../icons/Icon'
import { X } from 'react-feather'

export interface RemovableSearchFilterProps extends Omit<AppliedSearchFilterProps, 'onClick'> {
    onRemove: () => void
}

function RemovableSearchFilter(props: RemovableSearchFilterProps) {
    return (
        <AppliedSearchFilterWrapper>
            <Text>{props.label}</Text>
            <Icon onClick={props.onRemove}><X size={22}/></Icon>
        </AppliedSearchFilterWrapper>
    )
}

export default RemovableSearchFilter
