import React from 'react'

export interface FormattedDateProps {
    datetime: string
    options?: Intl.DateTimeFormatOptions
}

const FormattedDate = (props: FormattedDateProps) => {
    const locale = navigator.language
    const formatted = new Date(props.datetime)
        .toLocaleString(locale, props.options ?? { year: 'numeric', month: 'long', day: 'numeric' })
    return <>{formatted}</>
}

export default FormattedDate
