import { createContext, useContext } from 'react'
import { AuthResource } from './api'

const AuthContext = createContext<AuthResource>({})

export const useAuth = (): AuthResource => {
    return useContext(AuthContext)
}

export const ContextProvider = AuthContext.Provider
