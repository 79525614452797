import styled, { keyframes } from 'styled-components'
import Flex, { FlexProps } from '../layout/Flex'

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

export interface SpinnerProps {
    size?: number
}

export const Spinner = styled(Flex)<SpinnerProps & FlexProps>`
    width: ${({ size }) => size ? `${size}px` : '24px'};
    height: ${({ size }) => size ? `${size}px` : '24px'};
    border-radius: 50%;
    border: solid currentColor;
    border-width: 0 3px 0 0;
    animation: ${spin} 1s linear infinite;
`
