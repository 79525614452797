import { useState } from 'react'

function useLocalStorage<T>(key: string, initialValue: T) {
    const [ storedValue, setStoredValue ] = useState<T>(() => {
        try {
            const item = window.localStorage.getItem(key)
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            window.console.error(error)
            return initialValue
        }
    })

    const setValue = (value: T | ((val: T) => T)) => {
        try {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value
            setStoredValue(valueToStore)
            if (!valueToStore) {
                window.localStorage.removeItem(key)
            } else {
                window.localStorage.setItem(key, JSON.stringify(valueToStore))
            }
        } catch (error) {
            window.console.error(error)
        }
    }

    return [ storedValue, setValue ] as const
}

export default useLocalStorage
