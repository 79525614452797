import React, { ReactNode, useCallback } from 'react'
import styled from 'styled-components'
import Flex from '../../layout/Flex'

interface OptionBodyProps {
    selected: boolean
}

const OptionBody = styled(Flex)<OptionBodyProps>`
    border-radius: 0 8px;
    padding: 20px;
    background-color: ${({ theme: { colors } }) => colors.lightGrey};
    font-weight: 500;
    cursor: pointer;
    border: solid 2px ${({ theme: { colors }, selected }) => selected
        ? colors.baseBlue
        : colors.lightGrey};
    color: ${({ theme: { colors }, selected }) => selected
        ? colors.black
        : colors.darkGrey};
    word-break: break-word;
    text-align: center;
`

export interface Option {
    id: number
    name: ReactNode
}

export interface OptionElementProps {
    selected: boolean
    select: () => void
    option: Option
}

function OptionElement(props: OptionElementProps) {
    const { selected, select, option } = props

    const handleOptionClick = useCallback(() => {
        select()
    }, [ select ])

    const { name } = option

    return (
        <OptionBody
            alignItems="center"
            justifyContent="center"
            onClick={handleOptionClick}
            selected={selected}
        >
            { name }
        </OptionBody>
    )
}

export default OptionElement
