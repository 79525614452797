import React from 'react'
import { PartnerAttributeResource, PartnerResource } from 'ageras-api-client/src/api'
import Button from 'components/src/Button'
import Text from 'components/src/texts/Text'
import Heading from 'components/src/texts/Heading'
import Flex from 'components/src/layout/Flex'
import Grid from 'components/src/layout/Grid'
import Image from 'components/src/Image'
import Link from 'components/src/Link'
import Badge from 'components/src/Badge'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'

interface TPartnerInfoCard {
    partner: PartnerResource | null
    closeSide: () => void
}

function PartnerInfoCard({ partner, closeSide }: TPartnerInfoCard) {
    const { t } = useTranslation('quoteList')
    const attributesToDisplay = partner?.attributes ? Object.values(partner.attributes).filter((attr: PartnerAttributeResource) => attr.identifier && [ 'availability', 'customers_count', 'employees_count' ].includes(attr.identifier)) : []

    const partnerIndustry = partner?.attributes && Object.values(partner.attributes).find((attr: PartnerAttributeResource) => attr.identifier === 'partner_industry')
    const services = partnerIndustry?.selected || []

    return (
        <>
            <Flex justifyContent="flex-end" mt={4} mx={5}>
                <Button variant="neutral" onClick={closeSide}>
                    <X size={18} />
                </Button>
            </Flex>
            {partner && <>
                <Flex justifyContent="space-between" py={4} px={6}>
                    <Flex flexDirection="column">
                        {partner?.logo && <Image background={partner.logo.endsWith('.jpg')} round={partner.logo.endsWith('.jpg')} src={partner.logo} variant="large"/>}
                        <Heading size="large" color="impact">{partner.companyName}</Heading>
                        {partner.title && <Text mt={2}>{partner.title.name}</Text>}
                    </Flex>
                </Flex>
                <Flex py={4} px={6} flexDirection="column">
                    <Heading size="small">{partner.companyInformation}</Heading>
                    {Boolean(attributesToDisplay.length) && <Grid gridTemplateColumns="1fr 1fr" mt={5}>
                        {attributesToDisplay.map((attr: PartnerAttributeResource) => Boolean(attr.selected?.length) && <Flex flexDirection="column" mb={4} key={attr.id}>
                            <Text size="small" mb={2}>{attr.label}</Text>
                            <Heading size="small">{attr.selected?.[0].title}</Heading>
                        </Flex>)}
                    </Grid>}
                    {partner.badges && <>
                        <Heading my={4} size="medium">{t('certifications')}</Heading>
                        <Flex flexWrap="wrap">
                            {partner.badges.map(badge => <Badge m={2} size="large" key={badge.id}>
                                <Text size="large">{badge.badgeToken}</Text>
                            </Badge>
                            )}
                        </Flex>
                    </>}
                    {Boolean(services.length) && <>
                        <Heading mt={5} mb={4} size="medium">{t('services')}</Heading>
                        <Flex flexWrap="wrap">
                            {services.map(service => <Badge m={2} size="large" key={service.id}>
                                <Text size="large">{service.title}</Text>
                            </Badge>)}
                        </Flex>
                    </>}
                    <Heading mt={5} mb={4} size="medium">{t('contact')}</Heading>
                    <Text>
                        <Link href={'https://' + partner.website}>{partner.website}</Link>
                    </Text>
                </Flex>
            </>}
        </>
    )
}

export default PartnerInfoCard
