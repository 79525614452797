import { useParams, useSearchParams, useNavigate, Navigate } from 'react-router-dom'
import Page, { Graphics } from 'components/src/layout/Page'
import Flex from 'components/src/layout/Flex'
import TopBar from 'components/src/TopBar'
import MultiStepForm from 'components/src/MultiStepForm'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { WithSidebar } from '../../Sidebar'
import Icon from 'components/src/icons/Icon'
import { ChevronLeft } from 'react-feather'
import { MenuItem } from 'components/src/TopBar/MenuItem'
import { Option } from 'components/src/MultiStepForm/SelectStepForm/Option'
import IndustryStep from './IndustryStep'
import SubindustryStep from './SubindustryStep'
import ProductStep from './ProductStep'
import LeadTypeStep from './LeadTypeStep'
import RevenueStep from './RevenueStep'
import ZipCodeStep from './ZipCodeStep'
import { partnersSearchParams } from '../../utils'
import { useTranslation } from 'react-i18next'

interface Step {
    id: number
    component: React.FC<SetupStepProps>
    nextStepId?: number
    queryParameterName: string
    header?: string
    subheader?: string
    redirectOnPreselected: boolean
}

interface QueryParameterRedirect {
    queryParameter: string
    stepId: number
}

const queryParameterRedirects = [
    {
        queryParameter: partnersSearchParams.childIndustry,
        stepId: 3,
    } as QueryParameterRedirect,
] as QueryParameterRedirect[]

const steps: Step[] = [
    {
        id: 1,
        component: IndustryStep,
        nextStepId: 2,
        queryParameterName: partnersSearchParams.industry,
        redirectOnPreselected: true,
    },
    {
        id: 2,
        component: SubindustryStep,
        nextStepId: 3,
        queryParameterName: partnersSearchParams.childIndustry,
        redirectOnPreselected: false,
    },
    {
        id: 3,
        component: LeadTypeStep,
        nextStepId: 6,
        queryParameterName: partnersSearchParams.leadType,
        redirectOnPreselected: false,
    },
    {
        id: 4,
        component: ProductStep,
        nextStepId: 5,
        queryParameterName: partnersSearchParams.product,
        redirectOnPreselected: false,
    },
    {
        id: 5,
        component: RevenueStep,
        queryParameterName: partnersSearchParams.revenue,
        redirectOnPreselected: false,
    },
    {
        id: 6,
        component: ZipCodeStep,
        queryParameterName: partnersSearchParams.zipCode,
        redirectOnPreselected: false,
    },
]

export interface SetupStepProps {
    step: Step
    geoCode?: string | undefined
}

export const getStepUrl = (stepId: number) => `/partners-search/setup/step/${stepId}`

/**
 * For given list of step options and its query parameter name check if its already set, return selected option
 */
export const getQueryPreselectedOption = (step: Step, options: Option[], queryParameters: URLSearchParams): Option | undefined =>
    queryParameters.get(step.queryParameterName) == null
        ? undefined
        : options.find((option: Option) => option.id.toString() === queryParameters.get(step.queryParameterName))

const FormWrapper = styled(Flex)`
`

interface PartnerSearchSetupRouteParams {
    stepId: string
}

function PartnerSearchSetup() {
    const { stepId: stepIdParam } = useParams<keyof PartnerSearchSetupRouteParams>() as PartnerSearchSetupRouteParams
    const stepId = parseInt(stepIdParam)
    const [ queryParameters ] = useSearchParams()
    const navigate = useNavigate()
    const { t } = useTranslation('common')

    /**
    * In case this step's query parameter is already set return url to next form step
    */
    const getRedirectUrl = (step: Step): string | null => {
        if (step.nextStepId == null) {
            return null
        }

        if (step.redirectOnPreselected === false) {
            return null
        }

        return queryParameters.get(step.queryParameterName) == null
            ? null
            : `${getStepUrl(step.nextStepId)}?${queryParameters.toString()}`
    }

    const getQueryParameterRedirectUrl = (step: Step): string | null => {
        const redirect = queryParameterRedirects
            .find((setting: QueryParameterRedirect) => (queryParameters.get(setting.queryParameter) != null) && (step.id < setting.stepId))

        if (redirect == null) {
            return null
        }

        return `${getStepUrl(redirect.stepId)}?${queryParameters.toString()}`
    }

    const handleRenderForms = (): React.ReactNode[] => {
        const geoCode = queryParameters.get(partnersSearchParams.geoCode) || undefined

        return steps.map((step: Step, index: number) => {
            const FormComponent = step.component

            return <FormComponent key={`step-${index}`} step={step} geoCode={geoCode} />
        })
    }

    const handleRedirectBackClick = useCallback((): void => {
        navigate(-1)
    }, [ navigate ])

    const step = steps.find((step: Step) => step.id === stepId)

    if (step == null) {
        return <Navigate to={`${getStepUrl(1)}?${queryParameters.toString()}`} />
    }

    const redirectUrl = getRedirectUrl(step)
    const queryParameterRedirectUrl = getQueryParameterRedirectUrl(step)

    if (redirectUrl != null) {
        return <Navigate to={redirectUrl} />
    }

    if (queryParameterRedirectUrl != null) {
        return <Navigate to={queryParameterRedirectUrl} />
    }

    return (
        <WithSidebar>
            <Page>
                <Graphics graphics="primary" />
                <TopBar>
                    <MenuItem
                        label={t('back')}
                        onClick={handleRedirectBackClick}
                        iconLeft={<Icon mr={2} variant="accent">
                            <ChevronLeft size={22}/>
                        </Icon>}
                    />
                </TopBar>
                <FormWrapper py={6} justifyContent="center" alignItems="center" mx="auto">
                    <MultiStepForm
                        currentStepNumber={step.id}
                    >
                        { handleRenderForms() }
                    </MultiStepForm>
                </FormWrapper>
            </Page>
        </WithSidebar>
    )
}

export default PartnerSearchSetup
