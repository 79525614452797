import styled from 'styled-components'
import { variant, space, SpaceProps, typography, TypographyProps, layout, LayoutProps } from 'styled-system'
import { ReactNode } from 'react'
import { TextSizeTypes, TextColorTypes } from '../index'

export interface HeadingProps extends SpaceProps, TypographyProps, LayoutProps {
    size?: TextSizeTypes
    color?: TextColorTypes
    children: ReactNode
}

const Heading = styled.span<HeadingProps>`
    margin: 0;
    display: inline-block;
    font-weight: bold;
    vertical-align: baseline;
    ${space};
    ${typography};
    ${layout};

    ${variant({
        prop: 'size',
        scale: 'headings.sizes',
    })}

    ${variant({
        prop: 'color',
        scale: 'text.colors',
    })}
`

Heading.defaultProps = {
    color: 'default',
    size: 'small',
}

export default Heading
