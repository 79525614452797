import { useAgerasApi } from 'ageras-api-client/src'
import { IndustryResource, ProductResult, ProductsApiFetchParamCreator } from 'ageras-api-client/src/api'
import SelectStepForm from 'components/src/MultiStepForm/SelectStepForm'
import { Option } from 'components/src/MultiStepForm/SelectStepForm/Option'
import { useCallback } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { getQueryPreselectedOption, getStepUrl, SetupStepProps } from '.'
import getBasicAuth from '../../utils/getBasicAuth'
import { useTranslation } from 'react-i18next'

function ProductStep(props: SetupStepProps) {
    const { step, geoCode } = props
    const [ queryParameters ] = useSearchParams()
    const navigate = useNavigate()
    const { t } = useTranslation('searchSetup')

    const { isLoading, data: products } = useAgerasApi<ProductResult>(
        ProductsApiFetchParamCreator().productsIndex(
            undefined,
            undefined,
            geoCode,
        ),
        { meta: { authorization: getBasicAuth() } }
    )

    const handleSubmit = useCallback((option: Option) => {
        queryParameters.set(step.queryParameterName, option.id.toString())

        navigate({
            pathname: getStepUrl(step.nextStepId || 1),
            search: queryParameters.toString(),
        })
    }, [ navigate, step.queryParameterName, queryParameters, step.nextStepId ])

    const options = products?.data == null
        ? []
        : products?.data.map((product: IndustryResource) => ({ id: product.id, name: product.name } as Option))

    const selectedOption = getQueryPreselectedOption(step, options, queryParameters)

    return (
        <SelectStepForm
            header={t('product_header')}
            subheader={t('product_subheader')}
            submit={handleSubmit}
            options={options}
            actionButtonText={t('select')}
            loading={isLoading}
            preselectedOption={selectedOption}
        />
    )
}

export default ProductStep
