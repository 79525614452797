import React from 'react'
import styled from 'styled-components'
import Text, { TextProps } from '../texts/Text'
import Flex from '../layout/Flex'
import { ReactNode } from 'react'

const MenuItemWrapper = styled(Flex)`
    cursor: pointer;
`

export interface MenuTextProps extends TextProps {
    to?: string
}

const MenuText = styled(Text)<MenuTextProps>`
`

export interface MenuItemProps {
    label: string
    to?: string
    as?: React.ComponentType<any>
    onClick?: () => any | boolean
    iconLeft?: ReactNode
    iconRight?: ReactNode
}

export const MenuItem = ({
    label,
    iconLeft,
    iconRight,
    to,
    as,
    ...props
}: MenuItemProps) => (
    <MenuItemWrapper p={20} alignItems="center" {...props}>
        {iconLeft}
        <MenuText to={to} as={as} size="large">{label}</MenuText>
        {iconRight}
    </MenuItemWrapper>
)
