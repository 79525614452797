import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect } from 'react'
import Page, { Graphics } from 'components/src/layout/Page'
import Flex from 'components/src/layout/Flex'
import Heading from 'components/src/texts/Heading'
import Button from 'components/src/Button'
import Text from 'components/src/texts/Text'
import { Input } from 'components/src/inputs/Input'
import { FormControl } from 'components/src/inputs/FormControl'
import { useNavigate } from 'react-router-dom'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useTranslation } from 'react-i18next'
import { useAgerasApi } from 'ageras-api-client/src'
import {
    FormQuestionAnswerResource,
    FormQuestionResource,
    FormResource,
    FormResponseAnswerResource,
    FormResponseResource,
    FormResult,
    FormsApiFetchParamCreator,
} from 'ageras-api-client/src/api'
import Checkbox from 'components/src/inputs/Checkbox'
import Select from 'components/src/inputs/Select'
import MultiSelect from 'components/src/inputs/MultiSelect'
import { getGuestUser } from '../../utils/guestUser'
import { WithSidebar } from '../../Sidebar'
import TopBar from 'components/src/TopBar'
import { MenuItem } from 'components/src/TopBar/MenuItem'
import Icon from 'components/src/icons/Icon'
import { ChevronLeft } from 'react-feather'
import useLocalStorage from '../../utils/useLocalStorage'
import getBasicAuth from '../../utils/getBasicAuth'

type OnAnswerChangeFunction = (event: SyntheticEvent<HTMLInputElement>) => void
type OnCheckboxChangeFunction = (event: ChangeEvent<HTMLInputElement>) => void
type OnSelectChangeFunction = (selected: FormQuestionAnswerResource) => void
type OnMultiSelectChangeFunction = (selected: FormQuestionAnswerResource[]) => void
type ProcessCheckboxChangeFunction = (questionId: string, isSelected: boolean) => void
type ProcessAnswerChangeFunction = (questionId: string, answer: string) => void
type ProcessSelectChangeFunction = (questionId: string, answerId: string) => void
type ProcessMultiSelectChangeFunction = (questionId: string, selected: string[]) => void

const MULTIANSWER_DELIMITER = '||'
const STORAGE_KEY = 'form.response'

interface FloatQuestionInterface {
    question: FormQuestionResource
    handleOnAnswerChange: OnAnswerChangeFunction
}

function FloatQuestion({ question, handleOnAnswerChange }: FloatQuestionInterface) {
    return <FormControl label={question.displayText} >
        <Input
            name={String(question.identifier)}
            step="0.01"
            type="number"
            placeholder={question.placeholder}
            onChange={handleOnAnswerChange}
        />
    </FormControl>
}

interface IntegerQuestionInterface {
    question: FormQuestionResource,
    handleOnAnswerChange: OnAnswerChangeFunction,
}

function IntegerQuestion({ question, handleOnAnswerChange }: IntegerQuestionInterface) {
    return <FormControl label={question.displayText} >
        <Input
            min={0}
            name={String(question.identifier)}
            type="number"
            placeholder={question.placeholder}
            onChange={handleOnAnswerChange}
        />
    </FormControl>
}

interface TextQuestionInterface {
    question: FormQuestionResource,
    handleOnAnswerChange: OnAnswerChangeFunction,
}

function TextQuestion({ question, handleOnAnswerChange }: TextQuestionInterface) {
    return <FormControl label={question.displayText} >
        <Input
            name={String(question.identifier)}
            type="text"
            placeholder={question.placeholder}
            onChange={handleOnAnswerChange}
        />
    </FormControl>
}

interface CheckboxQuestionInterface {
    question: FormQuestionResource,
    isSelected: boolean,
    handleOnCheckboxChange: OnCheckboxChangeFunction,
}

function CheckboxQuestion({ question, isSelected, handleOnCheckboxChange }: CheckboxQuestionInterface) {
    return <FormControl label={question.displayText} >
        <Checkbox
            value={isSelected}
            width="350px"
            onChange={handleOnCheckboxChange}
        />
    </FormControl>
}

interface SelectQuestionInterface {
    question: FormQuestionResource,
    answer: string | undefined,
    handleOnSelectChange: OnSelectChangeFunction,
}

function SelectQuestion({ question, answer, handleOnSelectChange }: SelectQuestionInterface) {
    return <FormControl label={question.displayText} >
        <Select
            items={question.possibleAnswers || []}
            selected={question.possibleAnswers?.find(option => option.id === answer)}
            placeholder={question.placeholder || ''}
            onChange={handleOnSelectChange}
            itemToLabel={useCallback((item: FormQuestionAnswerResource) => item.label ?? '', [])}
        />
    </FormControl>
}

interface MultiSelectQuestionInterface {
    question: FormQuestionResource,
    answer: string | undefined,
    handleOnMultiSelectChange: OnMultiSelectChangeFunction,
}

function MultiSelectQuestion({ question, answer, handleOnMultiSelectChange }: MultiSelectQuestionInterface) {
    const { t } = useTranslation('segmentationForm')
    return <FormControl label={question.displayText} >
        <MultiSelect
            items={question.possibleAnswers ?? []}
            selected={question.possibleAnswers?.filter(option => (answer?.split(MULTIANSWER_DELIMITER) || []).includes(option.id ?? '')) ?? []}
            placeholder={t('multiselect_placeholder') || ''}
            onChange={handleOnMultiSelectChange}
            itemToLabel={useCallback((item: FormQuestionAnswerResource) => item.label ?? '', [])}
        />
    </FormControl>
}

interface QuestionInterface {
    clientAnswers: Map<string, string>,
    parentsWithVisibleChildQuestions: Map<string, boolean>,
    question: FormQuestionResource,
    processOnAnswerChange: ProcessAnswerChangeFunction,
    processOnCheckboxChange: ProcessCheckboxChangeFunction,
    processOnSelectChange: ProcessSelectChangeFunction
    processOnMultiSelectChange: ProcessMultiSelectChangeFunction
}

function Question({
    clientAnswers,
    parentsWithVisibleChildQuestions,
    question,
    processOnAnswerChange,
    processOnCheckboxChange,
    processOnSelectChange,
    processOnMultiSelectChange,
}: QuestionInterface) {
    const answer = clientAnswers.get(String(question.id))

    let isSelected: boolean = false
    if (clientAnswers && clientAnswers.get) {
        isSelected = clientAnswers.get(String(question.id)) === 'true'
    }

    const handleOnCheckboxChange = useCallback(()  => {
        processOnCheckboxChange(String(question.id), !isSelected)
    }, [ processOnCheckboxChange, isSelected, question ])

    const handleOnAnswerChange = useCallback((event: SyntheticEvent<HTMLInputElement>)  => {
        const answerChanged: string = (event.target as HTMLInputElement).value
        processOnAnswerChange(String(question.id), answerChanged)
    }, [ processOnAnswerChange, question ])

    const handleOnSelectChange = useCallback((selected: FormQuestionAnswerResource) => {
        const answerId = selected.id ?? ''
        processOnSelectChange(String(question.id), answerId)
    }, [ processOnSelectChange, question ])

    const handleOnMultiSelectChange = useCallback((selected: FormQuestionAnswerResource[])  => {
        processOnMultiSelectChange(String(question.id), selected.map(s => s.id ?? ''))
    }, [ processOnMultiSelectChange, question ])

    if (question.parentQuestionId && !parentsWithVisibleChildQuestions.has(String(question.parentQuestionId))) {
        return null
    }

    switch (question.answerType) {
        case FormQuestionResource.AnswerTypeEnum.Float:
            return <FloatQuestion question={question} handleOnAnswerChange={handleOnAnswerChange} />
        case FormQuestionResource.AnswerTypeEnum.Integer:
            return <IntegerQuestion question={question} handleOnAnswerChange={handleOnAnswerChange} />
        case FormQuestionResource.AnswerTypeEnum.Text:
            return <TextQuestion question={question} handleOnAnswerChange={handleOnAnswerChange} />
        case FormQuestionResource.AnswerTypeEnum.Checkbox:
            return <CheckboxQuestion question={question} isSelected={isSelected} handleOnCheckboxChange={handleOnCheckboxChange} />
        case FormQuestionResource.AnswerTypeEnum.Select:
            return <SelectQuestion question={question} answer={answer} handleOnSelectChange={handleOnSelectChange} />
        case FormQuestionResource.AnswerTypeEnum.Multiselect:
            return <MultiSelectQuestion question={question} answer={answer} handleOnMultiSelectChange={handleOnMultiSelectChange} />
    }

    return null
}

function Form() {
    const guestUser = getGuestUser()
    const navigate = useNavigate()

    const childIndustryId = Number(guestUser.childIndustryId)
    const leadTypeId = Number(guestUser.leadTypeId)
    const geoCode = String(guestUser.geoCode)

    const { t } = useTranslation([ 'common', 'segmentationForm' ])
    const [ clientAnswers, setClientAnswers ] = React.useState<Map<string, string>>(new Map())
    const [ parentsWithVisibleChildQuestions, setParentsWithVisibleChildQuestions ] =
        React.useState<Map<string, boolean>>(new Map())
    const [ isFormFilled, setIsFormFilled ] = React.useState<boolean>(false)
    const [ ,setStoredFormResponse ] = useLocalStorage<FormResponseResource | null>(STORAGE_KEY, null)

    const { isLoading, data } = useAgerasApi<FormResult>(
        FormsApiFetchParamCreator().formsIndex(
            childIndustryId,
            leadTypeId,
            geoCode,
            undefined,
            undefined,
            undefined,
            undefined
        ),
        { meta: { authorization: getBasicAuth() } }
    )

    const response: FormResource | undefined = data?.data?.[0]
    const formId: string = String(response?.id)

    const onBackClick = useCallback(() => navigate(-1), [ navigate ])

    const validateForm = useCallback(() => {
        setIsFormFilled(true)
        response?.questions?.forEach((item: FormQuestionResource) => {
            if (item.isRequired && !clientAnswers.has(String(item.id))) {
                setIsFormFilled(false)
            }
        })
    }, [ clientAnswers, setIsFormFilled, response ])

    const onNextStepClick =  useCallback(() => {
        if (!clientAnswers) {
            return
        }

        const answersToStore: FormResponseAnswerResource[] = []
        clientAnswers.forEach((values, key) => {
            for (const value of values.split(MULTIANSWER_DELIMITER)) {
                const answer: FormResponseAnswerResource = {
                    questionId: Number(key),
                    answer: value,
                }
                answersToStore.push(answer)
            }
        })

        const formResponse: FormResponseResource = {
            id: formId,
            answers: answersToStore,
        }

        setStoredFormResponse(formResponse)
        navigate({
            pathname: '/signup',
        })
    }, [ navigate, clientAnswers, setStoredFormResponse, formId ])

    const processOnAnswerChange = useCallback((questionId: string, answer: string)  => {
        if (!answer) {
            clientAnswers.delete(questionId)
            setClientAnswers(clientAnswers)
            return
        }

        setClientAnswers(map => new Map(map?.set(questionId, answer)))
        validateForm()
    }, [ setClientAnswers, clientAnswers, validateForm ])

    const processOnCheckboxChange = useCallback((questionId: string, isSelected: boolean)  => {
        if (clientAnswers.has(questionId) && clientAnswers.get(questionId) === String(isSelected)) {
            return
        }

        setClientAnswers(map => new Map(map?.set(questionId, String(isSelected))))
        if (isSelected === true) {
            setParentsWithVisibleChildQuestions(map => new Map(map?.set(questionId, isSelected)))
        } else {
            parentsWithVisibleChildQuestions.delete(questionId)
            setParentsWithVisibleChildQuestions(parentsWithVisibleChildQuestions)
        }
        validateForm()
    }, [ setClientAnswers, clientAnswers, setParentsWithVisibleChildQuestions, parentsWithVisibleChildQuestions, validateForm ])

    const processOnSelectChange = useCallback((questionId: string, answerId: string)  => {
        setClientAnswers(map => new Map(map?.set(questionId, answerId)))
        validateForm()
    }, [ setClientAnswers, validateForm ])

    const processOnMultiSelectChange = useCallback((questionId: string, selected: string[])  => {
        setClientAnswers(map => new Map(map?.set(questionId, selected.join(MULTIANSWER_DELIMITER))))
        validateForm()
    }, [ setClientAnswers, validateForm ])

    const disabled = isLoading || !isFormFilled

    useEffect(() => {
        if (!isLoading && response?.questions && response?.questions?.length === 0) {
            const answersToStore: FormResponseAnswerResource[] = []
            const formResponse: FormResponseResource = {
                id: formId,
                answers: answersToStore,
            }

            setStoredFormResponse(formResponse)
            navigate({
                pathname: '/signup',
            })
        }
    }, [ response, isLoading, formId, navigate, setStoredFormResponse ])

    return (
        <WithSidebar>
            <Page>
                <Graphics graphics="primary" />
                <TopBar>
                    <MenuItem
                        label="Back"
                        onClick={onBackClick}
                        iconLeft={<Icon mr={2} variant="accent">
                            <ChevronLeft size={22}/>
                        </Icon>}
                    />
                </TopBar>
                <Flex my={6} px={3} maxWidth={600} mx="auto" flexWrap="wrap" flexDirection="column">
                    <Heading maxWidth="500px" mb={3} textAlign="left" size="medium" color="impact">
                        {t('segmentationForm:form_header')}
                    </Heading>
                    <Text maxWidth="500px"  mb={5} textAlign="left" size="medium">
                        {t('segmentationForm:form_subheader')}
                    </Text>
                    {isLoading && <Heading size="medium" textAlign="center">
                        <Text>{t('common:loading')}</Text>
                    </Heading>}
                    {!isLoading && response?.questions?.map((item: FormQuestionResource) =>
                        <Question
                            key={item.id}
                            clientAnswers={clientAnswers}
                            parentsWithVisibleChildQuestions={parentsWithVisibleChildQuestions}
                            question={item}
                            processOnAnswerChange={processOnAnswerChange}
                            processOnCheckboxChange={processOnCheckboxChange}
                            processOnSelectChange={processOnSelectChange}
                            processOnMultiSelectChange={processOnMultiSelectChange}
                        />
                    )}
                    <Flex mt={5} flexDirection="row">
                        <Button
                            border="2px solid"
                            mr={1}
                            grow
                            variant="ternary"
                            onClick={onBackClick}
                        >
                            {t('common:back')}
                        </Button>
                        <Button
                            border="2px solid"
                            ml={1}
                            grow
                            variant="secondary"
                            onClick={onNextStepClick}
                            disabled={disabled}
                        >
                            {t('common:next_step')}
                        </Button>
                    </Flex>
                </Flex>
            </Page>
        </WithSidebar>
    )
}

export default Form
