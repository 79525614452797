import { GeoCodes } from './index'

const LANGUAGE_CODE = 'language_code'

export function setLanguageCode(value: GeoCodes) {
    localStorage.setItem(LANGUAGE_CODE, value)
}

export function getLanguageCode(): GeoCodes {
    return (localStorage.getItem(LANGUAGE_CODE) || 'en') as GeoCodes
}

export function removeLanguageCode() {
    localStorage.removeItem(LANGUAGE_CODE)
}
