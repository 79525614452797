import Flex from 'components/src/layout/Flex'
import Text from 'components/src/texts/Text'
import Button from 'components/src/Button'
import Card from 'components/src/Card'
import styled from 'styled-components'
import Rating from 'components/src/Rating'
import Badge from 'components/src/Badge'
import { PartnerBadgeResource, PartnerBusinessUnitPartnerResource, PartnerBusinessUnitResource } from 'ageras-api-client/src/api'
import { useCallback } from 'react'
import Icon from 'components/src/icons/Icon'
import { Check, Plus, RefreshCw, X } from 'react-feather'
import { useTranslation } from 'react-i18next'

interface PartnerSearchCardContentProps {
    isSwapping: boolean
    isSelected: boolean
}

const PartnerSearchCardContent = styled(Card.Content)<PartnerSearchCardContentProps>`
    opacity: ${({ isSwapping, isSelected }) => isSwapping && isSelected ? '.6' : '1' };
`

const CardButton = styled(Button)`
    border-radius: 0 12px 0 12px;
    top: 0;
    right: 0;
    position: absolute;
    padding: 22px 28px;
`

const OfferSelectedLabel = styled(CardButton)`
    pointer-events: none;
`

const PartnerImageContainer = styled(Flex)`
    width: auto;
`

const Image = styled.img`
    max-height: 220px;
    border-radius: 12px;
    max-width: 220px;
`

const PartnerInfoContainer = styled(Flex)`
    max-width: 60%;
    width: fill-available;
`

const PartnerBadgesContainer = styled(Flex)`
    flex-wrap: wrap;
    gap: 6px;
`

const PartnerDescriptionContainer = styled(Flex)`
    max-height: 100px;
    overflow-y: scroll;
    overflow-x: hidden;
`

interface PartnerSearchCardWrapperProps {
    selected: boolean
    isSwappingFrom: boolean
}

const PartnerSearchCardWrapper = styled(Card)<PartnerSearchCardWrapperProps>`
    border: ${({ theme: { colors }, selected, isSwappingFrom }) => selected
        ? `solid 2px ${colors.electricGreen}`
        : isSwappingFrom
            ? `solid 2px ${colors.baseBlue}`
            : 'none' };
    position: relative;
`

interface BasePartnerSearchCardProps {
    isSwapping: boolean
    isSelected: boolean
    unit: PartnerBusinessUnitResource
    children: React.ReactNode
    showPartnerDetails: (unit: PartnerBusinessUnitResource) => void
    showPartnerRating: (unit: PartnerBusinessUnitResource) => void
}

function BasePartnerSearchCard(props: BasePartnerSearchCardProps) {
    const { t } = useTranslation('searchResultList')
    const { isSwapping, isSelected, unit, children: buttonElement, showPartnerDetails, showPartnerRating } = props

    const partner = unit.partner

    const buildAddressLine = (cityName: string | undefined, address: string | undefined): string | null => {
        let result = null

        if (cityName) {
            result = cityName
        }

        if (address) {
            result = `${result}, ${address}`
        }

        return result
    }

    const handleShowPartnerDetails = useCallback(() => {
        showPartnerDetails(unit)
    }, [ unit, showPartnerDetails ])

    const address = buildAddressLine(unit.geo?.cityName, unit.geo?.address)

    const handleRatingClick = useCallback(() => {
        showPartnerRating(unit)
    }, [ unit, showPartnerRating ])

    if (partner == null) {
        return <Text>Loading</Text>
    }

    return (
        <PartnerSearchCardWrapper
            mb={2}
            selected={isSelected && !isSwapping}
            isSwappingFrom={isSelected && isSwapping}
        >
            <PartnerSearchCardContent isSwapping={isSwapping} isSelected={isSelected}>
                <Flex justifyContent="space-between" alignItems="stretch" width="fill-available" p={4}>
                    {partner.logo && <PartnerImageContainer justifyContent="flex-start" alignItems="center" mr={4}>
                        <Image src={partner.logo} />
                    </PartnerImageContainer>}
                    <PartnerInfoContainer flexDirection="column" mr={4}>
                        <Text size="large" fontWeight="600" color="impact" mb={1}>
                            {partner.companyName}
                        </Text>
                        <Text size="small" mb={2}>
                            { address }
                        </Text>
                        <Flex alignItems="center">
                            {partner.rating?.stars !== 0 && <Flex mr={2} alignItems="center" onClick={handleRatingClick} style={{ cursor: 'pointer' }}>
                                <Rating ratingValue={partner.rating?.stars as number} size="extraSmall" />
                            </Flex>}
                            <Text size="small" mr={2}>
                                {partner.rating?.score}
                                {'/5 '}
                                {t('stars')}
                            </Text>
                            <Text size="small" underline>
                                {partner.rating?.count}
                                {' '}
                                {t('result_reviews')}
                            </Text>
                        </Flex>
                        {Boolean(partner.badges?.length) && <PartnerBadgesContainer  mt={3}>
                            {partner.badges?.map((badge: PartnerBadgeResource) =>
                                <Badge
                                    key={`perk_${badge.id}`}
                                >
                                    {badge.badgeToken}
                                </Badge>
                            )}
                        </PartnerBadgesContainer>}
                        {partner.companyInformation && <PartnerDescriptionContainer mt={3}>
                            <Text size="small">
                                { partner.companyInformation }
                            </Text>
                        </PartnerDescriptionContainer>}
                    </PartnerInfoContainer>
                    <Flex flexDirection="column" justifyContent="flex-end">
                        <Button variant="secondary" onClick={handleShowPartnerDetails}>
                            {t('search_card.view_profile')}
                        </Button>
                    </Flex>
                </Flex>
            </PartnerSearchCardContent>
            { buttonElement }
        </PartnerSearchCardWrapper>
    )
}

interface PartnerSearchCardProps {
    unit: PartnerBusinessUnitResource
    caseId: string
    isSelected: boolean
    isSwapping: boolean
    selectionsFull?: boolean
    select: (unitId: number) => void
    swap: (swapFromUnitId: number) => void
    cancelSwap: () => void
    showPartnerDetails: (partner: PartnerBusinessUnitPartnerResource) => void
    showPartnerRating: (partner: PartnerBusinessUnitPartnerResource) => void
}

function PartnerSearchCard(props: PartnerSearchCardProps) {
    const { t } = useTranslation('searchResultList')
    const { unit, isSelected, isSwapping, select, swap, cancelSwap, selectionsFull, showPartnerDetails, showPartnerRating } = props

    const handleSwapClick = useCallback(() => {
        if (unit.id) {
            swap(unit.id)
        }
    }, [ swap, unit.id ])

    const handleSelectClick = useCallback(() => {
        if (unit.id) {
            select(unit.id)
        }
    }, [ select, unit.id ])

    if (isSelected) {
        return (
            <BasePartnerSearchCard
                isSwapping={isSwapping}
                isSelected={true}
                unit={unit}
                showPartnerDetails={showPartnerDetails}
                showPartnerRating={showPartnerRating}
            >
                {!isSwapping && <OfferSelectedLabel variant="primary" size="small">
                    <Icon mr={2}>
                        <Check size={18} />
                    </Icon>
                    {t('search_card.offer_selected')}
                </OfferSelectedLabel>}
                {isSwapping && <CardButton variant="secondary" size="small" onClick={handleSwapClick}>
                    <Icon mr={2}>
                        <Check size={18} />
                    </Icon>
                    {t('search_card.swap_out_offer')}
                </CardButton>}
            </BasePartnerSearchCard>
        )
    }

    return (
        <BasePartnerSearchCard
            isSwapping={isSwapping}
            isSelected={false}
            unit={unit}
            showPartnerDetails={showPartnerDetails}
            showPartnerRating={showPartnerRating}
        >
            {!isSwapping && <CardButton variant="ternary" size="small" onClick={handleSelectClick}>
                { selectionsFull === true && <>
                    <Icon mr={2}>
                        <RefreshCw size={18} />
                    </Icon>
                    {t('search_card.swap_for_offer')}
                </> }
                { !selectionsFull && <>
                    <Icon mr={2}>
                        <Plus size={18} />
                    </Icon>
                    {t('search_card.select_offer')}
                </> }
            </CardButton>}
            {isSwapping && <CardButton variant="ternary" size="small" onClick={cancelSwap}>
                {t('search_card.cancel_swap')}
                <Icon ml={2}>
                    <X size={18} />
                </Icon>
            </CardButton>}
        </BasePartnerSearchCard>
    )
}

export default PartnerSearchCard
