import React from 'react'

export interface AmountProps {
    number: number
    currency?: string
    maximumFractionDigits?: number
}

const Amount = ({ number, currency, maximumFractionDigits }: AmountProps) => {
    const locale = navigator.language
    const props: Intl.NumberFormatOptions = {}

    if (currency) {
        props.style = 'currency'
        props.currency = currency
    }

    if (maximumFractionDigits !== undefined) {
        props.maximumFractionDigits = maximumFractionDigits
    }

    const formatted = new Intl.NumberFormat(locale, props)
        .format(number)
    return <>{formatted}</>
}

export default Amount
