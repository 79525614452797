import Flex from 'components/src/layout/Flex'
import Text from 'components/src/texts/Text'
import Amount from 'components/src/Amount'
import Card from 'components/src/Card'
import React from 'react'
import { LeadQuoteResource } from 'ageras-api-client/src/api'
import { getTotalQuoteValue } from '../../../../utils'
import { useTranslation } from 'react-i18next'

interface QuoteInfoProps {
    quote: LeadQuoteResource | undefined
    industry: string | undefined
    showPricesWithVat: boolean | undefined
}

function QuoteInfo({ quote, industry, showPricesWithVat }: QuoteInfoProps) {
    const { t } = useTranslation([ 'common', 'caseList' ])

    if (!quote) {
        return null
    }
    switch (industry) {
        case 'loans':
            const loan = quote?.loans?.[0]
            return <>
                <Flex justifyContent="space-between" mb={2}>
                    <Text size="small">Loan amount</Text>
                    <Text size="small" color="impact" fontWeight="bold">
                        {loan?.loanAmountExclVat?.amount &&
                        <Amount
                            number={loan.loanAmountExclVat.amount}
                            currency={loan.loanAmountExclVat.currency}
                        />
                        }
                    </Text>
                </Flex>
                <Flex justifyContent="space-between" mb={2}>
                    <Text size="small">{t('common:duration')}</Text>
                    <Text size="small" color="impact" fontWeight="bold">
                        {loan?.durationMonths}
                        {' '}
                        {t('common:months')}
                    </Text>
                </Flex>
                <Flex justifyContent="space-between">
                    <Text size="small">{t('caseList:lead_quotes_info.apr_from')}</Text>
                    <Text size="small" color="impact" fontWeight="bold">
                        {(Number(loan?.aprInterestRate) * 100).toFixed(1)}
                        {t('common:percent_per_year')}
                    </Text>
                </Flex>
            </>
        default:
            const totalPrice = getTotalQuoteValue(quote)

            return <>
                {quote.items?.map(item => <Flex key={item.id} mb={3} justifyContent="space-between">
                    <Flex flexBasis={0} flexGrow={2}>
                        <Text>{item.title}</Text>
                    </Flex>
                    <Flex flexBasis={0} flexGrow={1} justifyContent="flex-end">
                        <Text>{item.type}</Text>
                    </Flex>
                    <Flex flexBasis={0} flexGrow={1} justifyContent="flex-end">
                        <Text color="impact" fontWeight="bold">
                            {item.amountExclVat?.amount &&
                            <Amount
                                number={item.amountExclVat.amount}
                                currency={item.amountExclVat.currency}
                            />
                            }
                        </Text>
                    </Flex>
                </Flex>)}
                <Card.Line />
                <Flex my={3} justifyContent="space-between">
                    <Flex flexBasis={0} flexGrow={2}>
                        <Text>Total excl. VAT</Text>
                    </Flex>
                    <Flex flexBasis={0} flexGrow={1} justifyContent="flex-end">
                        <Text color="impact" fontWeight="bold">
                            <Amount
                                number={totalPrice.totalAmountExclVat.amount}
                                currency={totalPrice.totalAmountExclVat.currency}
                            />
                        </Text>
                    </Flex>
                </Flex>
                {showPricesWithVat && <>
                    <Flex mb={3} justifyContent="space-between">
                        <Flex flexBasis={0} flexGrow={2}>
                            <Text>Total VAT</Text>
                        </Flex>
                        <Flex flexBasis={0} flexGrow={1} justifyContent="flex-end">
                            <Text color="impact" fontWeight="bold">
                                <Amount
                                    number={totalPrice.totalAmountVat.amount}
                                    currency={totalPrice.totalAmountVat.currency}
                                />
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex mb={3} justifyContent="space-between">
                        <Flex flexBasis={0} flexGrow={2}>
                            <Text>Total incl. VAT</Text>
                        </Flex>
                        <Flex flexBasis={0} flexGrow={1} justifyContent="flex-end">
                            <Text color="impact" fontWeight="bold">
                                <Amount
                                    number={totalPrice.totalAmountInclVat.amount}
                                    currency={totalPrice.totalAmountInclVat.currency}
                                />
                            </Text>
                        </Flex>
                    </Flex>
                </>}
            </>
    }
}

export default QuoteInfo
