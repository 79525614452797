import { lighten } from 'polished'

export const colors = {
    baseBlue: '#0A7AE0',
    baseBlueLight: 'rgba(10, 122, 224, 0.1)',
    black: '#000000',
    lightBlue: '#16BEFF',
    darkBlue: '#042D52',
    electricGreen: '#29D380',
    lightElectricGreen: lighten(.25, '#29D380'),
    limeGreen: '#00B67A',
    lightGreen: '#5EC87A',
    greyCyan: '#E7FAF4',
    skyBlue: '#EFF9FF',
    skyBlueDarker: '#E9F6FF',
    white: '#FFFFFF',
    lightGrey: '#F1F5F7',
    darkGrey: '#637D94',
    brightRed: '#EE3028',
    lightPink: '#FDEAEA',
    brightYellow: '#FBDB3F',
    dawnYellow: '#FFF8E6',
    offsetBlue: '#3C95E6',
}

export const fontSizes = [ 10, 12, 14, 16, 18, 20, 24, 28 ]

export const lineHeights = [ 1, 1.25, 1.29, 1.33, 1.43, 1.66, 2 ]

export const boxShadows = {
    base: '0px -4px 20px rgba(10, 122, 224, 0.1)',
    light: '0px 18px 20px rgba(0, 0, 0, 0.03)',
    active: '0px 4px 20px rgba(88, 168, 247, 0.3)',
    dark: '0px 5px 40px rgba(0, 0, 0, 0.2)',
}

export const breakpoints = [ '640px', '960px', '1200px' ]
