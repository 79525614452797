import React from 'react'
import styled from 'styled-components'
import Flex from '../layout/Flex'

const TopBarWrapper = styled(Flex)`
    height: 60px;
    min-height: 60px;
    position: fixed;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.topBar.colors.borderColor};
    background-color: ${({ theme }) => theme.topBar.colors.background};
    z-index: 9 !important;
`

interface TopBarProps {
    children?: React.ReactNode
}

function TopBar(props: TopBarProps) {
    return (
        <TopBarWrapper justifyContent="center">
            <Flex maxWidth={1300} minWidth="100%" alignItems="center" justifyContent="space-between">
                {props.children}
            </Flex>
        </TopBarWrapper>
    )
}

export default TopBar
