import React from 'react'
import ReactMarkdown, { Options } from 'react-markdown'
import gfm from 'remark-gfm'

interface TMarkdown extends Options {
    children: string
}

const Markdown = (props: TMarkdown) => {
    return (
        <ReactMarkdown plugins={[ gfm ]} children={props.children} />
    )
}

export default Markdown
