import React from 'react'
import styled from 'styled-components'
import Button from '../../Button'
import Flex from '../../layout/Flex'
import Heading from '../../texts/Heading'
import Text from '../../texts/Text'
import { LayoutProps } from 'styled-system'

export const HeaderText = styled(Heading)`
    max-width: 500px;
`

export const SubheaderText = styled(Text)`
    max-width: 500px;
`

export const SubmitButton = styled(Button)`
    min-width: 190px;
`

export interface StepFormWrapperProps {
    header: string
    subheader?: string
    loading?: boolean
}

export interface StepFormProps extends LayoutProps {
    header: string
    subheader?: string
    children: React.ReactNode
}

function StepForm(props: StepFormProps) {
    const { header, subheader, children } = props

    return (
        <Flex flexDirection="column" height="100%" width="100%" {...props}>
            <HeaderText mb={3} textAlign="left" size="medium" color="impact">
                { header }
            </HeaderText>
            {subheader && <SubheaderText mb={5} textAlign="left" size="medium">
                { subheader}
            </SubheaderText>}
            { children }
        </Flex>
    )
}

export default StepForm
