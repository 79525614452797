import React, { MouseEvent, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { Star as StarIcon } from 'react-feather'
import { LayoutProps, SpaceProps, variant as styledVariant } from 'styled-system'

type RatingStarStates = 'empty' | 'half' | 'full'
type RatingStarSizes = 'small' | 'medium' | 'large' | 'extraSmall'
type RatingStarVariants = 'base'

const getStars = (
    ratingValue: number,
    totalNumberOfStars: number,
    size: RatingStarSizes,
    variant: RatingStarVariants,
    handleOnMouseMove: (event: MouseEvent<HTMLInputElement>) => void
) => {
    const stars: JSX.Element[] = []

    const fullStars = Math.floor(ratingValue)
    const halfStar = ratingValue === 0
        ? false
        : ratingValue % Math.floor(ratingValue) !== 0

    for (let i = 1; i <= totalNumberOfStars; i++) {
        const isFullStar = i <= fullStars
        const isHalfStar = i === fullStars + 1
            ? halfStar
            : false

        const state = isFullStar
            ? 'full'
            : isHalfStar ? 'half' : 'empty'

        stars.push(
            <Star
                key={`star-${i}`}
                state={state}
                size={size}
                variant={variant}
                id={i}
                handleOnMouseMove={handleOnMouseMove}
            />
        )
    }

    return stars
}

interface StarWrapperProps {
    size: RatingStarSizes
}

const StarWrapper = styled.div<StarWrapperProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 5px;
    ${styledVariant({
        prop: 'size',
        scale: 'ratings.starWrappers.sizes',
    })}
    &:last-of-type {
        margin-right: 0;
    }
`

interface StarBackgroundProps {
    size: RatingStarSizes
    state: RatingStarStates
    variant: RatingStarVariants
}

const StarBackground = styled.div<StarBackgroundProps>`
    position: absolute;
    left: 0;
    height: 100%;
    z-index: 0;
    pointer-events: none;
    ${styledVariant({
        prop: 'size',
        scale: 'ratings.starBackgrounds.sizes',
    })}
    ${styledVariant({
        prop: 'state',
        scale: 'ratings.starBackgrounds.states',
    })}
    ${styledVariant({
        scale: 'ratings.starBackgrounds.colors',
    })}
`

interface StarIconWrapperProps {
    size: RatingStarSizes
    variant: RatingStarVariants
}

const StarIconWrapper = styled(StarIcon)<StarIconWrapperProps>`
    z-index: 1;
    pointer-events: none;
    ${styledVariant({
        prop: 'size',
        scale: 'ratings.stars.sizes',
    })}
    ${styledVariant({
        scale: 'ratings.stars.colors',
    })}
`

export interface StarProps extends SpaceProps, LayoutProps {
    id?: number
    state: RatingStarStates
    size: RatingStarSizes
    variant: RatingStarVariants
    handleOnMouseMove?: (event: MouseEvent<HTMLInputElement>) => void
}

export const Star = ({
    id,
    state,
    size,
    variant,
    handleOnMouseMove,
}: StarProps) => {
    return (
        <StarWrapper data-id={id} size={size} onMouseMove={handleOnMouseMove} >
            <StarIconWrapper size={size} variant={variant} />
            <StarBackground size={size} state={state} variant={variant} />
        </StarWrapper>
    )
}

Star.defaultProps = {
    variant: 'base',
}

interface RatingWrapperProps {
    width: string
    disabled?: boolean
}

const RatingWrapper = styled.div<RatingWrapperProps>`
    width: ${({ width }) => width};
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    ${({ disabled }) => disabled && css`
        cursor: inherit;
    `}
`

export interface RatingProps {
    ratingValue: number
    totalNumberOfStars?: number
    width?: string
    size?: RatingStarSizes
    variant?: RatingStarVariants
    onChange?: (rating: number) => void
    disabled?: boolean
}

const Rating = (
    {
        ratingValue,
        totalNumberOfStars = 5,
        width = 'fit-content',
        size = 'medium',
        variant = 'base',
        onChange,
        disabled = true,
    }: RatingProps) => {
    const [ rating, setRating ] = React.useState<number>(ratingValue)
    const [ selected, setSelected ] = React.useState<boolean>(false)

    const handleOnMouseEnter = useCallback(() => {
        if (disabled) {
            return
        }

        setSelected(false)
    }, [ disabled, setSelected ])

    const handleOnMouseLeave = useCallback(() => {
        if (disabled) {
            return
        }

        setSelected(true)
    }, [ disabled, setSelected ])

    const handleOnMouseMove = useCallback((event: MouseEvent<HTMLInputElement>) => {
        if (disabled || selected) {
            return
        }

        const target = event.target as HTMLInputElement
        const rect = target.getBoundingClientRect()
        const cursorPositionX = event.clientX - rect.left
        let newRating: number = parseInt((event.target as HTMLInputElement).dataset.id as string, 10)
        if (cursorPositionX < (rect.width / 2)) {
            newRating = newRating - 0.5
        }
        setRating(newRating)
    }, [ setRating, disabled, selected ])

    const handleOnClick = useCallback(() => {
        if (disabled) {
            return
        } else if (onChange) {
            onChange(rating)
            setSelected(true)
        }
    }, [ onChange, rating, setSelected, disabled ])

    return(
        <RatingWrapper
            width={width}
            onClick={handleOnClick}
            disabled={disabled}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
        >
            { getStars(rating, totalNumberOfStars, size, variant, handleOnMouseMove) }
        </RatingWrapper>
    )
}

export default Rating
