import { FormControl } from 'components/src/inputs/FormControl'
import { Input } from 'components/src/inputs/Input'
import React, { useCallback } from 'react'
import * as yup from 'yup'
import { SchemaOf } from 'yup'
import Flex from 'components/src/layout/Flex'
import { useFormik } from 'formik'
import Button from 'components/src/Button'
import { useAgerasApiMutation } from 'ageras-api-client/src'
import { AuthApiFetchParamCreator, AuthResource } from 'ageras-api-client/src/api'
import Text from 'components/src/texts/Text'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface FormValues {
    email: string,
    password: string,
}

const validationSchema: SchemaOf<FormValues> = yup.object().shape({
    email: yup.string().email('validation:invalid_email').required('validation:required'),
    password: yup.string()
        .min(8, 'validation:password_min_length')
        .max(50, 'validation:password_max_length')
        .required('validation:required'),
})

const initialValues = {
    email: '',
    password: '',
}

function LoginForm() {
    const { t } = useTranslation([ 'common', 'logIn', 'validation' ])

    const navigate = useNavigate()
    const mutation = useAgerasApiMutation<AuthResource>(
        (actionData: AuthResource) => AuthApiFetchParamCreator().authCreate(actionData),
    )

    const onSubmit = useCallback(async (values: FormValues) => {
        const authData: AuthResource = {
            email: values.email,
            password: values.password,
            userType: 'client',
        }
        await mutation.mutateAsync(authData)
        navigate('/phone-verification')
    }, [ mutation, navigate ])

    const formik = useFormik<FormValues>({
        initialValues,
        onSubmit,
        validateOnChange: false,
        validationSchema,
    })

    return (
        <Flex flexWrap="wrap" flexDirection="column">
            {mutation.isLoading && <Text>{t('common:loading')}</Text>}
            {mutation.isError && <Text color="danger">{t('logIn:authentication_failed')}</Text>}
            <form onSubmit={formik.handleSubmit}>
                <FormControl
                    error={formik.errors.email}
                    label={t('logIn:email')}
                >
                    <Input
                        name="email"
                        onChange={formik.handleChange}
                        placeholder={t('logIn:enter_email')}
                        value={formik.values.email}
                    />
                </FormControl>
                <FormControl
                    error={formik.errors.password}
                    label={t('logIn:password')}
                >
                    <Input
                        name="password"
                        placeholder={t('logIn:enter_password')}
                        type="password"
                    />
                </FormControl>
                <Button variant="secondary" grow type="submit" mt={2}>{t('logIn:log_in')}</Button>
            </form>
        </Flex>
    )
}

export default LoginForm
