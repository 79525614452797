import { useAgerasApi } from 'ageras-api-client/src'
import { LeadQuoteResource, PartnerResource, PartnersApiFetchParamCreator } from 'ageras-api-client/src/api'
import Card from 'components/src/Card'
import Flex from 'components/src/layout/Flex'
import Image from 'components/src/Image'
import Heading from 'components/src/texts/Heading'
import Text from 'components/src/texts/Text'
import Badge from 'components/src/Badge'
import Amount from 'components/src/Amount'
import Icon from 'components/src/icons/Icon'
import { Check, User } from 'react-feather'
import Button from 'components/src/Button'
import Circle from 'components/src/icons/Circle'
import { Link as RouterLink } from 'react-router-dom'
import React, { Dispatch, SetStateAction } from 'react'
import { getTotalQuoteValue } from '../../utils'
import { useTranslation } from 'react-i18next'

function showPartnerDetail(
    partner: PartnerResource,
    displayedPartner: OfferCardInterface['displayedPartner'],
    setPartner: OfferCardInterface['setPartner'],
    isOpen: OfferCardInterface['isOpen'],
    setIsOpen: OfferCardInterface['setIsOpen']
) {
    return async () => {
        if (!isOpen) {
            setIsOpen(true)
            await new Promise(resolve => setTimeout(resolve, 300))
            setPartner(partner)
        } else {
            if (displayedPartner && displayedPartner.id === partner.id) {
                setIsOpen(false)
                setPartner(null)
            } else {
                setPartner(partner)
            }
        }
    }
}

interface QuoteDataProps {
    quote: LeadQuoteResource
    industry: string | undefined
    showPricesWithVat: boolean | undefined
}

function TotalQuoteValue({ quote, industry, showPricesWithVat }: QuoteDataProps) {
    const { t } = useTranslation([ 'common', 'quoteList' ])

    switch (industry) {
        case 'loans':
            const loan = quote.loans?.[0]
            return (
                <>
                    <Text textAlign="right">{t('quoteList:total_loan_amount')}</Text>
                    <Heading textAlign="right" color="impact" size="large">
                        {loan?.totalLoanAmountExclVat?.amount && (
                            <Amount
                                number={loan.totalLoanAmountExclVat.amount}
                                currency={loan.totalLoanAmountExclVat.currency}
                            />
                        )}
                    </Heading>
                </>
            )
        default:
            const totalPrice = getTotalQuoteValue(quote)
            const append = showPricesWithVat ? t('common:append_include_vat') : t('common:append_exclude_vat')
            return (
                <>
                    <Text textAlign="right">
                        {t('quoteList:total_offer_price')}
                        {' '}
                        {append}
                    </Text>
                    <Heading textAlign="right" color="impact" size="large">
                        {showPricesWithVat &&
                        <Amount
                            number={totalPrice.totalAmountInclVat?.amount}
                            currency={totalPrice.totalAmountInclVat?.currency}
                        />
                        }
                        {!showPricesWithVat &&
                        <Amount
                            number={totalPrice.totalAmountExclVat?.amount}
                            currency={totalPrice.totalAmountExclVat?.currency}
                        />
                        }
                    </Heading>
                </>
            )
    }
}

function QuoteItems({ quote, industry }: QuoteDataProps) {
    const { t } = useTranslation([ 'common', 'quoteList' ])

    switch (industry) {
        case 'loans':
            const loan = quote.loans?.[0]
            return (
                <>
                    <Flex justifyContent="space-between" mb={2}>
                        <Text size="small">{t('quoteList:total_offer_price')}</Text>
                        <Text size="small" color="impact" fontWeight="bold">
                            {loan?.loanAmountExclVat?.amount &&
                            <Amount
                                number={loan.loanAmountExclVat.amount}
                                currency={loan.loanAmountExclVat.currency}
                            />
                            }
                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between" mb={2}>
                        <Text size="small">{t('common:duration')}</Text>
                        <Text size="small" color="impact" fontWeight="bold">
                            {loan?.durationMonths}
                            {' '}
                            {t('common:months')}
                        </Text>
                    </Flex>
                    <Flex justifyContent="space-between" mb={2}>
                        <Text size="small">{t('quoteList:apr_from')}</Text>
                        <Text size="small" color="impact" fontWeight="bold">
                            {(Number(loan?.aprInterestRate) * 100).toFixed(1)}
                            {t('common:percent_per_year')}
                        </Text>
                    </Flex>
                </>
            )
        default:
            return (
                <>
                    {quote.items?.map(item => <Flex key={item.id} mb={3} justifyContent="space-between">
                        <Flex flexBasis={0} flexGrow={2}>
                            <Text>{item.title}</Text>
                        </Flex>
                        <Flex flexBasis={0} flexGrow={1} justifyContent="flex-end">
                            <Text>{item.type}</Text>
                        </Flex>
                        <Flex flexBasis={0} flexGrow={1} justifyContent="flex-end">
                            <Text color="impact" fontWeight="bold">
                                {item.amountExclVat?.amount &&
                                <Amount
                                    number={item.amountExclVat.amount}
                                    currency={item.amountExclVat.currency}
                                />
                                }
                            </Text>
                        </Flex>
                    </Flex>)}
                </>
            )
    }
}

interface OfferCardInterface {
    industry: string | undefined
    showPricesWithVat: boolean | undefined
    quote: LeadQuoteResource
    caseId: number | string
    setIsOpen: Dispatch<SetStateAction<boolean>>
    isOpen: boolean
    setPartner: Dispatch<SetStateAction<PartnerResource | null>>
    displayedPartner: PartnerResource | null
}

function OfferCard({
    industry,
    showPricesWithVat,
    quote,
    caseId,
    setIsOpen,
    isOpen,
    setPartner,
    displayedPartner,
}: OfferCardInterface) {
    const { data: partner } = useAgerasApi<PartnerResource>(
        PartnersApiFetchParamCreator().partnersGet(String(quote.partner?.id ?? 0))
    )

    const { t } = useTranslation('quoteList')

    return (
        <Card
            shadow="loan"
            mb={4}
            onClick={partner && showPartnerDetail(partner, displayedPartner, setPartner, isOpen, setIsOpen)}
        >
            <Card.Content p={3}>
                <Flex alignItems="center">
                    <Flex mr={3}>
                        {partner?.logo && <Image background={partner.logo.endsWith('.jpg')} round={partner.logo.endsWith('.jpg')} src={partner.logo} variant="large"/>}
                        {!partner?.logo &&
                        <Circle size="large" variant="primary">
                            <Icon as={User} size={24} />
                        </Circle>
                        }
                    </Flex>
                    <Flex mr={4} flexDirection="column">
                        <Heading>{quote.partner?.companyName}</Heading>
                        <Text>{quote.partner?.city}</Text>
                    </Flex>
                    <Flex>
                        <Badge variant="success">{t('preferred_partner')}</Badge>
                    </Flex>
                    <Flex flexDirection="column" ml="auto" mr="0">
                        <TotalQuoteValue
                            quote={quote}
                            industry={industry}
                            showPricesWithVat={showPricesWithVat}
                        />
                    </Flex>
                </Flex>
            </Card.Content>
            <Card.Line />
            <Card.Content>
                <Flex>
                    <Flex flexDirection="column" p={4} flexBasis={0} flexGrow={1}>
                        {quote.description && <Text mb={4}>{quote.description.substring(0, 255)}</Text>}
                        <Flex flexWrap="wrap">
                            {partner?.badges?.map(badge => <Badge mb={3} mr={3} key={badge.id}>
                                <Circle mr={2}>
                                    <Icon as={Check} size={12} />
                                </Circle>
                                <Text>{badge.badgeToken}</Text>
                            </Badge>)}
                        </Flex>
                    </Flex>
                    <Card.VerticalDivider />
                    <Flex p={4} flexDirection="column" justifyContent="space-between" flexGrow={1} flexBasis={0}>
                        <QuoteItems
                            quote={quote}
                            industry={industry}
                            showPricesWithVat={showPricesWithVat}
                        />
                        <Flex justifyContent="end" mt={3}>
                            <RouterLink to={`/cases/${caseId}/quotes/${quote.id}`}>
                                <Button variant="primary">{t('select_offer')}</Button>
                            </RouterLink>
                        </Flex>
                    </Flex>
                </Flex>
            </Card.Content>
        </Card>
    )
}

export default OfferCard
