import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { variant } from 'styled-system'
import Flex from '../layout/Flex'

const EMPTY_NAME_INITIALS = '??'

type LogoSizeTypes = 'small' | 'medium' | 'large'

interface WrapperProps {
    square?: boolean
    backgroundColor?: string
    textColor?: string
    size: LogoSizeTypes
}

const Wrapper = styled(Flex)<WrapperProps>`
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme: { logos }, square }) => square ? logos.squareBorderRadius : '50%'};
    background-color: ${({ theme: { logos }, backgroundColor }) => backgroundColor ? backgroundColor : logos.defaultBackgroundColor};
    color: ${({ theme: { logos }, textColor }) => textColor ? textColor : logos.defaultTextColor};
    overflow: hidden;

    ${variant({
        prop: 'size',
        scale: 'logos.sizes',
    })}
`

interface ImageProps {
    isHorizontal: boolean
}

const Image = styled.img<ImageProps>`
    ${({ isHorizontal }) => isHorizontal ? `
        height: 100%;
    ` : `
        width: 100%;
    `}
`

export interface LogoProps {
    imageUrl?: string
    name?: string
    forceShowInitials?: boolean
    initials?: string
    square?: boolean
    backgroundColor?: string
    textColor?: string
    size: LogoSizeTypes
}

const Logo = (props: LogoProps) => {
    const { imageUrl, name, forceShowInitials, initials, square, backgroundColor, textColor, size } = props
    const [ isImageHorizontal, setIsImageHorizontal ] = useState<boolean>(false)

    const handleImageOnLoad = useCallback(({ target }) => {
        const width = target.naturalWidth || 0
        const height = target.naturalHeight || 0

        setIsImageHorizontal(width > height)
    }, [])

    const shouldDisplayImage = !forceShowInitials && imageUrl

    const getInitials = () => {
        if (initials != null && initials !== '') {
            return initials
        }

        if (name == null || name === '') {
            return EMPTY_NAME_INITIALS
        }

        const parts = name.trim().split(' ')

        if (parts.length === 1) {
            return parts[0].substring(0, 2)
        }

        return `${parts[0].charAt(0)}${parts[1].charAt(0)}`
    }

    return (
        <Wrapper square={square} backgroundColor={backgroundColor} textColor={textColor} size={size}>
            {shouldDisplayImage && <Image isHorizontal={isImageHorizontal} src={imageUrl} alt="Logo" onLoad={handleImageOnLoad} />}
            {!shouldDisplayImage && getInitials()}
        </Wrapper>
    )
}

export default Logo
