import React from 'react'
import {
    TermsResource,
} from 'ageras-api-client/src/api'
import Markdown from '../../Markdown'
import Modal from 'components/src/modals/Modal'
import { ModalProps } from 'components/src/modals'
import Button from 'components/src/Button'
import Card from 'components/src/Card'
import Heading from 'components/src/texts/Heading'
import Text from 'components/src/texts/Text'
import Flex from 'components/src/layout/Flex'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'

function TermsModal(props: ModalProps & {
    body: TermsResource['body']
}) {
    const { t } = useTranslation('quoteDetail')

    return (
        <Modal {...props} size="medium">
            <Card.Content p={4}>
                <Flex justifyContent="space-between" alignItems="center">
                    <Heading size="large" color="accent">{t('terms_and_conditions')}</Heading>
                    <Button variant="neutral" onClick={props.onHide}>
                        <X size={18} />
                    </Button>
                </Flex>
            </Card.Content>
            <Card.Line variant="primary" />
            <Card.Content px={5} py={3} scroll>
                <Text>
                    {props.body &&
                    <Markdown>
                        {props.body}
                    </Markdown>
                    }
                </Text>
            </Card.Content>
            <Card.Content py={3} />
        </Modal>
    )
}

export default TermsModal
