import React from 'react'
import {
    IndustriesApiFetchParamCreator,
    IndustryResult,
} from 'ageras-api-client/src/api'
import { useAgerasApi } from 'ageras-api-client/src'
import InputGroup from 'components/src/inputs/Input'
import Button from 'components/src/Button'
import { Field, Form, Formik, FormikProps } from 'formik'
import * as yup from 'yup'
import Page, { Graphics } from 'components/src/layout/Page'
import Flex from 'components/src/layout/Flex'
import Heading from 'components/src/texts/Heading'
import Text from 'components/src/texts/Text'
import { SchemaOf } from 'yup'
import getBasicAuth from '../utils/getBasicAuth'
import { WithSidebar } from '../Sidebar'

function onSubmit() {
    alert('sup')
}

interface TestFormValues {
    email: string
}

function TestForm(props: FormikProps<TestFormValues>) {
    return (
        <Form>
            <Field name="email" label="Email" as={InputGroup} error={props?.errors?.email}/>
            <Button variant="primary" type="submit">Hello</Button>
        </Form>
    )
}

const formValidationSchema: SchemaOf<TestFormValues> = yup.object().shape({
    email: yup.string().email('Invalid email').required('Required'),
})

function Home() {
    const { isLoading, error } = useAgerasApi<IndustryResult>(
        IndustriesApiFetchParamCreator().industriesIndex(),
        { meta: { authorization: getBasicAuth() } }
    )

    if (isLoading) {
        return <Page variant="base">
            <Graphics graphics="primary" />
            <Flex p={72} flexDirection="column">
                <Text>Loading..</Text>
            </Flex>
        </Page>
    }

    if (error) {
        return <Page variant="base">
            <Graphics graphics="primary" />
            <Flex p={72} flexDirection="column">
                <Text>{`An error has occurred: ${error.message}`}</Text>
            </Flex>
        </Page>
    }

    return (
        <WithSidebar>
            <Page variant="base">
                <Graphics graphics="primary" />
                <Flex p={72} flexDirection="column">
                    <Heading size="large">Welcome</Heading>
                </Flex>
                <Flex px={72} flexDirection="column">
                    <Formik
                        initialValues={{ email: 'nist@ageras' }}
                        onSubmit={onSubmit}
                        validationSchema={formValidationSchema}
                        render={TestForm}
                    />
                </Flex>
            </Page>
        </WithSidebar>
    )
}

export default Home
