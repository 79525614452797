import React, { ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { MapPin, Search } from 'react-feather'
import styled from 'styled-components'
import Flex from '../layout/Flex'
import Button from '../Button'
import { useComponentVisible } from '../inputs/Select'

const InputWrapper = styled(Flex).attrs({ as: styled.input`` })`
    background-color: #F7FAFC;
    border: 0;
    border-right: 1px solid rgba(10, 122, 224, 0.1);
    box-shadow: 1px 0px 2px rgba(216, 218, 220, 0.4);
`

type InputProps = any

function Input(props: InputProps) {
    const [ optionsOpen, setOptionsOpen ] = useState<boolean>(false)
    const ref = useComponentVisible<HTMLDivElement>(setOptionsOpen)
    const {
        options,
        onPickOption: onAutocompleteListOptionPick,
    } = props
    const onFocus = useCallback(() => {
        setOptionsOpen(true)
    }, [])
    const onBlur = useCallback(() => {
        if (Object.keys(options).length < 2) {
            setOptionsOpen(false)
            onAutocompleteListOptionPick(options[0])
        }
    }, [ onAutocompleteListOptionPick, options ])

    const onPickOption = useCallback((option) => () => {
        setOptionsOpen(false)
        onAutocompleteListOptionPick(option)
    }, [ onAutocompleteListOptionPick ])

    return (
        <Flex
            position="relative"
            flexDirection="column"
            height="100%"
            ref={ref}
        >
            {props.icon}
            <InputWrapper
                {...props}
                onFocus={onFocus}
                onBlur={onBlur}
                autocomplete={false}
            />
            <Flex
                flexDirection="column"
                position="absolute"
                width="100%"
                top="60px"
                bg="#F7FAFC"
                pl={20}
                pr={20}
                pb="10px"
                maxHeight={200}
                overflow="auto"
                zIndex={10}
                display={optionsOpen && options?.length ? 'initial' : 'none'}
            >
                {Object.entries(options ?? {}).map(([ id, option ], index) => (
                    <Flex
                        mt="10px"
                        key={index}
                        onClick={onPickOption(option)}
                    >
                        {props.optionToLabel(option)}
                    </Flex>
                ))}
            </Flex>
        </Flex>
    )
}

interface SearchCriteria {
    childIndustry?: { id?: number, name?: string }
    location?: { id?: number, zipCode?: string, cityName?: string, point?: { lat?: string, lon?: string } }
}

const queryOptionRenderer = (option: SearchCriteria['childIndustry']) => option?.name
const zipCodeLabelRenderer = (option: SearchCriteria['location']) => `${option?.zipCode} ${option?.cityName}`
const zipCodeValueRenderer = (option: SearchCriteria['location']) => option?.zipCode

interface SearchBarProps {
    onSearch: (criteria: SearchCriteria) => void
    useQueryChange: <T>(query: string, primaryIndustryId?: string, geoCode?: string) => T | undefined
    useZipCodeChange: <T>(zipCode: string, geoCode?: string) => T | undefined
    query?: string
    zipCode?: string
    geoCode?: string
    primaryIndustryId?: string
    children?: ReactNode
}

export function SearchBar(props: SearchBarProps) {
    const [ query, setQuery ] = useState<string>('')
    const [ childIndustry, setChildIndustry ] = useState<SearchCriteria['childIndustry']>()
    const [ zipCode, setZipCode ] = useState<string>(props.zipCode ?? '')
    const [ location, setLocation ] = useState<SearchCriteria['location']>()

    useEffect(() => {
        if (props.query !== undefined) {
            setQuery(props.query)
        }
    }, [ props.query ])

    const onQueryChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
        setQuery(e.currentTarget.value)
    }, [])

    const onZipCodeChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
        setZipCode(e.currentTarget.value)
    }, [])

    const onSearch = props.onSearch
    const onSearchClick = useCallback(() => {
        onSearch({
            childIndustry,
            location,
        })
    }, [ onSearch, childIndustry, location ])
    const onPickQueryOption = useCallback((option: SearchCriteria['childIndustry']) => {
        setChildIndustry(option)
        setQuery(String(option?.name))
    }, [])
    const onPickZipCodeOption = useCallback((option: SearchCriteria['location']) => {
        setLocation(option)
        setZipCode(String(option?.zipCode))
    }, [])

    const industryData = props.useQueryChange<{ data: Array<{ name?: string }> }>(query, props.primaryIndustryId, props.geoCode)
    const locationData = props.useZipCodeChange<{ data: Array<SearchCriteria['location']> }>(zipCode, props.geoCode)

    return (
        <Flex
            height="60px"
            border="1px solid #EFF9FF"
            boxShadow="1px 0px 2px rgba(216, 218, 220, 0.4)"
            boxSizing="border-box"
            bg="white"
            width="100%"
            style={{ backgroundColor: 'white' }}
        >
            <Flex maxWidth="1140px" width="100%" height="100%" alignItems="center" justifyContent="flex-start">
                <Input
                    name="query"
                    value={query}
                    onChange={onQueryChange}
                    options={industryData?.data}
                    optionToLabel={queryOptionRenderer}
                    optionToValue={queryOptionRenderer}
                    onPickOption={onPickQueryOption}
                    height="100%"
                    placeholder="Search Directory"
                    pl={74}
                    width={336}
                    fontSize={14}
                    icon={
                        <Flex position="absolute" left={20} top={0} height="100%" alignItems="center">
                            <Search size={24} opacity={0.4}/>
                        </Flex>
                    }
                />
                <Input
                    name="zipCode"
                    height="100%"
                    width={214}
                    pl={62}
                    borderLeft={0}
                    placeholder="Zip Code"
                    fontSize={14}
                    value={zipCode}
                    onChange={onZipCodeChange}
                    options={locationData?.data}
                    optionToLabel={zipCodeLabelRenderer}
                    optionToValue={zipCodeValueRenderer}
                    onPickOption={onPickZipCodeOption}
                    icon={
                        <Flex position="absolute" left={11} top={0} height="100%" alignItems="center">
                            <MapPin size={24} opacity={0.4}/>
                        </Flex>
                    }
                />
                <Button variant="ternary" ml={3} onClick={onSearchClick}>Search</Button>
                {props.children}
            </Flex>
        </Flex>
    )
}
