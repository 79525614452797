import React, {
    useState,
    useCallback, SyntheticEvent,
} from 'react'
import {
    useComponentVisible,
    Dropdown,
    DropdownBody,
    Option,
    SelectProps,
} from '../Select'
import { CircleFlag } from 'react-circle-flags'
import { Input } from '../Input'
import { formatIncompletePhoneNumber, getCountryCallingCode, CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js'
import styled from 'styled-components'
import Flex from '../../layout/Flex'

export interface PhoneNumberProps<Value> extends Omit<SelectProps<Value>, 'selected' | 'onChange'> {
    selected: Value
    onCountryChange?: (selected: Value | undefined) => void
    onChange?: (phone: string) => void
    itemToCode: (item: Value) => string
    value: string
}

const StyledIcon = styled.span`
    & > img {
        width: auto;
    }
    margin-right: 5px;
`

export const getDefaultSelectedCountry = (geoCode:string, countries:Array<{
    code: string
    label: string
}>) => {
    const defaultCountry = countries.find((country) => country.code.toLowerCase() == geoCode)
    if (defaultCountry) {
        return defaultCountry
    }

    return countries[0]
}

export const getSelectedCountryByPhoneNumber = (phoneNumber:string, geoCode:string, countries:Array<{
    code: string
    label: string
}>) => {
    if (phoneNumber.length < 4 || !phoneNumber.startsWith('+')) {
        return getDefaultSelectedCountry(geoCode, countries)
    }

    const defaultFormattedValue = formatIncompletePhoneNumber(phoneNumber)
    const defaultParsedValue = parsePhoneNumberFromString(defaultFormattedValue)
    if (defaultParsedValue?.country === undefined) {
        return getDefaultSelectedCountry(geoCode, countries)
    }

    const defaultCountry = countries.find((country) => country.code == defaultParsedValue?.country)
    if (defaultCountry) {
        return defaultCountry
    }

    return getDefaultSelectedCountry(geoCode, countries)
}

export type ComponentProps<Value> = PhoneNumberProps<Value>

const PhoneNumber = <Value, >({
    itemToLabel,
    itemToCode,
    items,
    selected,
    value,
    disabled,
    onChange,
    onCountryChange,
}: ComponentProps<Value>) => {
    const [ isOpen, setOpen ] = useState<boolean>(false)
    const [ countrySearchPhrase, setCountrySearchPhrase ] = useState<string>('')
    const ref = useComponentVisible<HTMLDivElement>(setOpen)
    const [ filteredItems, setFilteredItems ] = useState<Value[]>(items)
    const [ countryCallingCode, setCountryCallingCode ] = useState<string>(
        getCountryCallingCode(itemToCode?.(selected) as CountryCode)
    )
    const normalizedUserInput = value.replace('+' + countryCallingCode,'').trim()

    const toggleDropdown = useCallback((e: SyntheticEvent) => {
        !disabled && setOpen(!isOpen)
        e.stopPropagation()
        e.preventDefault()
    }, [ disabled, isOpen ])

    const onOptionClick = useCallback((clickValue: Value) => () => {
        setOpen(false)
        if (Object.is(selected, clickValue)) {
            return
        }
        if (onChange) {
            onChange(
                formatIncompletePhoneNumber(
                    '+' + getCountryCallingCode(itemToCode?.(clickValue) as CountryCode) + normalizedUserInput
                )
            )
        }
        setCountryCallingCode(getCountryCallingCode(itemToCode?.(clickValue) as CountryCode))
        if (onCountryChange) {
            onCountryChange(clickValue)
        }
    }, [
        setOpen,
        selected,
        onChange,
        countryCallingCode,
        setCountryCallingCode,
        onCountryChange,
        normalizedUserInput
    ])

    const onInputChange = useCallback(event => {
        setOpen(false)
        if (onChange) {
            onChange(formatIncompletePhoneNumber('+' + countryCallingCode + event.currentTarget.value))
        }
    }, [
        setOpen,
        countryCallingCode,
        onChange,
    ])

    const onCountrySearchCallback = useCallback(event => {
        const userInput = event.currentTarget.value
        setCountrySearchPhrase(userInput)
        setFilteredItems(items.filter(item => itemToLabel?.(item).toLowerCase().includes(userInput.toLowerCase())))
    }, [ setCountrySearchPhrase, items, itemToLabel ])

    return (
        <Flex position="relative">
            <Dropdown ref={ref} disabled={disabled} open={isOpen}>
                <Input
                    icon={
                        <>
                            <Flex
                                as={CircleFlag}
                                countryCode={itemToCode?.(selected).toLowerCase()}
                                width={22}
                                height={22}
                                onClick={toggleDropdown}
                            />
                            <Flex ml="1">
                                +
                                {countryCallingCode}
                            </Flex>
                        </>}
                    value={normalizedUserInput}
                    onChange={onInputChange}
                />
                <DropdownBody open={isOpen}>
                    <Input
                        value={countrySearchPhrase}
                        onChange={onCountrySearchCallback}
                    />
                    {filteredItems.map(item => (
                        <Option
                            key={itemToCode?.(item) ?? String(item)}
                            onClick={onOptionClick(item)}
                            selected={itemToCode?.(item) === itemToCode?.(selected)}
                        >
                            <>
                                <StyledIcon>
                                    <CircleFlag
                                        countryCode={itemToCode?.(item).toLowerCase() ?? String(item)}
                                        width={14}
                                        height={14}
                                    />
                                </StyledIcon>
                                {itemToLabel?.(item) ?? item}
                            </>
                        </Option>
                    ))}
                </DropdownBody>
            </Dropdown>
        </Flex>
    )
}

export default PhoneNumber
