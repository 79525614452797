import i18n from 'i18next'
import countries, { GetNameOptions, LocalizedCountryNames } from 'i18n-iso-countries'
import { languages } from './utils'
import { getLanguageCode } from './utils/languageCode'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

const FILES_LIST = [
    'common',
    'validation',
    'caseList',
    'logIn',
    'quoteList',
    'quoteDetail',
    'segmentationForm',
    'leadInformationForm',
    'searchResultList',
    'searchSetup',
    'signUp',
    'phoneVerification',
    'menu'
]

const getResources = () => {
    type languageKey = keyof typeof languages;
    const resources: Record<string, Record<string, LocalizedCountryNames<GetNameOptions>>> = {}
    for (let geoCode of Object.keys(languages)) {
        const files: Record<string, LocalizedCountryNames<GetNameOptions>> = {}
        for (const name of FILES_LIST) {
            files[name] = require('./locales/' + languages[geoCode as languageKey] + '/' + name + '.json')
        }
        files.countries = countries.getNames(geoCode, { select: 'alias' })
        resources[geoCode] = files
    }

    return resources
}

i18n
    .init({
        lng: getLanguageCode(),
        resources: getResources(),
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
