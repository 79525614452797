import React, { useCallback } from 'react'
import {
    ConversationResource,
    ConversationResult,
    ConversationsApiFetchParamCreator,
    LeadResource, MessageResource,
    PartnerResource
} from 'ageras-api-client/src/api'
import Button from 'components/src/Button'
import Text from 'components/src/texts/Text'
import Heading from 'components/src/texts/Heading'
import Flex from 'components/src/layout/Flex'
import Image from 'components/src/Image'
import { X } from 'react-feather'
import { useAgerasApi, useAgerasApiMutation } from 'ageras-api-client/src'
import { useAuth } from 'ageras-api-client/src/auth'
import Messenger from '../../components/Messenger'
import Card from 'components/src/Card'

interface TPartnerMessagesCard {
    lead: LeadResource
    partner: PartnerResource | null
    closeSide: () => void
}

function handleClose(closeSide: TPartnerMessagesCard['closeSide']) {
    return () => closeSide()
}

function PartnerMessagesCard({ lead, partner, closeSide }: TPartnerMessagesCard) {
    const auth = useAuth()
    const { data, refetch, isLoading } = useAgerasApi<ConversationResult>(
        ConversationsApiFetchParamCreator().conversationsIndex(
            undefined,
            undefined,
            String(partner?.id),
            undefined,
            String(auth.client?.id),
            undefined,
            String(lead.id),
            'latest_activity'
        )
    )

    const mutation = useAgerasApiMutation<MessageResource>(
        (newConversation: ConversationResource) => ConversationsApiFetchParamCreator().conversationsCreate(newConversation),
        {
            onSuccess: async () => {
                await refetch()
            },
        }
    )

    const handleConversationCreate: () => void = useCallback(() => {
        const participants = [
            { id: partner?.id, type: 'partner' },
            { id: lead.id, type: 'lead' },
        ]
        const newConversation: ConversationResource = {
            subject: 'CP',
            participants,
        }
        mutation.mutate(newConversation)
    }, [ lead, partner, mutation ])

    const conversation = data?.data?.[0]

    return (
        <Flex flexDirection="column" height="100%">
            <Flex justifyContent="flex-end" mt={4} mx={5}>
                <Button variant="neutral" onClick={handleClose(closeSide)}>
                    <X size={18} />
                </Button>
            </Flex>
            {partner && <>
                <Flex justifyContent="space-between" py={4} px={6}>
                    <Flex flexDirection="column">
                        {partner?.logo && <Image background={partner.logo.endsWith('.jpg')} round={partner.logo.endsWith('.jpg')} src={partner.logo} variant="large"/>}
                        <Heading size="large" color="impact">{partner.companyName}</Heading>
                        {partner.title && <Text mt={2}>{partner.title.name}</Text>}
                    </Flex>
                </Flex>
                <Card.Line />
                {!isLoading && conversation && <Messenger conversation={conversation}/>}
                {!isLoading && !conversation && <Flex justifyContent="center" alignItems="center" flexGrow={1}>
                    <Button variant="secondary" onClick={handleConversationCreate}>Start conversation</Button>
                </Flex>}
            </>}
        </Flex>
    )
}

export default PartnerMessagesCard
