import React, { SyntheticEvent, useCallback, useState } from 'react'
import Heading from 'components/src/texts/Heading'
import Flex from 'components/src/layout/Flex'
import Grid from 'components/src/layout/Grid'
import Page, { Graphics } from 'components/src/layout/Page'
import Button from 'components/src/Button'
import Loader from 'components/src/Loader'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { isValidNumber } from 'libphonenumber-js'
import { SchemaOf } from 'yup'
import Select from 'components/src/inputs/Select'
import { range } from '../../utils'
import { useAgerasApi, useAgerasApiMutation } from 'ageras-api-client/src'
import {
    LeadResource,
    // LocationsApiFetchParamCreator,
    LeadsApiFetchParamCreator,
    SectorResult,
    SectorsApiFetchParamCreator,
    // LocationResource,
    // LocationResult,
    LeadTypeResult,
    LeadTypeResource,
    LeadAttributeResource,
    SectorResource,
    // LeadGeoResource,
    DatafieldsApiFetchParamCreator,
    LeadIndustriesResource,
    DataFieldResult,
} from 'ageras-api-client/src/api'
import getBasicAuth from '../../utils/getBasicAuth'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from 'ageras-api-client/src/auth'
import { useTranslation } from 'react-i18next'
import { WithSidebar } from '../../Sidebar'
import { MenuItem } from 'components/src/TopBar/MenuItem'
import Icon from 'components/src/icons/Icon'
import { ChevronLeft } from 'react-feather'
import TopBar from 'components/src/TopBar'
import { FormControl } from 'components/src/inputs/FormControl'
import { Input } from 'components/src/inputs/Input'
import PhoneNumber, { getSelectedCountryByPhoneNumber } from 'components/src/inputs/PhoneNumber'
import { getCountryNames } from '../../utils'
import { Spinner } from 'components/src/Spinner'

interface InformationFormProps {
    lead: LeadResource
}

const IDENTIFIER_NUMBER_OF_EMPLOYEES = 'number_of_employees'
const IDENTIFIER_ESTABLISHING_YEAR = 'establishing_year'

interface InformationFormValues {
    companyName: string
    vatNumber: string
    companyType?: LeadTypeResource
    numberOfEmployees?: number
    yearEstablished?: number
    sector?: SectorResource
    // zipCode?: LocationResource
    // subRegionName: string
    phoneNumber: string
    address: string
}

// function useLocations(zipCode: string, geoCode?: string) {
//     const { data } = useAgerasApi<LocationResult>(
//         LocationsApiFetchParamCreator().locationsIndex(
//             undefined,
//             geoCode,
//             undefined,
//             undefined,
//             undefined,
//             undefined,
//             undefined,
//             undefined,
//             undefined,
//             zipCode,
//             50,
//             undefined
//         ),
//         getBasicAuth({
//             enabled: zipCode.length ? zipCode.length > 3 : false,
//         })
//     )
//     return data?.data ?? []
// }

function useSectors(geoCode: string) {
    const { data } =  useAgerasApi<SectorResult>(
        SectorsApiFetchParamCreator().sectorsIndex(
            geoCode,
            undefined,
            1000,
            undefined,
            undefined,
            undefined
        ), { meta: { authorization: getBasicAuth() } }
    )
    return data?.data ?? []
}

function useLeadsTypes(geoCode: string) {
    const { data } = useAgerasApi<LeadTypeResult>(
        LeadsApiFetchParamCreator().leadsTypesIndex(
            undefined,
            undefined,
            geoCode
        ),
        { meta: { authorization: getBasicAuth() } }
    )
    return data?.data ?? []
}

function useDataFields(industries: LeadIndustriesResource) {
    const { data } = useAgerasApi<DataFieldResult>(
        DatafieldsApiFetchParamCreator().datafieldsIndex(
            [ String(industries.primary?.id), String(industries.secondary?.id) ].join(','),
            undefined,
            'lead',
            true,
            undefined,
            50,
        )
    )
    return data?.data ?? []
}

function InformationForm({ lead }: InformationFormProps) {
    const { t } = useTranslation([ 'leadInformationForm', 'common', 'validation', 'countries' ])
    const { t: countries } = useTranslation('countries')
    const countryNames = getCountryNames(countries)
    // const [ searchZipCode, setSearchZipCode ] = useState<string>(lead.geo?.zipCode as string)
    const [ searchYearValue, setSearchYearValue ] = useState<string>('')
    const leadUpdateMutation = useAgerasApiMutation<LeadResource>((resource) => {
        return LeadsApiFetchParamCreator().leadsUpdate(String(lead.id), resource)
    })
    const navigate = useNavigate()
    // const locationOptions = useLocations(searchZipCode, lead.geo?.code)
    const leadsType = useLeadsTypes(lead.geo?.code as string)
    const sectorOptions = useSectors(lead.geo?.code as string)
    const dataFields = useDataFields(lead.industries as LeadIndustriesResource)

    const years = range(searchYearValue.length === 4 && Number(searchYearValue) < 1960 ? Number(searchYearValue) : 1960, new Date().getFullYear(), 1, true)
        .filter(year => searchYearValue ? year.toString().includes(String(searchYearValue)) : year)

    const handleSubmit = useCallback(async (values: InformationFormValues, { resetForm }) => {
        // const geo: LeadGeoResource = {
        //     ...lead.geo,
        //     address: values.address,
        //     zipCode: values.zipCode?.zipCode,
        //     point: values.zipCode?.point,
        //     cityName: values.zipCode?.cityName,
        // }

        const leadData: LeadResource = {
            ...lead,
            customerInfo: {
                ...lead.customerInfo,
                companyName: values.companyName,
                vatNumber: values.vatNumber,
            },
            primarySector: {
                id: values.sector?.id
            },
            // geo,
            customVariables: [
                { key: 'utm_source', value: 'amino' },
            ],
        }

        leadData.attributes = Object.values(leadData.attributes || {}) || []

        if (values.numberOfEmployees) {
            const field = dataFields.find(item => item.identifier === IDENTIFIER_NUMBER_OF_EMPLOYEES)
            if (field) {
                const dataField: LeadAttributeResource = {
                    id: field.id,
                    identifier: field.identifier,
                    selected: [
                        {
                            title: String(values.numberOfEmployees)
                        }
                    ]
                }
                leadData.attributes?.push(dataField)
            }
        }

        if (values.yearEstablished) {
            const field = dataFields.find(item => item.identifier === IDENTIFIER_ESTABLISHING_YEAR)
            if (field) {
                const dataField: LeadAttributeResource = {
                    id: field.id,
                    identifier: field.identifier,
                    selected: [
                        {
                            title: String(values.yearEstablished)
                        }
                    ]
                }
                leadData.attributes?.push(dataField)
            }
        }

        await leadUpdateMutation.mutateAsync(leadData)
        navigate('/cases')
    }, [ lead, leadUpdateMutation, navigate, dataFields ])

    const validationSchema: SchemaOf<InformationFormValues> = yup.object().shape({
        companyName : yup.string().required(t('validation:required')),
        vatNumber: yup.string().required(t('validation:required')),
        companyType: yup.object().required(t('validation:required')),
        numberOfEmployees: yup.number().integer().required(t('validation:required')).test(
            'Is positive ?',
            t('validation:number_must_be_positive'),
            (value) => value ? value > 0 : false
        ),
        yearEstablished: yup.number().required(t('validation:required')),
        sector: yup.object().required(t('validation:required')),
        // zipCode: yup.object().required(t('validation:required')),
        // subRegionName: yup.string().required(t('validation:required')),
        address: yup.string().required(t('validation:required')),
        phoneNumber: yup.string().test(
            'validPhoneNumber',
            t('validation:not_valid_phone_number'),
            async value => {
                return isValidNumber(value ? value : '')
            }
        ).required(t('validation:required')),
    })

    const formik = useFormik({
        initialValues: {
            companyName: lead.customerInfo?.companyName || '',
            vatNumber: lead.customerInfo?.vatNumber || '',
            companyType: leadsType.find(leadType => leadType.id === lead.customerInfo?.customerType?.id) || undefined,
            numberOfEmployees: lead.attributes ? Object.values(lead.attributes).find(dataField => dataField.identifier === IDENTIFIER_NUMBER_OF_EMPLOYEES)?.selected?.[0].title as unknown as number : undefined,
            yearEstablished: lead.attributes ? Object.values(lead.attributes).find(dataField => dataField.identifier === IDENTIFIER_ESTABLISHING_YEAR)?.selected?.[0].title as unknown as number : undefined,
            sector: sectorOptions.find(sector => sector.id === lead.primarySector?.id) || undefined,
            // zipCode: locationOptions.find(location => location.zipCode === lead.geo?.zipCode && location.cityName === lead.geo?.cityName) || undefined,
            // subRegionName: locationOptions.find(location => location.zipCode === lead.geo?.zipCode && location.cityName === lead.geo?.cityName)?.subRegionName || '',
            phoneNumber: lead.client?.phone || '',
            address: lead.geo?.address || '',
        },
        validationSchema,
        onSubmit: handleSubmit,
        validateOnChange: true,
        validateOnBlur: false,
    })

    const handleSearchYear = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
        setSearchYearValue(event.currentTarget.value)
    }, [])

    // const handleSearchZipCode = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    //     setSearchZipCode(event.currentTarget.value)
    // }, [])
    //
    // const populateZipCodeSelect = useCallback(() => {
    //     const find: LocationResource|undefined =  locationOptions.find(value => value.zipCode === lead.geo?.zipCode && value.cityName === lead.geo?.cityName)
    //     if (find) {
    //         formik.setFieldValue('zipCode', find)
    //         formik.setFieldValue('subRegionName', find.subRegionName)
    //     }
    //     return find
    // }, [ locationOptions, formik, lead ])
    //
    // const handleZipCodeSelect = useCallback((value: LocationResource) => {
    //     formik.setFieldValue('zipCode', value)
    //     formik.setFieldValue('subRegionName', value.subRegionName)
    // }, [ formik ])

    const handleYearEstablishedSelect = useCallback((value: number) => {
        formik.setFieldValue('yearEstablished', value)
    }, [ formik ])

    const populateSectorSelect = useCallback(() => {
        const find: SectorResource|undefined =  sectorOptions.find(value => value.id === Number(lead.primarySector?.id))
        if (find) {
            formik.setFieldValue('sector', find)
        }
        return find
    }, [ sectorOptions, formik, lead ])

    const handleSectorSelect = useCallback((value: SectorResource) => {
        formik.setFieldValue('sector', value)
    }, [ formik ])

    const handleCompanyTypeSelect = useCallback((leadType: LeadTypeResource) => {
        formik.setFieldValue('companyType', leadType)
    }, [ formik ])

    const populateCompanyTypeSelect = useCallback(() => {
        const find: LeadTypeResource|undefined =  leadsType.find(value => value.id === Number(lead.client?.customerType?.id))
        if (find) {
            formik.setFieldValue('companyType', find)
        }
        return find
    }, [ leadsType, formik, lead ])

    const itemToLabel = useCallback(item => item.label, [])
    const itemToCode = useCallback(item => item.code, [])

    return (
        <Flex flexDirection="column">
            <form onSubmit={formik.handleSubmit}>
                <Grid gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))" gridColumnGap="30px">
                    <FormControl
                        error={formik.touched.companyName ? formik.errors.companyName : undefined}
                        label={t('leadInformationForm:company_name')}
                    >
                        <Input
                            name="companyName"
                            placeholder={t('leadInformationForm:enter_company_name')}
                            onChange={formik.handleChange}
                            value={formik.values.companyName}
                        />
                    </FormControl>
                    <FormControl
                        error={formik.touched.vatNumber ? formik.errors.vatNumber : undefined}
                        label={t('leadInformationForm:vat_number')}
                    >
                        <Input
                            name="vatNumber"
                            placeholder={t('leadInformationForm:enter_vat_number')}
                            onChange={formik.handleChange}
                            value={formik.values.vatNumber}
                        />
                    </FormControl>
                </Grid>
                <FormControl
                    error={formik.touched.companyType ? formik.errors.companyType : undefined}
                    label={t('leadInformationForm:company_type')}
                >
                    <Select
                        placeholder={t('leadInformationForm:enter_company_type')}
                        items={leadsType}
                        onChange={handleCompanyTypeSelect}
                        selected={!formik.values.companyType ? populateCompanyTypeSelect() : formik.values.companyType}
                        itemToLabel={useCallback(leadsTypeItems => leadsTypeItems.title, [])}
                        disabled
                    />
                </FormControl>
                <Grid gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))" gridColumnGap="30px">
                    <FormControl
                        error={formik.touched.yearEstablished ? formik.errors.yearEstablished : undefined}
                        label={t('leadInformationForm:year_established')}
                    >
                        <Select
                            placeholder={t('leadInformationForm:year_established')}
                            items={years}
                            onChange={handleYearEstablishedSelect}
                            selected={formik.values.yearEstablished}
                            searchable
                            onSearchChange={handleSearchYear}
                            searchValue={String(searchYearValue)}
                        />
                    </FormControl>
                    <FormControl
                        error={formik.touched.numberOfEmployees ? formik.errors.numberOfEmployees : undefined}
                        label={t('leadInformationForm:no_of_employees')}
                    >
                        <Input
                            placeholder={t('leadInformationForm:no_of_employees')}
                            type="number"
                            name="numberOfEmployees"
                            onChange={formik.handleChange}
                            value={String(formik.values.numberOfEmployees)}
                            min={0}
                        />
                    </FormControl>
                </Grid>
                <FormControl
                    label={t('leadInformationForm:zip_code')}
                >
                    <Input
                        placeholder={t('leadInformationForm:enter_zip_code')}
                        type="zipCode"
                        name="zipCode"
                        value={String(lead.geo?.zipCode)}
                        disabled
                    />
                </FormControl>
                {/*<Grid gridTemplateColumns="1fr 1fr" gridGap="30px">*/}
                {/*    <FormControl*/}
                {/*        error={formik.touched.zipCode ? formik.errors.zipCode : undefined}*/}
                {/*        label={t('leadInformationForm:zip_code')}*/}
                {/*    >*/}
                {/*        <Select*/}
                {/*            placeholder={t('leadInformationForm:enter_zip_code')}*/}
                {/*            items={locationOptions}*/}
                {/*            itemToLabel={useCallback(item => item.zipCode + ', ' + item.cityName, [])}*/}
                {/*            searchValue={searchZipCode}*/}
                {/*            onSearchChange={handleSearchZipCode}*/}
                {/*            onChange={handleZipCodeSelect}*/}
                {/*            selected={!formik.values.zipCode ? populateZipCodeSelect() : formik.values.zipCode}*/}
                {/*            searchable*/}
                {/*        />*/}
                {/*    </FormControl>*/}
                {/*    <FormControl*/}
                {/*        error={formik.touched.subRegionName ? formik.errors.subRegionName : undefined}*/}
                {/*        label={t('leadInformationForm:sub_region_name')}*/}
                {/*    >*/}
                {/*        <Input*/}
                {/*            name="cityName"*/}
                {/*            onChange={formik.handleChange}*/}
                {/*            value={formik.values.subRegionName}*/}
                {/*            disabled*/}
                {/*        />*/}
                {/*    </FormControl>*/}
                {/*</Grid>*/}
                <FormControl
                    error={formik.touched.address ? formik.errors.address : undefined}
                    label={t('leadInformationForm:address')}
                >
                    <Input
                        placeholder={t('leadInformationForm:enter_address')}
                        name="address"
                        onChange={formik.handleChange}
                        value={formik.values.address}
                    />
                </FormControl>
                <FormControl
                    error={formik.touched.sector ? formik.errors.sector : undefined}
                    label={t('leadInformationForm:select_sector')}
                >
                    <Select
                        placeholder={t('leadInformationForm:select_sector')}
                        items={sectorOptions}
                        onChange={handleSectorSelect}
                        itemToLabel={useCallback(item => item.displayName, [])}
                        name="sector"
                        selected={!formik.values.sector ? populateSectorSelect() : formik.values.sector}
                    />
                </FormControl>
                <FormControl
                    error={formik.touched.phoneNumber ? formik.errors.phoneNumber : undefined}
                    label={t('leadInformationForm:phone_number')}
                >
                    <PhoneNumber
                        name="phoneNumber"
                        placeholder={t('leadInformationForm:enter_phone_number')}
                        value={formik.values.phoneNumber}
                        items={countryNames}
                        selected={getSelectedCountryByPhoneNumber(formik.values.phoneNumber, lead.geo?.code as string, countryNames)}
                        itemToCode={itemToCode}
                        itemToLabel={itemToLabel}
                        disabled
                    />
                </FormControl>
                <Flex justifyContent="center" mt={3}>
                    <Button variant="secondary" type="submit" mt={2} disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting} grow>
                        <Flex alignItems="center">
                            {formik.isSubmitting && <Spinner size={18}/>}
                            <Flex flexGrow={1} justifyContent="center" mr={formik.isSubmitting ? 18 : 0}>
                                {t('common:confirm')}
                            </Flex>
                        </Flex>
                    </Button>
                </Flex>
            </form>
        </Flex>
    )
}

interface PrivateLeadTypeIds {
    [key: string]: number
}

const privateLeadTypeIds: PrivateLeadTypeIds = {
    dk: 4,
    se: 14,
    no: 18,
    nl: 24,
    de: 25,
    us: 77,
}

interface InformationPageProps {
    caseId: string
}

function Information() {
    const auth = useAuth()
    const { caseId } = useParams<keyof InformationPageProps>() as InformationPageProps
    const { t } = useTranslation([ 'common', 'leadInformationForm' ])

    const { isLoading, data: lead } = useAgerasApi<LeadResource>(
        LeadsApiFetchParamCreator().leadsGet(caseId)
    )

    const navigate = useNavigate()
    const onBackClick = useCallback((): void => {
        navigate(-1)
    }, [ navigate ])

    if (!auth?.client) {
        navigate(-1)
        return null
    }

    if (lead?.geo?.code && lead?.customerInfo?.customerType?.id === privateLeadTypeIds[lead.geo.code]) {
        navigate('/cases')
        return null
    }

    return (
        <WithSidebar>
            <Page>
                <Graphics graphics="primary" />
                <TopBar>
                    <MenuItem
                        label={t('common:back')}
                        onClick={onBackClick}
                        iconLeft={<Icon mr={2} variant="accent">
                            <ChevronLeft size={22}/>
                        </Icon>}
                    />
                </TopBar>
                {isLoading && !lead && <Flex width="100%" height="100%" justifyContent="center" alignItems="100%">
                    <Loader />
                </Flex>}
                {lead && <Flex p={6} maxWidth={800} mx="auto" flexDirection="column">
                    <Heading size="large">{t('leadInformationForm:business_information')}</Heading>
                    <InformationForm
                        lead={lead}
                    />
                </Flex>}
            </Page>
        </WithSidebar>
    )
}

export default Information
