import React from 'react'
import Text from '../../texts/Text'
import styled from 'styled-components'

export interface ErrorProps {
    error?: string
    warning?: string
}

const StyledText = styled(Text)`
    min-height: 20px;
`

const Error = ({ error, warning }: ErrorProps) =>
    <StyledText size="small" textAlign="left" color="danger">{error || warning || ''}</StyledText>

export default Error
