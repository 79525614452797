import React, { ReactElement } from 'react'
import styled, { css } from 'styled-components'
import { space } from 'styled-system'

export const Progress: any = styled.ul`
    position: relative;
    list-style: none;
    margin: 0;
    ${space};
`

interface Item {
    active?: boolean
    completed?: boolean
}

const Item = styled.li<Item>`
    position: relative;
    counter-increment: list;
    ${space};

    &:before {
        content: "";
        position: absolute;
        left: -28px;
        top: 0;
        bottom: 0;
        width: 1px;
        border-left: 2px solid ${({ theme: { progress } }) => progress.colors.inactive};
    }

    &:after {
        content: counter(list);
        position: absolute;
        top: 0;
        left: -40px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: ${({ theme: { progress } }) => progress.colors.inactive};
        color: #fff;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        border: 1px solid ${({ theme: { progress } }) => progress.colors.inactive};
    }

    &:last-child:before {
        border: none;
    }

    ${({ active }) => active && css`
        &:after {
            background: ${({ theme: { progress } }) => progress.colors.active};
            border-color: ${({ theme: { progress } }) => progress.colors.active};
            color: white;
        }
    `}

    ${({ completed }) => completed && css`
        &:after {
            background: ${({ theme: { progress } }) => progress.colors.active};
            border-color: ${({ theme: { progress } }) => progress.colors.active};
            color: ${({ theme: { progress } }) => progress.colors.content};
        }
        &:before {
            border-color: ${({ theme: { progress } }) => progress.colors.active};
        }
    `}
`

Progress.Item = Item

interface ProgressBar {
    children: ReactElement | ReactElement[]
    step?: number
}

const ProgressBar = ({ children, step = 0 }: ProgressBar) => {
    if (!children) {
        return null
    }

    const items = children.constructor === Array ? children : [ children ]

    return <Progress>
        {items.map((item, i) => <Progress.Item key={i} pl={2} pb={3} active={step === i + 1} completed={step > i + 1}>
            {item}
        </Progress.Item>)}
    </Progress>
}

export default ProgressBar
