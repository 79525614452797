import React, { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { GuestUser, setGuestUser, getGuestUser } from '../../utils/guestUser'
import { GeoCodes, languages, partnersSearchParams } from '../../utils'
import { useTranslation } from 'react-i18next'
import { setLanguageCode } from '../../utils/languageCode'

interface GuestUserGetterProps {
    geoCode: string
    childIndustryId: string
    primaryIndustryId: string
}

function GuestUserGetter({
    geoCode,
    childIndustryId,
    primaryIndustryId,
}: GuestUserGetterProps) {
    const [ searchParams ] = useSearchParams()
    const leadTypeId = searchParams.get(partnersSearchParams.leadType)
    const zipCode = searchParams.get(partnersSearchParams.zipCode)
    const navigate = useNavigate()
    const existingGuestUser = getGuestUser()

    const createGuestUser = (values: GuestUser) => {
        setGuestUser(values)
    }

    useEffect(() => {
        const guestUser: GuestUser = {
            ...existingGuestUser,
            geoCode,
            childIndustryId,
            primaryIndustryId,
        }

        if (zipCode) {
            guestUser.zipCode = String(zipCode)
        }

        if (leadTypeId) {
            guestUser.leadTypeId = leadTypeId
        }

        createGuestUser(guestUser)
        navigate('/form', { replace: true })
    }, [ childIndustryId, existingGuestUser, geoCode, leadTypeId, navigate, primaryIndustryId, zipCode ])

    return null
}

function StartStep() {
    const { i18n } = useTranslation()
    const [ searchParams ] = useSearchParams()
    const geoCode = searchParams.get(partnersSearchParams.geoCode)
    const childIndustryId = searchParams.get(partnersSearchParams.childIndustry)
    const primaryIndustryId = searchParams.get(partnersSearchParams.industry)
    const leadTypeId = searchParams.get(partnersSearchParams.leadType)
    const navigate = useNavigate()

    if (geoCode && geoCode in languages) {
        type languageKey = keyof typeof languages;
        i18n.changeLanguage(String(languages[geoCode as languageKey]))
        setLanguageCode(geoCode as GeoCodes)
    }

    const redirect = useCallback((url: string) => {
        navigate({
            pathname: url,
            search: searchParams.toString(),
        }, { replace: true })
    }, [ navigate, searchParams ])

    useEffect(() => {
        if (!geoCode) {
            redirect('/')
            return
        }
        if (!primaryIndustryId) {
            redirect('/partners-search/setup/step/1')
            return
        }
        if (!childIndustryId) {
            redirect('/partners-search/setup/step/2')
            return
        }
        if (!leadTypeId) {
            redirect('/partners-search/setup/step/3')
            return
        }
    }, [ childIndustryId, geoCode, redirect, primaryIndustryId, leadTypeId ])

    if (!geoCode || !childIndustryId || !primaryIndustryId) {
        return null
    }

    return <GuestUserGetter geoCode={geoCode} childIndustryId={childIndustryId} primaryIndustryId={primaryIndustryId}/>
}

export default StartStep
