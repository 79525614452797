import styled, { CSSObject } from 'styled-components'
import {
    boxShadow,
    BoxShadowProps,
    color,
    ColorProps,
    size,
    SizeProps,
    space,
    SpaceProps,
    flexbox,
    FlexboxProps,
    layout,
    LayoutProps,
    position,
    PositionProps,
    border,
    BorderProps,
    background,
    BackgroundProps,
    GridGapProps,
    gridGap,
} from 'styled-system'

export interface FlexProps extends SpaceProps, FlexboxProps, LayoutProps, PositionProps, BorderProps, BackgroundProps, BoxShadowProps, ColorProps, SizeProps, GridGapProps {
    resize?: CSSObject['resize']
    boxSizing?: HTMLElement['style']['boxSizing']
    cursor?: HTMLElement['style']['cursor']
    gap?: CSSObject['gap']
}

const Flex = styled.div<FlexProps>(
    {
        display: 'flex',
        position: 'relative',
    },
    gridGap,
    boxShadow,
    color,
    size,
    background,
    border,
    position,
    flexbox,
    layout,
    space,
    ({ cursor }) => ({ cursor }),
    ({ resize }) => ({ resize }),
    ({ boxSizing }) => boxSizing && `box-sizing: ${boxSizing}`,
    ({ gap }) => gap && `gap: ${gap}`
)

export const Spacer = styled.div`
    margin-top: auto;
    margin-left: auto;
    margin-bottom: 0;
    margin-right: 0;
`

export default Flex
