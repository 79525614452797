import styled from 'styled-components'
import { ModalBackdropProps } from './index'

const ModalBackdrop = styled.div<ModalBackdropProps>`
    all: inherit;
    filter: ${props => props.active ? 'blur(5px)' : undefined};
    overflow: ${props => props.active ? 'hidden' : undefined};

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        display: ${props => props.active ? 'block' : 'none'};
        background-color: rgba(0, 0, 0, .2);
    }
`

export default ModalBackdrop
