import React, { Dispatch, SetStateAction } from 'react'
import styled, { css } from 'styled-components'
import Flex from '../layout/Flex'

interface Tab {
    active?: boolean
}

export const Tab = styled(Flex)<Tab>`
    display: inline-block;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
    }

    ${({ active }) => active && css`
        color: ${({ theme: { tabs } }) => tabs.colors.active};
        border-bottom: 3px solid ${({ theme: { tabs } }) => tabs.colors.active};
    `}
`

export const TabsContainer = styled(Flex)`
    border-bottom: 1px solid ${({ theme: { tabs } }) => tabs.colors.containerBorder};
`
