import { Input } from 'components/src/inputs/Input'
import Flex from 'components/src/layout/Flex'
import { Field, Form, Formik, FormikProps } from 'formik'
import styled from 'styled-components'
import { SchemaOf } from 'yup'
import * as yup from 'yup'
import StepForm, { StepFormWrapperProps, SubmitButton } from 'components/src/MultiStepForm/StepForm'
import { useTranslation } from 'react-i18next'

const InputWrapper = styled(Flex)`
    height: 150px;
    width: 100%;

    & > div {
        width: 100%;
    }
`

const FormInput = styled(Input)`
    width: 100%;
`

interface RevenueFormValues {
    revenue: number
}

function RevenueFormBody(props: FormikProps<RevenueFormValues>) {
    const { t } = useTranslation('searchSetup')
    const submitDisabled = props?.errors.revenue != null

    return (
        <Form>
            <InputWrapper justifyContent="center" alignItems="center">
                <Field
                    name="revenue"
                    label={t('enter_revenue')}
                    as={FormInput}
                    error={props?.errors?.revenue}
                    type="number"
                />
            </InputWrapper>
            <Flex justifyContent="center">
                <SubmitButton
                    variant="secondary"
                    type="submit"
                    disabled={submitDisabled}
                >
                    {t('search_now')}
                </SubmitButton>
            </Flex>
        </Form>
    )
}

function handleSubmit(submit: PartnerSearchStepRevenueFormProps['submit']) {
    return (data: RevenueFormValues) => {
        submit(data.revenue)
    }
}

interface PartnerSearchStepRevenueFormProps extends StepFormWrapperProps {
    submit: (revenue: number) => void
}

function RevenueForm(props: PartnerSearchStepRevenueFormProps) {
    const { submit, header, subheader } = props
    const { t } = useTranslation('validation')

    const formValidationSchema: SchemaOf<RevenueFormValues> = yup.object().shape({
        revenue: yup.number().required(t('required')).positive().integer(),
    })

    return (
        <StepForm
            header={header}
            subheader={subheader}
        >
            <Formik
                initialValues={{
                    revenue: 0,
                }}
                onSubmit={handleSubmit(submit)}
                validationSchema={formValidationSchema}
            >
                {(formProps) => <RevenueFormBody {...formProps} />}
            </Formik>
        </StepForm>
    )
}

export default RevenueForm
