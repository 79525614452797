import React, { useCallback } from 'react'
import {
    LeadQuoteActionResource,
    LeadQuoteResource,
    LeadResource,
    LeadsApiFetchParamCreator,
} from 'ageras-api-client/src/api'
import { useAgerasApi, useAgerasApiMutation } from 'ageras-api-client/src'
import Grid from 'components/src/layout/Grid'
import Text from 'components/src/texts/Text'
import Heading from 'components/src/texts/Heading'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import ActionBar from 'components/src/ActionBar'
import Page, { Graphics } from 'components/src/layout/Page'
import Flex from 'components/src/layout/Flex'
import Icon from 'components/src/icons/Icon'
import { ChevronLeft } from 'react-feather'
import Header from 'components/src/Header'
import Button from 'components/src/Button'
import ConfirmForm from './ConfirmForm'
import ContactInfoForm from './ContactInformationForm'
import OfferCheckoutCard from './OfferCheckoutCard'
import { UseMutationResult } from '@tanstack/react-query'
import { getLeadProgressStatus, leadProgressStatuses } from '../../utils'
import { useTranslation } from 'react-i18next'

function saveLead(mutation: UseMutationResult<LeadResource, Error, LeadResource>) {
    return (data: LeadResource) => {
        mutation.mutate(data)
    }
}

function acceptQuote(mutation: UseMutationResult<LeadQuoteActionResource, Error, LeadQuoteActionResource>) {
    return () => {
        const actionData: LeadQuoteActionResource = {
            action: LeadQuoteActionResource.ActionEnum.Accept,
        }
        mutation.mutate(actionData)
    }
}

interface QuoteDetailRouteParams {
    caseId: string
    quoteId: string
}

function QuoteDetail() {
    const { caseId, quoteId } = useParams<keyof QuoteDetailRouteParams>() as QuoteDetailRouteParams
    const navigate = useNavigate()
    const { t } = useTranslation([ 'quoteDetail', 'common' ])

    const goBack = useCallback(() => navigate(-1), [ navigate ])

    const leadMutation = useAgerasApiMutation<LeadResource>(
        leadData => LeadsApiFetchParamCreator().leadsUpdate(caseId, leadData)
    )

    const quoteMutation = useAgerasApiMutation<LeadQuoteActionResource>(
        data => LeadsApiFetchParamCreator()
            .leadsQuotesActionsCreate(caseId, quoteId, data),
        {
            onSuccess: () => {
                navigate('/cases')
            },
        }
    )

    const { isLoading: quoteLoading, error: quoteError, data: quote } = useAgerasApi<LeadQuoteResource>(
        LeadsApiFetchParamCreator().leadsQuotesGet_2(caseId, quoteId)
    )
    const { isLoading: leadLoading, error: leadError, data: lead } = useAgerasApi<LeadResource>(
        LeadsApiFetchParamCreator().leadsGet(caseId)
    )

    const progressStatus = lead ? getLeadProgressStatus(lead) : ''
    if (![ leadProgressStatuses.awaitingQuotes, leadProgressStatuses.awaitingSelection, leadProgressStatuses.inProgress ].includes(progressStatus)) {
        return <Navigate to="/cases" replace />
    }

    if (quoteError || leadError) {
        return <div>{t('common:error_occurred', { message: quoteError?.message || leadError?.message })}</div>
    }

    const quoteAvailable = !quoteLoading && quote
    const leadAvailable = !leadLoading && lead
    const leadPrimaryIndustry = lead?.industries?.primary?.identifier
    const showPricesWithVat = lead?.customerInfo?.showPricesIncludingVat

    return (
        <Grid gridTemplateColumns="1fr 700px">
            <Page>
                <Graphics graphics="primary" />
                <Header>
                    <Flex px={6}>
                        <Flex as={Button} variant="transparent" onClick={goBack} alignItems="center">
                            <Icon variant="accent" as={ChevronLeft} size={30} />
                            <Text ml={2} mt={1}>{t('common:go_back')}</Text>
                        </Flex>
                    </Flex>
                </Header>
                <Flex p={6} maxWidth={1000} flexDirection="column">
                    <Heading size="large">
                        {t('quoteDetail:application_confirmation')}
                    </Heading>
                </Flex>
                <Flex px={6} maxWidth={1000} flexDirection="column">
                    {!quoteAvailable && <Text>{t('quoteDetail:loading_offer_info')}</Text>}
                    {quoteAvailable &&
                    <OfferCheckoutCard
                        quote={quote}
                        industry={leadPrimaryIndustry}
                        showPricesWithVat={showPricesWithVat}
                    />
                    }
                </Flex>
            </Page>
            <Page graphics="left">
                <Header />
                {!leadAvailable && <Flex p={8} flexDirection="column">
                    <Text>{t('quoteDetail:loading_contact_info')}</Text>
                </Flex>}
                {leadAvailable &&
                <ContactInfoForm lead={lead} onSubmit={saveLead(leadMutation)} />
                }
            </Page>
            <ActionBar>
                <Flex px={10} flexDirection="column">
                    {leadAvailable && lead.geo?.code && <ConfirmForm geoCode={lead.geo.code} onSubmit={acceptQuote(quoteMutation)} isActionLoading={quoteMutation.isLoading} />}
                </Flex>
            </ActionBar>
        </Grid>
    )
}

export default QuoteDetail
