import React, { ReactElement } from 'react'
import styled from 'styled-components'
import Flex from '../layout/Flex'
import Text from '../texts/Text'

export interface AppliedSearchFilterProps {
    label: ReactElement | string
    onClick?: () => void
}

export const AppliedSearchFilterWrapper = styled(Flex)`
    align-items: center;
    font-weight: normal;
    width: max-content;
    padding-left: 25px;
    padding-right: 25px;
    margin-right: 15px;
    height: 36px;
    border-radius: 10px;
    border: 1px solid;
    color: ${({ theme }) => theme.appliedSearchFilters.colors.color};
    background-color: ${({ theme }) => theme.appliedSearchFilters.colors.background};
`

function AppliedSearchFilter(props: AppliedSearchFilterProps) {
    return (
        <AppliedSearchFilterWrapper {...props}>
            <Text>{props.label}</Text>
        </AppliedSearchFilterWrapper>
    )
}

export default AppliedSearchFilter
