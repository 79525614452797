import React, { useCallback, useState } from 'react'
import { ChevronDown, ChevronUp, User } from 'react-feather'
import { Link } from 'react-router-dom'
import {
    LeadIndustriesResource,
    LeadResource, PartnerResource
} from 'ageras-api-client/src/api'
import Flex from 'components/src/layout/Flex'
import Text from 'components/src/texts/Text'
import Heading from 'components/src/texts/Heading'
import Circle from 'components/src/icons/Circle'
import Icon from 'components/src/icons/Icon'
import Button from 'components/src/Button'
import Card from 'components/src/Card'
import LeadQuotesInfo from './LeadQuotesInfo'
import { leadProgressStatuses, getLeadProgressStatus } from '../../../utils'
import Image from 'components/src/Image'
import { useTranslation } from 'react-i18next'

interface LeadCardProps {
    lead: LeadResource
    openPartnerDetail: (newPartner: PartnerResource) => Promise<void>
    openPartnerMessages: (newPartner: PartnerResource, newLead?: LeadResource) => Promise<void>
}

const serviceString = (industries: LeadIndustriesResource|undefined) => {
    if (!industries) {
        return 'Services'
    }

    switch (industries.primary?.identifier) {
        case 'loans':
            return 'Financial services'
        case 'law':
            return 'Law services'
        default:
            return 'Accounting services'
    }
}

const getStatusText = (progressStatus: string) => {
    if (progressStatus === leadProgressStatuses.completed) {
        return <Text color="success">{progressStatus}</Text>
    }
    if ([ leadProgressStatuses.allQuotesRejected, leadProgressStatuses.abandoned, leadProgressStatuses.spam ].includes(progressStatus)) {
        return <Text color="danger">{progressStatus}</Text>
    }
    if ([ leadProgressStatuses.notSerious, leadProgressStatuses.duplicate ].includes(progressStatus)) {
        return <Text color="warning">{progressStatus}</Text>
    }
    return <Text color="accent">{progressStatus}</Text>
}

interface LeadHeadingProps {
    lead: LeadResource
}

const LeadHeading = ({ lead }: LeadHeadingProps) => {
    if (lead.quotesInfo?.acceptedQuote) {
        const partner = lead.quotesInfo?.quotes?.find(quote => quote.id === lead.quotesInfo?.acceptedQuote?.id)?.partner
        return (
            <>
                {partner?.logo && <Image background={partner.logo.endsWith('.jpg')} round src={partner.logo} variant="large"/>}
                {Boolean(partner?.logo) || <Circle size="large">
                    <Heading size="large" color="light">{partner?.companyName?.substr(0, 2)}</Heading>
                </Circle>}
                <Flex flexDirection="column" ml={3}>
                    <Heading size="medium" color="impact">{partner?.companyName}</Heading>
                    <Text color="default">{serviceString(lead.industries)}</Text>
                </Flex>
            </>
        )
    }

    return (
        <>
            <Circle size="large">
                <Icon as={User} size={30} />
            </Circle>
            {lead.industries &&
                <Heading size="medium" color="impact" ml={3}>{serviceString(lead.industries)}</Heading>
            }
        </>
    )
}

function LeadCard(props: LeadCardProps) {
    const { t } = useTranslation([ 'common', 'caseList' ])
    const [ open, setOpen ] = useState<boolean>(false)
    const { lead, openPartnerDetail, openPartnerMessages } = props

    const progressStatus = getLeadProgressStatus(lead)
    const canUseFlow = [ leadProgressStatuses.awaitingSelection, leadProgressStatuses.awaitingQuotes, leadProgressStatuses.inProgress ].includes(progressStatus)
    const canSeeDetails = ![ leadProgressStatuses.notSerious, leadProgressStatuses.duplicate, leadProgressStatuses.abandoned, leadProgressStatuses.spam ].includes(progressStatus)

    const toggleOpen = useCallback(() => {
        setOpen(!open)
    }, [ open ])

    return (
        <Card mb={4}>
            <Card.Content p={4}>
                <Flex alignItems="center">
                    <Flex alignItems="center">
                        <LeadHeading
                            lead={lead}
                        />
                    </Flex>
                    {lead.quotesInfo && <Text ml="auto">
                        {t('caseList:status')}
                        {': '}
                        {getStatusText(progressStatus)}
                    </Text>
                    }
                    {canUseFlow && <Link to={`/cases/${lead.id}/quotes`}>
                        <Button variant="ternary" ml={3}>{t('caseList:see_details')}</Button>
                    </Link>}
                    {canSeeDetails && <Button variant="transparent" size="small" onClick={toggleOpen} ml={3}>
                        {open ? <ChevronUp size={24}/> : <ChevronDown size={24}/>}
                    </Button>}
                </Flex>
            </Card.Content>
            <Card.Collapse open={canSeeDetails && open}>
                <Card.Line />
                <LeadQuotesInfo
                    lead={lead}
                    openPartnerDetail={openPartnerDetail}
                    openPartnerMessages={openPartnerMessages}
                />
            </Card.Collapse>
        </Card>
    )
}

export default LeadCard
