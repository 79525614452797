import {
    LeadQuoteInfoEntryResource,
    LeadQuoteResource,
    LeadResource,
} from 'ageras-api-client/src/api'
import countries from 'i18n-iso-countries'

const MAX_DEFAULT_DISTANCE = 100
const MAX_DISTANCE: {[index: string]: number} = {
    dk: 2,
    no: 40,
    se: 80,
    de: 100,
    nl: 50,
    us: 300,
}

export const getCountryNames = (translated: (key: string) => string) => {
    const toReturn = []
    for (let key in countries.getAlpha2Codes()) {
        toReturn.push({
            code: key,
            label: translated(key),
        })
    }

    return toReturn
}

export type GeoCodes = 'se' | 'dk' | 'no' | 'nl' | 'de' | 'us'

export const languageCodeToISOCountryCode: Record<string, string> = {
    en: 'us',
    da: 'dk',
}

export const languages = {
    en: 'en',
    dk: 'da',
    se: 'sv-SE',
    no: 'nb',
    nl: 'nl-NL',
    de: 'de-DE',
    us: 'en-US'
}

export const configCurrencies: { no: string; de: string; se: string; dk: string; nl: string; us: string } = {
    dk: 'DKK',
    no: 'NOK',
    se: 'SEK',
    de: 'EUR',
    nl: 'EUR',
    us: 'USD',
}

export const partnersSearchArrangeParam = 'sort'

export const partnersSearchParams = {
    geoCode: 'geo_code',
    industry: 'industry_id',
    childIndustry: 'child_industry_id',
    distance: 'geo_distance',
    revenue: 'revenue_amount',
    search: 'search',
    rating: 'minimum_partner_rating',
    certificates: 'segmented_for_certification_id',
    product: 'product_id',
    leadType: 'lead_type_id',
    zipCode: 'zip_code',
}

export const leadProgressStatuses = {
    completed: 'Completed',
    notSerious: 'Not serious',
    spam: 'Spam',
    duplicate: 'Duplicate',
    abandoned: 'Abandoned',
    awaitingQuotes: 'Awaiting quotes',
    awaitingSelection: 'Awaiting selection',
    allQuotesRejected: 'All rejected',
    inProgress: 'Processing',
}

export const getLeadProgressStatus = (lead: LeadResource) => {
    if (lead.status === LeadResource.StatusEnum.NotSerious) {
        return leadProgressStatuses.notSerious
    }
    if (lead.status === LeadResource.StatusEnum.Spam) {
        return leadProgressStatuses.spam
    }
    if (lead.status === LeadResource.StatusEnum.Duplicate) {
        return leadProgressStatuses.duplicate
    }
    if (!lead.quotesInfo) {
        return leadProgressStatuses.inProgress
    }
    if (lead.quotesInfo.acceptedQuote) {
        return leadProgressStatuses.completed
    }
    if (lead.quotesInfo.openForQuotes) {
        return leadProgressStatuses.awaitingQuotes
    }
    if (!lead.quotesInfo.openForQuotes && !Number(lead.quotesInfo.max)) {
        return leadProgressStatuses.abandoned
    }
    const quoteStatuses = lead.quotesInfo.quotes?.length ? lead.quotesInfo.quotes?.map(quote => quote.status) : []
    if (quoteStatuses.length && quoteStatuses.every( (val, i, arr) => val === arr[0] && val === LeadQuoteInfoEntryResource.StatusEnum.Rejected )) {
        return leadProgressStatuses.allQuotesRejected
    }
    if (Number(lead.quotesInfo.count) === Number(lead.quotesInfo.max) && lead.quotesInfo.quotes?.every(quote => Boolean(quote.publishedAt))) {
        return leadProgressStatuses.awaitingSelection
    }
    return leadProgressStatuses.inProgress
}

export function getTotalQuoteValue(quote: LeadQuoteResource) {
    const geoCode = quote.partner?.geoCode ?? 'dk'
    const defaultTotal = {
        totalAmountExclVat: {
            amount: 0,
            currency: configCurrencies[geoCode as GeoCodes] || undefined,
        },
        totalAmountVat: {
            amount: 0,
            currency: configCurrencies[geoCode as GeoCodes] || undefined,
        },
        totalAmountInclVat: {
            amount: 0,
            currency: configCurrencies[geoCode as GeoCodes] || undefined,
        },
    }
    return quote.items?.length ? quote.items.reduce((acc, cur) => {
        acc.totalAmountExclVat.amount += cur.totalAmountExclVat?.amount ?? 0
        acc.totalAmountExclVat.currency = cur.totalAmountExclVat?.currency

        acc.totalAmountVat.amount += cur.totalAmountVat?.amount ?? 0
        acc.totalAmountVat.currency = cur.totalAmountVat?.currency

        acc.totalAmountInclVat.amount += cur.totalAmountInclVat?.amount ?? 0
        acc.totalAmountInclVat.currency = cur.totalAmountInclVat?.currency
        return acc
    }, defaultTotal) : defaultTotal
}

export function getClientCurrencyCode(geoCode: string): string | null {
    return configCurrencies[geoCode as GeoCodes] ?? null
}

export const getDistanceUnitByGeoCode = (geoCode: string | undefined): string => {
    if (geoCode && geoCode === 'us') {
        return 'miles'
    }

    return 'km'
}

export const getMaxDistanceByGeoCode = (geoCode: string | undefined): number => {
    if (geoCode && MAX_DISTANCE[geoCode] && MAX_DISTANCE[geoCode] >= 50) {
        return MAX_DISTANCE[geoCode]
    }

    return MAX_DEFAULT_DISTANCE
}

export const range = (start: number, stop: number, step: number, reverse: boolean) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => {
        if (reverse)
            return stop - (i * step)
        return start + (i * step)
    })
