import React, {
    ChangeEvent,
    useState,
    Dispatch,
    SetStateAction,
    useCallback,
} from 'react'
import styled, { css } from 'styled-components'
import Button from 'components/src/Button'
import Range from 'components/src/inputs/Range'
import { useComponentVisible } from 'components/src/inputs/Select'
import Rating from 'components/src/Rating'
import Flex from 'components/src/layout/Flex'
import Checkbox from 'components/src/inputs/Checkbox'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { getDistanceUnitByGeoCode, getMaxDistanceByGeoCode, partnersSearchParams } from '../../utils'
import { useAgerasApi } from 'ageras-api-client/src'
import {
    CertificationResource,
    CertificationResult,
    CertificationsApiFetchParamCreator,
} from 'ageras-api-client/src/api'
import getBasicAuth from '../../utils/getBasicAuth'
import Heading from 'components/src/texts/Heading'

const PARAM_SEPARATOR = ','

interface ControlsWrapperProps {
    open?: boolean
}

const ControlsWrapper = styled.div<ControlsWrapperProps>`
    position: absolute;
    width: inherit;
    top: 100%;
    left: 0;
    right: 0;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: ${({ theme: { boxShadows } }) => boxShadows.base};
    background-color: ${({ theme }) => theme.pages.colors.base.background};
    z-index: 9999;
    display: none;
    ${({ open }) => open && css`
        display: block;
    `}
`

const SearchFiltersWrapper = styled.div`
    position: relative;
    border-radius: 16px;
    user-select: none;
    width: 400px;
`

const toggleFilterControls = (isOpen: boolean, setOpen: Dispatch<SetStateAction<boolean>>) => () => setOpen(!isOpen)

const RenderCertificationSwitcher = (
    certification: CertificationResource,
    isSelected: boolean,
    CertificationOnChange: (event: ChangeEvent<HTMLInputElement>) => void
) => {
    return <Flex mt={2} mb={1} key={certification.id}>
        <Checkbox
            data-id={certification.id}
            name={certification.title || ''}
            labelLeft={certification.title || ''}
            value={isSelected}
            width="350px"
            onChange={CertificationOnChange}
        />
    </Flex>
}

const SearchFiltersSection = () => {
    const [ isOpen, setOpen ] = useState<boolean>(false)
    const ref = useComponentVisible<HTMLDivElement>(setOpen)
    const [ range, setRange ] = React.useState<string>('')
    const [ rating, setRating ] = React.useState<number>(0)
    const { t } = useTranslation('searchResultList')
    const [ searchParams, setSearchParams ] = useSearchParams()
    const defaultCertifications: string = searchParams.get(partnersSearchParams.certificates) || ''
    const [ certifications, setCertifications ] = React.useState<number[]>(
        defaultCertifications.split(PARAM_SEPARATOR).map(element => Number(element))
    )
    const geoCode: string = searchParams.get(partnersSearchParams.geoCode) || ''
    const maxDistance: number = getMaxDistanceByGeoCode(geoCode)
    const distanceUnit: string = getDistanceUnitByGeoCode(geoCode)
    const RANGE_STEP = 10
    const INFINITE_VALUE = maxDistance + RANGE_STEP
    const defaultDistance: number = Number(searchParams.get(partnersSearchParams.distance) || INFINITE_VALUE)
    const defaultRating: number = Number(searchParams.get(partnersSearchParams.rating))

    const { data: allCertifications } = useAgerasApi<CertificationResult>(
        CertificationsApiFetchParamCreator().certificationsIndex(
            geoCode
        ),
        { meta: { authorization: getBasicAuth() } }
    )

    const RangeOnChange = useCallback((value) => {
        setRange(value)
    }, [ setRange ])

    const RatingOnChange = useCallback((value) => {
        setRating(value)
    }, [ setRating ])

    const CertificationOnChange = useCallback((event: ChangeEvent<HTMLInputElement>)  => {
        const id: number = parseInt((event.target as HTMLInputElement).dataset.id as string, 10)
        if (event.target.checked) {
            certifications.push(id)
        } else if (certifications.indexOf(id) !== -1) {
            certifications.splice(certifications.indexOf(id), 1)
        }

        setCertifications([ ...certifications ])
    }, [ certifications, setCertifications ])

    const OnSubmit = useCallback(() => {
        if (parseInt(range, 10) > maxDistance) {
            searchParams.delete(partnersSearchParams.distance)
        } else if (parseInt(range, 10) > 0) {
            searchParams.set(partnersSearchParams.distance, range)
        }
        if (rating > 0) {
            searchParams.set(partnersSearchParams.rating, rating as unknown as string)
        }
        if (certifications && certifications.length > 0) {
            searchParams.set(partnersSearchParams.certificates, certifications.join(PARAM_SEPARATOR))
        }

        setOpen(false)

        setSearchParams(searchParams)
    }, [
        maxDistance,
        searchParams,
        setSearchParams,
        setOpen,
        range,
        rating,
        certifications,
    ])

    return (
        <SearchFiltersWrapper ref={ref}>
            <Button variant="ternary" onClick={toggleFilterControls(isOpen, setOpen)}>
                {t('search_filters.filters')}
            </Button>
            <ControlsWrapper open={isOpen}>
                <Flex ml={3} mt={2} flexDirection="column">
                    <Heading size="small" mt={2} mb={1} display="block">
                        {t('search_filters.distance')}
                    </Heading>
                    <Range
                        width="350px"
                        min={0}
                        max={INFINITE_VALUE}
                        step={RANGE_STEP}
                        unit={distanceUnit}
                        onChange={RangeOnChange}
                        value={defaultDistance}
                    />
                </Flex>
                <Flex ml={3} mt={5}>
                    <Heading mr={2} mt={1} size="small">
                        {t('search_filters.rating')}
                    </Heading>
                    <Rating
                        totalNumberOfStars={5}
                        onChange={RatingOnChange}
                        disabled={false}
                        ratingValue={defaultRating}
                    />
                </Flex>
                <Flex ml={3} mt={5} flexDirection="column">
                    <Heading size="small" mb={1} display={[ 'block' ]}>
                        {t('search_filters.certifications')}
                    </Heading>
                    {allCertifications?.data?.map((certification) => {
                        const isSelected: boolean = certifications.indexOf(certification.id!) !== -1
                        return RenderCertificationSwitcher(certification, isSelected, CertificationOnChange)
                    })}
                </Flex>
                <Flex ml={3} mt={3} mb={3} justifyContent="center">
                    <Button variant="ternary" onClick={OnSubmit}>{t('search_filters.set_filters')}</Button>
                </Flex>
            </ControlsWrapper>
        </SearchFiltersWrapper>
    )
}

export default SearchFiltersSection
