import React from 'react'
import styled, { css } from 'styled-components'
import Flex from '../../layout/Flex'
import Text from '../../texts/Text'
import Date from '../../Date'

const MessageCard = styled(Flex)<{ own?: boolean }>`
    border-radius: 8px;
    width: max-content;
    box-shadow: ${({ theme: { messages } }) => messages.shadow};

    ${({ own, theme: { messages } }) => own ? css`
        border-bottom-right-radius: 0;
        background-color: ${messages.colors.white};
        align-self: flex-end;
    ` : css`
        border-bottom-left-radius: 0;
        background-color: ${messages.colors.accent};
        align-self: flex-start;
    `}
`

interface MessageProps {
    text?: string
    own: boolean
    name?: string
    updatedAt?: string
}

function Message({ text, own, name, updatedAt }: MessageProps) {
    return <Flex flexDirection="column" justifyContent={own ? 'flex-end' : 'flex-start'}>
        <MessageCard px={16} py={12} mb={1} own={own}>
            <Text size="medium">{text}</Text>
        </MessageCard>
        <Text size="small" mb={3} mx={16} textAlign={own ? 'right' : 'left'}>
            {name}
            {' '}
            {updatedAt && <Date datetime={updatedAt}/>}
        </Text>
    </Flex>
}

export default Message
