import Flex from 'components/src/layout/Flex'
import { CircleFlag, countries } from 'react-circle-flags'
import styled from 'styled-components'
import { languageCodeToISOCountryCode } from '../../../utils'
import Text from 'components/src/texts/Text'

const OpaqueText = styled(Text)`
    opacity: .5;
`

type AvailableCountryCodes = keyof typeof countries

// Method that takes language codes and replaces any, which dont match ISO country codes with correct one
const parseLanguageCodesToCountryCodes = (languageCodes: string[]): string[] => {
    return languageCodes.map((languageCode: string) => {
        if (languageCodeToISOCountryCode.hasOwnProperty(languageCode)) {
            return languageCodeToISOCountryCode[languageCode]
        }

        return languageCode
    })
}

export interface Language {
    code: string
    name: string
}

interface LanguagesListProps {
    languages: Language[]
    iconSize?: number
}

function LanguagesList(props: LanguagesListProps) {
    const { languages, iconSize } = props

    const languageNames = languages.map((language: Language) => language.name)
    const languageCodes = languages.map((language: Language) => language.code)

    const countryCodes = parseLanguageCodesToCountryCodes(languageCodes)

    return (
        <Flex alignItems="center">
            <Flex mr={2}>
                {countryCodes.map((countryCode: AvailableCountryCodes, index) =>
                    <Flex key={index} ml={index === 0 ? 0 : -1}>
                        <CircleFlag
                            width={iconSize}
                            height={iconSize}
                            countryCode={countryCode}
                        />
                    </Flex>
                )}
            </Flex>
            <Flex>
                <OpaqueText size="medium" fontWeight="400" color="impact">
                    {languageNames.join(', ')}
                </OpaqueText>
            </Flex>
        </Flex>
    )
}

export default LanguagesList
