import { LeadQuoteResource, PartnerResource, PartnersApiFetchParamCreator } from 'ageras-api-client/src/api'
import { useAgerasApi } from 'ageras-api-client/src'
import React from 'react'
import Flex from 'components/src/layout/Flex'
import Image from 'components/src/Image'
import Heading from 'components/src/texts/Heading'
import Text from 'components/src/texts/Text'
import Card from 'components/src/Card'
import Badge from 'components/src/Badge'
import Circle from 'components/src/icons/Circle'
import Icon from 'components/src/icons/Icon'
import { Check, User } from 'react-feather'
import Amount from 'components/src/Amount'
import Rating from 'components/src/Rating'
import { getTotalQuoteValue } from '../../utils'
import { useTranslation } from 'react-i18next'

interface QuoteDataProps {
    quote: LeadQuoteResource
    industry: string | undefined
    showPricesWithVat: boolean | undefined
}

function QuoteItems({ quote, industry }: QuoteDataProps) {
    const { t } = useTranslation([ 'quoteDetail', 'common' ])
    switch (industry) {
        case 'loans':
            const loan = quote.loans?.[0]
            return (
                <>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Text size="small">{t('common:loan_amount')}</Text>
                        <Heading color="impact">
                            {loan?.loanAmountExclVat?.amount &&
                            <Amount
                                number={loan.loanAmountExclVat.amount}
                                currency={loan.loanAmountExclVat.currency}
                            />
                            }
                        </Heading>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Text size="small">{t('quoteDetail:repayment_times')}</Text>
                        <Heading color="impact">
                            {loan?.durationMonths}
                            {' '}
                            {t('common:months')}
                        </Heading>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Text size="small">{t('quoteDetail:monthly_fees')}</Text>
                        <Heading color="impact">
                            <Amount
                                number={Number(loan?.totalLoanAmountExclVat?.amount) - Number(loan?.loanAmountExclVat?.amount)}
                                currency={loan?.loanAmountExclVat?.currency}
                            />
                        </Heading>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Text size="small">{t('quoteDetail:aoc')}</Text>
                        <Heading color="impact">
                            {Number(loan?.aprInterestRate) * 100}
                            {t('common:percent_per_year')}
                        </Heading>
                    </Flex>
                </>
            )
        default:
            return (
                <>
                    {quote.items?.map(item => <Flex key={item.id} mb={3} justifyContent="space-between">
                        <Flex flexBasis={0} flexGrow={2}>
                            <Text>{item.title}</Text>
                        </Flex>
                        <Flex flexBasis={0} flexGrow={1} justifyContent="flex-end">
                            <Text>{item.type}</Text>
                        </Flex>
                        <Flex flexBasis={0} flexGrow={1} justifyContent="flex-end" ml={2}>
                            <Text color="impact" fontWeight="bold">
                                {item.amountExclVat?.amount &&
                                <Amount
                                    number={item.amountExclVat.amount}
                                    currency={item.amountExclVat.currency}
                                />
                                }
                            </Text>
                        </Flex>
                    </Flex>)}
                </>
            )
    }
}

function TotalQuoteValue({ quote, industry, showPricesWithVat }: QuoteDataProps) {
    const { t } = useTranslation([ 'quoteDetail', 'common' ])
    switch (industry) {
        case 'loans':
            const loan = quote.loans?.[0]
            return (
                <>
                    <Text color="light" size="large">{t('quoteDetail:total_cost')}</Text>
                    <Heading color="light" size="large">
                        {loan?.totalLoanAmountExclVat?.amount &&
                        <Amount
                            number={loan.totalLoanAmountExclVat.amount}
                            currency={loan.totalLoanAmountExclVat.currency}
                        />
                        }
                    </Heading>
                </>
            )
        default:
            const totalPrice = getTotalQuoteValue(quote)
            const append = showPricesWithVat ? t('common:append_include_vat') : t('common:append_exclude_vat')
            return (
                <>
                    <Text textAlign="right">
                        {t('quoteDetail:total_cost')}
                        {' '}
                        {append}
                    </Text>
                    <Heading color="light" size="large">
                        {showPricesWithVat &&
                        <Amount
                            number={totalPrice.totalAmountInclVat?.amount}
                            currency={totalPrice.totalAmountInclVat?.currency}
                        />
                        }
                        {!showPricesWithVat &&
                        <Amount
                            number={totalPrice.totalAmountExclVat?.amount}
                            currency={totalPrice.totalAmountExclVat?.currency}
                        />
                        }
                    </Heading>
                </>
            )
    }
}

interface OfferCardInterface {
    quote: LeadQuoteResource
    industry: string | undefined
    showPricesWithVat: boolean | undefined
}

function OfferCheckoutCard({ quote, industry, showPricesWithVat }: OfferCardInterface) {
    const { data: partner } = useAgerasApi<PartnerResource>(
        PartnersApiFetchParamCreator().partnersGet(String(quote.partner?.id ?? 0))
    )
    const { t } = useTranslation('common')
    const ratingAvailable = partner?.rating

    return (
        <>
            <Flex
                justifyContent="space-between"
                alignItems="center"
                mb={3}
            >
                <Flex alignItems="center">
                    {partner?.logo && <Image background={partner.logo.endsWith('.jpg')} round={partner.logo.endsWith('.jpg')} src={partner.logo} variant="large"/>}
                    {!partner?.logo &&
                    <Circle size="large" variant="primary">
                        <Icon as={User} size={24} />
                    </Circle>
                    }
                    <Flex flexDirection="column" ml={3}>
                        <Heading>{quote.partner?.companyName}</Heading>
                        <Text>{quote.partner?.city}</Text>
                    </Flex>
                </Flex>
                <Flex>
                    {ratingAvailable &&
                        <Flex alignItems="center">
                            <Rating
                                ratingValue={Number(partner.rating?.score)}
                            />
                            <Text size="small" textAlign="right" mt={1}>
                                {partner.rating?.score}
                                {' '}
                                {t('from')}
                                {' '}
                                {partner.rating?.count}
                                {' '}
                                {t('reviews')}
                            </Text>
                        </Flex>
                    }
                </Flex>
            </Flex>
            <Card>
                <Card.Content px={6} py={4}>
                    <Text>{quote.description}</Text>
                    <Flex mt={4}>
                        {partner?.badges && Boolean(partner?.badges?.length) && <Flex flexDirection="column" flexBasis={0} flexGrow={1}>
                            {partner.badges.map(badge => <Flex flexDirection="column" key={badge.id}>
                                <Badge mb={3}>
                                    <Circle mr={2}>
                                        <Icon>
                                            <Check size={12}/>
                                        </Icon>
                                    </Circle>
                                    <Text>{badge.badgeToken}</Text>
                                </Badge>
                            </Flex>)}
                        </Flex>}
                        <Flex flexDirection="column" flexBasis={0} flexGrow={1}>
                            <QuoteItems
                                quote={quote}
                                industry={industry}
                                showPricesWithVat={showPricesWithVat}
                            />
                        </Flex>
                    </Flex>
                </Card.Content>
                <Card.Content px={6} py={3} accent>
                    <Flex justifyContent="space-between" alignItems="center">
                        <TotalQuoteValue
                            quote={quote}
                            industry={industry}
                            showPricesWithVat={showPricesWithVat}
                        />
                    </Flex>
                </Card.Content>
            </Card>
        </>
    )
}

export default OfferCheckoutCard
