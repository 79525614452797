import styled, { css } from 'styled-components'
import { variant, space, SpaceProps, layout, LayoutProps } from 'styled-system'
import Flex from '../layout/Flex'

export interface CardProps extends SpaceProps, LayoutProps {
    active?: boolean
    variant?: string
    shadow?: string
}

const Card: any = styled(Flex)<CardProps>`
    position: relative;
    flex-direction: column;
    overflow: ${({ overflow }) => overflow ?? 'hidden'};
    border: 1px solid;
    border-radius: 16px;
    ${space};
    ${layout};

    ${variant({
        scale: 'cards.variants.colors',
    })}

    ${variant({
        prop: 'shadow',
        scale: 'cards.variants.shadows',
    })}

    ${({ active, theme: { colors } }) => active && `border-color: ${colors.baseBlue}`}
`

export interface CardContentProps extends SpaceProps, LayoutProps {
    accent?: boolean
    scroll?: boolean
}

const Content = styled(Flex)<CardContentProps>`
    flex-direction: column;
    ${space};
    ${layout};

    ${({ accent, theme }) => accent && theme.cards.content.variants.accent};

    ${({ scroll }) => scroll && css`
        overflow: hidden;
        overflow-y: scroll;
    `};
`

interface LineProps {
    variant?: 'default' | 'primary'
}

const Line = styled.hr<LineProps>`
    width: 100%;
    height: 1px;
    border: 0;
    margin: 0;

    ${variant({
        scale: 'cards.line.variants',
    })}
`

Line.defaultProps = {
    variant: 'default',
}

interface DividerProps {
    variant?: 'default' | 'primary'
}

const VerticalDivider = styled.div<DividerProps>`
    width: 1px;

    ${variant({
        scale: 'cards.line.variants',
    })}
`

VerticalDivider.defaultProps = {
    variant: 'default',
}

interface CollapseProps {
    open: boolean
}

const Collapse = styled.div<CollapseProps>`
    max-height: 0px;
    overflow: hidden;
    transition: max-height .3s ease-in-out;

    ${({ open }) => open && css`
        max-height: 100vh;
    `}
`

Card.defaultProps = {
    variant: 'base',
    shadow: 'base',
}

Card.Content = Content
Card.Line = Line
Card.VerticalDivider = VerticalDivider
Card.Collapse = Collapse

export default Card
