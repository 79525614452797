import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ThemeProvider } from 'styled-components'
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import ModalHandler from 'components/src/modals/ModalHandler'
import light from 'components/src/themes/light'
import Mont from 'components/src/themes/fonts/Mont'
import LineBreaks from 'components/src/themes/globals/LineBreaks'
import i18n from './i18n'
import Home from './pages/Home'
import AuthProvider from './AuthProvider'
import QuoteList from './pages/QuoteList'
import QuoteDetail from './pages/QuoteDetail'
import CaseList from './pages/CaseList'
import Content from 'components/src/layout/Content'
import Flex from 'components/src/layout/Flex'
import PartnerSearchSetup from './pages/PartnerSearchSetup'
import PartnersSearchResultList from './pages/PartnersSearchResultList'
import SignUp from './pages/SignUp'
import Form from './pages/Form'
import Submission from './pages/Form/Submission'
import PhoneVerification from './pages/SignUp/PhoneVerification'
// eslint-disable-next-line implicit-dependencies/no-implicit
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Information from './pages/Information'
import StartStep from './pages/StartStep'
import Loader from 'components/src/Loader'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})

function GlobalLoadingComponent() {
    return (
        <Flex height="100%" width="100%" justifyContent="center" alignItems="center">
            <Loader />
        </Flex>
    )
}

function App() {
    return (
        <React.Suspense fallback={<GlobalLoadingComponent/>}>
            <I18nextProvider i18n={i18n}>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={light}>
                        <Router>
                            <ModalHandler zIndex={1000}>
                                <Content>
                                    <Routes>
                                        <Route path="/start" element={<StartStep />} />
                                        <Route path="/partners-search">
                                            <Route index element={<PartnersSearchResultList/>}/>
                                            <Route path="setup/step/:stepId" element={<PartnerSearchSetup/>}/>
                                        </Route>
                                        <Route path="/signup" element={<SignUp/>}/>
                                        <Route path="/phone-verification" element={<PhoneVerification />}/>
                                        <Route path="/form" element={<Form/>}/>
                                        <Route
                                            path="*"
                                            element={
                                                <AuthProvider>
                                                    <Routes>
                                                        <Route path="/" element={<Home/>}/>
                                                        <Route path="/cases">
                                                            <Route index element={<CaseList/>}/>
                                                            <Route path=":caseId">
                                                                <Route path="information" element={<Information/>}/>
                                                                <Route path="submission" element={<Submission />} />
                                                                <Route path="quotes">
                                                                    <Route path=":quoteId" element={<QuoteDetail/>}/>
                                                                    <Route index element={<QuoteList/>} />
                                                                </Route>
                                                            </Route>
                                                        </Route>
                                                    </Routes>
                                                </AuthProvider>
                                            }
                                        />
                                    </Routes>
                                </Content>
                            </ModalHandler>
                        </Router>
                        <Mont apply={true}/>
                        <LineBreaks/>
                    </ThemeProvider>
                </QueryClientProvider>
            </I18nextProvider>
        </React.Suspense>
    )
}

export default App
