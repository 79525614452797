import styled from 'styled-components'
import Flex from '../Flex'
import Grid from '../Grid'
import { SidebarContainer } from '../../Sidebar'
import Page from '../Page'

const Content = styled(Flex)`
    height: 100vh;
    position: relative;

    & > ${SidebarContainer} {
        flex-basis: auto;
    }

    & > ${Page},
    & > ${Flex},
    & > ${Grid} {
        flex-grow: 1;
        flex-basis: 0;
    }
`

export default Content
