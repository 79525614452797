import styled from 'styled-components'
import React, { ReactElement } from 'react'
import Text from '../texts/Text'
import Flex, { FlexProps } from '../layout/Flex'

export interface FormControlProps extends FlexProps {
    children: ReactElement | ReactElement[]
    color?: string // STUPID
    error?: string
    flexDirection?: 'column' | 'column-reverse' | 'row' | 'row-reverse'
    label?: string
    childrenWidth?: string
}

const Label = styled.label``

export function FormControl({ children, error, label, ...props }: FormControlProps) {
    const errorHeight = '20px'
    return (
        <Flex
            flexDirection="column"
            alignItems="baseline"
            {...props}
            as={Label}
        >
            <Text
                mb={props?.flexDirection?.includes?.('row') ? errorHeight : '5px'}
                width="100%"
            >
                {label || ' '}
            </Text>
            <Flex
                flexDirection="column"
                width={props.childrenWidth ?? '100%'}
            >
                {children}
                <Text minHeight={errorHeight} size="small" color="danger">{error}</Text>
            </Flex>
        </Flex>
    )
}
