import { useAgerasApi } from 'ageras-api-client/src'
import { RatingResource, RatingResult, RatingsApiFetchParamCreator } from 'ageras-api-client/src/api'
import Button from 'components/src/Button'
import Flex from 'components/src/layout/Flex'
import Logo from 'components/src/Logo'
import Rating from 'components/src/Rating'
import Side, { SideProps } from 'components/src/Side'
import Text from 'components/src/texts/Text'
import { useCallback } from 'react'
import { X } from 'react-feather'
import styled from 'styled-components'
import getBasicAuth from '../../../utils/getBasicAuth'
import { useTranslation } from 'react-i18next'

const Content = styled(Flex)`

`

const Header = styled(Flex)`
    position: relative;
`

const CloseButton = styled(Button)`
    position: absolute;
    right: 30px;
    top: 30px;
    padding: 6px;
    height: auto;
    width: auto;
`

const OpaqueText = styled(Text)`
    opacity: .5;
`

const ReviewRow = styled(Flex)`
    border-top: solid 2px ${({ theme: { colors } }) => colors.lightGrey};
`

const readableDate = (date: string) => {
    return new Date(date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })
}

interface PartnerReviewsSideProps extends SideProps {
    open: boolean
    hide: () => void
    partnerLogo?: string
    partnerName: string
    partnerId: string
    ratingScore: number
    ratingCount: number
}

function PartnerRatingSide(props: PartnerReviewsSideProps) {
    const { t } = useTranslation([ 'common', 'searchResultList' ])

    const { hide, partnerLogo, partnerName, partnerId, ratingScore, ratingCount } = props

    const handleCloseClick = useCallback(() => {
        hide()
    }, [ hide ])

    const { isLoading, data } = useAgerasApi<RatingResult>(
        RatingsApiFetchParamCreator().ratingsIndex(
            partnerId
        ),
        { meta: { authorization: getBasicAuth() } }
    )

    if (isLoading) {
        <Side {...props}>
            <Flex width="100%" height="100%" alignItems="center" justifyContent="center">
                <OpaqueText size="small" fontWeight="bold">
                    {t('common:loading')}
                </OpaqueText>
            </Flex>
        </Side>
    }

    const reviews = data?.data

    return (
        <Side {...props}>
            <Content flexDirection="column">
                <Header px={6} py={2} flexDirection="column">
                    <CloseButton variant="secondary" onClick={handleCloseClick}>
                        <X size={25} />
                    </CloseButton>
                    <Flex alignItems="center" pt={6} mb={3}>
                        <Logo imageUrl={partnerLogo} name={partnerName} size="large" />
                        <Text size="large" fontWeight="bold" color="impact" ml={2}>{partnerName}</Text>
                    </Flex>
                    <Flex justifyContent="center" flexDirection="column">
                        <Rating size="medium" ratingValue={ratingScore} />
                        <Flex alignItems="flex-end" mt={2}>
                            <Text color="impact" fontWeight="bold" size="large">
                                {ratingScore}
                                {' '}
                                {t('searchResultList:stars')}
                            </Text>
                            <Text color="impact" size="small" ml={2}>
                                {ratingCount}
                                {' '}
                                {t('searchResultList:rating_side.reviews_total')}
                            </Text>
                        </Flex>
                    </Flex>
                </Header>
                <Flex flexDirection="column" px={6} py={2}>
                    {reviews && reviews.length > 0 && reviews.map((review: RatingResource, index: number) =>
                        <ReviewRow key={index} flexDirection="column" py={2}>
                            <Text size="small" color="impact" mb={2}>{review.name}</Text>
                            <Flex alignItems="center" mb={2}>
                                <Rating ratingValue={review.stars || 0} size="extraSmall" />
                                {review.createdAt && <OpaqueText size="small" color="impact" ml={2}>{readableDate(review.createdAt)}</OpaqueText>}
                            </Flex>
                            <Text size="small" color="impact">{review.comment}</Text>
                        </ReviewRow>
                    )}
                    {(!reviews || reviews.length === 0) && <Flex minHeight="60px">
                        <OpaqueText size="small" fontWeight="bold">{t('searchResultList:rating_side.no_reviews')}</OpaqueText>
                    </Flex>}
                </Flex>
            </Content>
        </Side>
    )
}

export default PartnerRatingSide
