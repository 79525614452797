import { useAgerasApi } from 'ageras-api-client/src'
import { IndustriesApiFetchParamCreator, IndustryResource, IndustryResult } from 'ageras-api-client/src/api'
import SelectStepForm from 'components/src/MultiStepForm/SelectStepForm'
import { Option } from 'components/src/MultiStepForm/SelectStepForm/Option'
import { useCallback } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { getQueryPreselectedOption, getStepUrl, SetupStepProps } from '.'
import getBasicAuth from '../../utils/getBasicAuth'
import { useTranslation } from 'react-i18next'

function IndustryStep(props: SetupStepProps) {
    const { step, geoCode } = props
    const [ queryParameters ] = useSearchParams()
    const navigate = useNavigate()
    const { t } = useTranslation('searchSetup')

    const handleSubmit = useCallback((option: Option) => {
        queryParameters.set(step.queryParameterName, option.id.toString())

        navigate({
            pathname: getStepUrl(step.nextStepId || 1),
            search: queryParameters.toString(),
        })
    }, [ navigate, queryParameters, step.nextStepId, step.queryParameterName ])

    const { isLoading, data: industries } = useAgerasApi<IndustryResult>(
        IndustriesApiFetchParamCreator().industriesIndex(
            undefined,
            undefined,
            geoCode,
        ),
        {
            onSuccess: data => {
                if (data?.data?.length === 1) {
                    handleSubmit({ id: data.data[0].id!, name: data.data[0].name! })
                }
            },
            meta: { authorization: getBasicAuth() }
        },
    )

    const options = industries?.data == null
        ? []
        : industries?.data.map((industry: IndustryResource) => ({ id: industry.id, name: industry.name } as Option))

    const selectedOption = getQueryPreselectedOption(step, options, queryParameters)

    return (
        <SelectStepForm
            header={t('industry_header')}
            subheader={t('industry_subheader')}
            submit={handleSubmit}
            options={options}
            actionButtonText={t('select')}
            loading={isLoading}
            preselectedOption={selectedOption}
        />
    )
}

export default IndustryStep
