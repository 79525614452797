import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAgerasApiMutation } from 'ageras-api-client/src'
import { FormResponseResource, FormsApiFetchParamCreator } from 'ageras-api-client/src/api'
import { removeGuestUser } from '../../utils/guestUser'
import useLocalStorage from '../../utils/useLocalStorage'
import { useNavigate } from 'react-router-dom'
import Page, { Graphics } from 'components/src/layout/Page'
import Flex from 'components/src/layout/Flex'
import Loader from 'components/src/Loader'

interface SubmissionRouteParams {
    caseId: string
}

function Submission() {
    const [ loading, setLoading ] = useState<boolean>(false)
    const { caseId } = useParams<keyof SubmissionRouteParams>() as SubmissionRouteParams
    const [ formResponse ] = useLocalStorage<FormResponseResource | null>('form.response', null)
    const [ submitLeadForm, setSubmitLeadForm ] = useLocalStorage<string>('submitLeadForm', '')
    const navigate = useNavigate()

    const slawoFormMutation = useAgerasApiMutation<FormResponseResource>(
        (actionData: FormResponseResource) => FormsApiFetchParamCreator().formsResponsesCreate(String(actionData.id), actionData),
    )

    const submitData = useCallback(async () => {
        setLoading(true)
        try {
            if (formResponse) {
                formResponse.leadId = Number(caseId)
                await slawoFormMutation.mutateAsync(formResponse)
            }
            setSubmitLeadForm('')
            navigate(`/cases/${caseId}/information`)
        } catch (error) {
            navigate('/cases')
        } finally {
            removeGuestUser()
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ formResponse, navigate, caseId ])

    useEffect(() => {
        if (submitLeadForm === '1') {
            submitData()
        }
    }, [ submitData, submitLeadForm ])

    return <Page variant="grey">
        <Graphics graphics="primary" />
        <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
            {loading && <Loader/>}
        </Flex>
    </Page>
}

export default Submission
