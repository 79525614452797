import React from 'react'
import { useAuth } from 'ageras-api-client/src/auth'
import Page, { Graphics } from 'components/src/layout/Page'
import Flex from 'components/src/layout/Flex'
import Text from 'components/src/texts/Text'
import PartnerSearchCard from './PartnerSearchCard'
import ApplyNowSection from './ApplyNowSection'
import SearchFiltersSection from './SearchFiltersSection'
import AppliedSearchFiltersSection from './AppliedSearchFiltersSection'
import {
    IndustriesApiFetchParamCreator,
    IndustryResult,
    LocationsApiFetchParamCreator,
    PartnersApiFetchParamCreator,
    PartnerBusinessUnitResource,
    PartnerBusinessUnitResult,
} from 'ageras-api-client/src/api'
import { useAgerasApi } from 'ageras-api-client/src'
import getBasicAuth from '../../utils/getBasicAuth'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import useIntersectionObserver from '../../utils/useIntersectionObserver'
import styled from 'styled-components'
import { WithSidebar } from '../../Sidebar'
import TopBar from 'components/src/TopBar'
import { MenuItem } from 'components/src/TopBar/MenuItem'
import Icon from 'components/src/icons/Icon'
import { ChevronLeft } from 'react-feather'
import { partnersSearchParams, partnersSearchArrangeParam } from '../../utils'
import PartnerDetailsSide from './PartnerDetailsSide'
import ArrangeBySection, { ArrangeBySectionProps } from './ArrangeBySection'
import PartnerRatingSide from './PartnerRatingSide'
import { SearchBar } from 'components/src/SearchBar'
import { setGuestUser } from '../../utils/guestUser'
import { useTranslation } from 'react-i18next'

interface CardsWrapperProps {
}

const CardsWrapper = styled(Flex)<CardsWrapperProps>`
    max-width: 1300px;
`

function useIndustries<T>(query: string, primaryIndustryId?: string, geoCode?: string, industryId?: string) {
    const { data } = useAgerasApi<T>(
        IndustriesApiFetchParamCreator().industriesIndex(
            industryId,
            undefined,
            geoCode,
            true,
            undefined,
            primaryIndustryId,
            undefined,
            undefined,
            query
        ),
        { meta: { authorization: getBasicAuth() } }
    )
    return data
}

function useLocations<T>(zipCode: string, geoCode?: string) {
    const { data } = useAgerasApi<T>(
        LocationsApiFetchParamCreator().locationsIndex(
            undefined,
            geoCode,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            zipCode
        ),
        { meta: { authorization: getBasicAuth() } }
    )
    return data
}

interface PartnersSearchResultListRouteParams {
    caseId: string
}

function PartnerSearchResultList() {
    const MAX_SELECTED_BUSINESS_UNITS = 3

    const { t } = useTranslation([ 'searchResultList', 'common' ])
    const [ selectedUnitIds, setSelectedUnitIds ] = React.useState<number[]>([])
    const [ isSwapping, setIsSwapping ] = React.useState<boolean>(false)
    const [ swappingToUnitId, setSwappingToUnitId ] = React.useState<null | number>(null)
    const [ selectedUnit, setSelectedUnit ] = React.useState<null | PartnerBusinessUnitResource>(null)
    const [ isPartnerSideOpen, setIsPartnerSideOpen ] = React.useState<boolean>(false)
    const [ isRatingOpen, setIsRatingOpen ] = React.useState<boolean>(false)
    const { caseId } = useParams<keyof PartnersSearchResultListRouteParams>() as PartnersSearchResultListRouteParams
    const [ parameters, setParameters ] = useSearchParams()
    const navigate = useNavigate()
    const primaryIndustryId = parameters.get(partnersSearchParams.industry) || undefined
    const childIndustryId = parameters.get(partnersSearchParams.childIndustry) ?? undefined
    const leadTypeId = parameters.get(partnersSearchParams.leadType) ?? undefined
    const revenue = parameters.get(partnersSearchParams.revenue) ?? undefined
    const auth = useAuth()
    const geoCode = parameters.get(partnersSearchParams.geoCode) || auth?.client?.geo?.code
    const zipCode = parameters.get('zip_code') || undefined

    const applyNowSectionRef = useRef<HTMLDivElement | null>(null)
    const entry = useIntersectionObserver(applyNowSectionRef?.current, {})
    const isApplyNowSectionVisible = !!entry?.isIntersecting

    const { isLoading, data } = useAgerasApi<PartnerBusinessUnitResult>(
        PartnersApiFetchParamCreator().partnersBusinessunitsIndex(
            undefined,
            undefined,
            geoCode,
            undefined,
            undefined,
            true,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            childIndustryId,
            leadTypeId,
            revenue,
        ),
        { meta: { authorization: getBasicAuth() } }
    )

    const childIndustryData = useIndustries<IndustryResult>('', primaryIndustryId, geoCode, childIndustryId)

    const availableUnits = data?.data

    useEffect(() => {
        if (availableUnits == null) {
            return
        }

        const preselectedUnitIds = availableUnits
            .slice(0, MAX_SELECTED_BUSINESS_UNITS)
            .map((unit: PartnerBusinessUnitResource) => unit.id as number)

        setSelectedUnitIds(preselectedUnitIds)
    }, [ availableUnits, setSelectedUnitIds ])

    const handleUnitSelect = useCallback((offerId: number) => {
        if (selectedUnitIds.includes(offerId)) {
            return
        }

        if (selectedUnitIds.length >= MAX_SELECTED_BUSINESS_UNITS) {
            setIsSwapping(true)
            setSwappingToUnitId(offerId)

            return
        }

        setSelectedUnitIds(selectedUnitIds => [ ...selectedUnitIds, offerId ])
    }, [ selectedUnitIds ])

    const handleSelectedUnitSwap = useCallback((swapFromUnitId: number) => {
        if (swappingToUnitId == null) {
            return
        }

        const swapFromIndex = selectedUnitIds.findIndex(index => index === swapFromUnitId)

        setSelectedUnitIds(selectedUnitIds => {
            selectedUnitIds.splice(swapFromIndex, 1, swappingToUnitId)

            return selectedUnitIds
        })

        setIsSwapping(false)
    }, [ selectedUnitIds, swappingToUnitId ])

    const handleCancelSwap = useCallback(() => {
        setIsSwapping(false)
        setSwappingToUnitId(null)
    }, [])

    const handleRevenueFilterRemoval = useCallback(() => {
        parameters.set(partnersSearchParams.search, '')

        setParameters(parameters)
    }, [ parameters, setParameters ])

    const handleArrangeChange = useCallback<ArrangeBySectionProps['handleArrangeChange']>(sort => {
        parameters.set(partnersSearchArrangeParam, sort)
        setParameters(parameters)
    }, [ setParameters, parameters ])

    const submit = useCallback(() => {
        setGuestUser({
            geoCode,
            annualRevenue: revenue,
            zipCode,
            primaryIndustryId,
            childIndustryId,
            leadTypeId,
        })
        navigate('/signup')
    }, [ navigate, childIndustryId, geoCode, leadTypeId, primaryIndustryId, revenue, zipCode ])

    const handleRedirectBackClick = useCallback((): void => {
        navigate(-1)
    }, [ navigate ])

    const handleShowPartnerDetails = useCallback((unit: PartnerBusinessUnitResource) => {
        setSelectedUnit(unit)
        setIsPartnerSideOpen(true)
    }, [])

    const handleHidePartnerDetails = useCallback(() => {
        setSelectedUnit(null)
        setIsPartnerSideOpen(false)
    }, [])

    const handleHideRating = useCallback(() => {
        setIsRatingOpen(false)
        setSelectedUnit(null)
    }, [])

    const handleShowRating = useCallback((unit: PartnerBusinessUnitResource) => {
        setSelectedUnit(unit)
        setIsRatingOpen(true)
    }, [])

    const shouldDisplayFloatingApplyNowButton = !isApplyNowSectionVisible && !isPartnerSideOpen && !isRatingOpen

    const onSearch = useCallback<Parameters<typeof SearchBar>[0]['onSearch']>(({ childIndustry, location }) => {
        if (childIndustry) {
            parameters.set('query', String(childIndustry.name))
            parameters.set('child_industry_id', String(childIndustry.id))
        }
        if (location) {
            parameters.set('zip_code', String(location.zipCode))
            parameters.set('lat', String(location.point?.lat))
            parameters.set('lon', String(location.point?.lon))
        }
        setParameters(parameters)
    }, [ parameters, setParameters ])

    const selectedChildIndustry = childIndustryData?.data?.find(i => String(i.id) === childIndustryId)

    return (
        <WithSidebar>
            <Page>
                <Graphics graphics="primary" />
                <TopBar>
                    <MenuItem
                        label={t('common:back')}
                        onClick={handleRedirectBackClick}
                        iconLeft={<Icon mr={2} variant="accent">
                            <ChevronLeft size={22}/>
                        </Icon>}
                    />
                </TopBar>
                <SearchBar
                    onSearch={onSearch}
                    useQueryChange={useIndustries}
                    useZipCodeChange={useLocations}
                    primaryIndustryId={primaryIndustryId}
                    query={selectedChildIndustry?.name || parameters.get('query') || ''}
                    zipCode={parameters.get('zip_code') || ''}
                    geoCode={geoCode}
                >
                    <>
                        <Flex ml="40px"/>
                        <ArrangeBySection handleArrangeChange={handleArrangeChange} />
                        <SearchFiltersSection />
                    </>
                </SearchBar>
                <CardsWrapper
                    p={6}
                    mx="auto"
                    flexDirection="column"
                >
                    {isLoading && <Text>{t('common:loading')}</Text>}
                    {!Boolean(availableUnits?.length) && !isLoading && <Text>{t('searchResultList:no_offers')}</Text>}
                    {availableUnits != null && <Flex flexDirection="column">
                        <AppliedSearchFiltersSection handleRevenueFilterRemoval={handleRevenueFilterRemoval} />
                        {availableUnits.filter(unit => unit.id && unit.partner && selectedUnitIds.includes(unit.id)).map(unit => (
                            <PartnerSearchCard
                                key={`case_partner_card_${unit.id}`}
                                unit={unit}
                                caseId={caseId}
                                isSelected={true}
                                isSwapping={isSwapping}
                                select={handleUnitSelect}
                                swap={handleSelectedUnitSwap}
                                cancelSwap={handleCancelSwap}
                                showPartnerDetails={handleShowPartnerDetails}
                                showPartnerRating={handleShowRating}
                            />
                        ))}
                        {!isSwapping && <>
                            {shouldDisplayFloatingApplyNowButton &&
                                <ApplyNowSection
                                    floating={true}
                                    apply={submit}
                                    key="floating"
                                    selectedCount={selectedUnitIds.length}
                                />
                            }
                            <Flex mb={6}>
                                <ApplyNowSection
                                    ref={applyNowSectionRef}
                                    apply={submit}
                                    key="non-floating"
                                    selectionsFull={selectedUnitIds.length === MAX_SELECTED_BUSINESS_UNITS}
                                    selectedCount={selectedUnitIds.length}
                                />
                            </Flex>
                            {availableUnits.filter(unit => unit.id && unit.partner && !selectedUnitIds.includes(unit.id)).map(unit => (
                                <PartnerSearchCard
                                    key={`case_partner_card_${unit.id}`}
                                    unit={unit}
                                    caseId={caseId}
                                    isSelected={false}
                                    isSwapping={isSwapping}
                                    selectionsFull={selectedUnitIds.length === MAX_SELECTED_BUSINESS_UNITS}
                                    select={handleUnitSelect}
                                    swap={handleSelectedUnitSwap}
                                    cancelSwap={handleCancelSwap}
                                    showPartnerDetails={handleShowPartnerDetails}
                                    showPartnerRating={handleShowRating}
                                />
                            ))}
                        </>}
                        {isSwapping && availableUnits.filter(unit => unit.partner && (unit.id === swappingToUnitId)).map(unit =>
                            <PartnerSearchCard
                                key={`case_partner_card_${unit.id}`}
                                unit={unit}
                                caseId={caseId}
                                isSelected={false}
                                isSwapping={isSwapping}
                                select={handleUnitSelect}
                                swap={handleSelectedUnitSwap}
                                cancelSwap={handleCancelSwap}
                                showPartnerDetails={handleShowPartnerDetails}
                                showPartnerRating={handleShowRating}
                            />
                        )}
                    </Flex>}
                </CardsWrapper>
                {selectedUnit &&
                    <PartnerDetailsSide
                        openWidth={600}
                        open={isPartnerSideOpen}
                        hide={handleHidePartnerDetails}
                        unit={selectedUnit}
                        showRating={handleShowRating}
                    />
                }
                {selectedUnit &&
                    <PartnerRatingSide
                        openWidth={600}
                        partnerLogo={selectedUnit.partner?.logo}
                        partnerName={selectedUnit.partner?.companyName || ''}
                        partnerId={String(selectedUnit.partner?.id) as string}
                        ratingScore={selectedUnit.partner?.rating?.score || 0}
                        ratingCount={selectedUnit.partner?.rating?.count || 0}
                        hide={handleHideRating}
                        open={isRatingOpen}
                    />
                }
            </Page>
        </WithSidebar>
    )
}

export default PartnerSearchResultList
