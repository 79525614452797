import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useCallback } from 'react'
import styled, { css } from 'styled-components'
import Flex from '../../layout/Flex'
import Text from '../../texts/Text'
import StepForm, { StepFormWrapperProps, SubmitButton } from '../StepForm'
import OptionElement, { Option } from './Option'

interface OptionsContainerProps {
    columns: number
}

const OptionsContainer = styled.div<OptionsContainerProps>`
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    display: grid;
    grid-auto-flow: row;
    grid-column-gap: 12px;
    grid-row-gap: 9px;
    grid-auto-rows: 1fr;

    ${({ columns }) => columns && css`
        --grid-column-count: ${columns};
        --gap-count: calc(var(--grid-column-count) - 1);
        --total-gap-width: calc(var(--gap-count) * 12px);
        --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));
        grid-template-columns: repeat(auto-fill, minmax(max(300px, var(--grid-item--max-width)), 1fr));
    `}
`

export interface SelectStepFormProps extends StepFormWrapperProps {
    select?: (option: Option) => void
    deselect?: () => void
    submit?: (option: Option) => void
    options: Option[]
    actionButtonText?: string
    preselectedOption?: Option
    columns?: number
}

function SelectStepForm(props: SelectStepFormProps) {
    const {
        select,
        deselect,
        submit,
        options,
        header,
        subheader,
        actionButtonText = 'Search Now',
        loading = false,
        preselectedOption,
        columns = 2,
    } = props

    const [ selectedOption, setSelectedOption ] = useState<Option|null>(null)

    const isOptionSelected = useCallback((option: Option): boolean => selectedOption?.id === option.id, [ selectedOption ])

    const selectOption = useCallback((option: Option) => {
        setSelectedOption(option)

        if (select != null) {
            select(option)
        }
    }, [ setSelectedOption, select ])

    const deselectOption = useCallback(() => {
        setSelectedOption(null)

        if (deselect != null) {
            deselect()
        }
    }, [ setSelectedOption, deselect ])

    const handleSelect = useCallback((option: Option) => {
        return () => isOptionSelected(option) ? deselectOption() : selectOption(option)
    }, [ isOptionSelected, deselectOption, selectOption ])

    const handleSubmit = useCallback(() => {
        if (selectedOption == null) {
            return
        }

        if (submit != null) {
            submit(selectedOption)
        }
    }, [ selectedOption, submit ])

    useEffect(() => {
        if (preselectedOption == null) {
            return
        }

        setSelectedOption(preselectedOption)
    }, [ preselectedOption ])

    return (
        <StepForm
            header={header}
            subheader={subheader}
        >
            {loading && <Flex justifyContent="center">
                <Text size="small">Loading...</Text>
            </Flex>}
            {!loading && <>
                {options.length > 0 && <OptionsContainer columns={columns}>
                    {options.map(option =>
                        <OptionElement
                            key={`option_${option.id}`}
                            selected={isOptionSelected(option)}
                            select={handleSelect(option)}
                            option={option}
                        />
                    )}
                </OptionsContainer>}
                {options.length === 0 && <Flex justifyContent="center">
                    <Text size="small">List empty</Text>
                </Flex>}
            </>}
            <Flex mt={3} justifyContent="center" alignItems="center">
                <SubmitButton
                    onClick={handleSubmit}
                    variant="secondary"
                    disabled={selectedOption == null || loading}
                >
                    { actionButtonText }
                </SubmitButton>
            </Flex>
        </StepForm>
    )
}

export default SelectStepForm
