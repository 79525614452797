import React, { ReactNode } from 'react'
import { useAgerasApi } from 'ageras-api-client/src'
import { ContextProvider } from 'ageras-api-client/src/auth'
import { AuthApiFetchParamCreator, AuthResource } from 'ageras-api-client/src/api'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LogIn from './pages/LogIn'
import Flex from 'components/src/layout/Flex'
import Content from 'components/src/layout/Content'
import Heading from 'components/src/texts/Heading'

interface AuthProviderProps {
    children?: ReactNode
}

function AuthProvider(props: AuthProviderProps) {
    const [ searchParams, setSearchParams ] = useSearchParams()
    const { t } = useTranslation('common')

    // Log user out if /logout is the current pathname
    if (window.location.pathname === '/logout') {
        localStorage.removeItem('refresh_token')
        window.location.href = '/'
    }

    // Load auth using access_token query parameter
    const { data: accessTokenData, isLoading: accessTokenIsLoading } = useAgerasApi<AuthResource>(AuthApiFetchParamCreator().authCreate({
        token: searchParams.get('access_token') ?? undefined,
        userType: 'client',
    }), {
        enabled: Boolean(searchParams.get('access_token')),
        onSuccess: () => {
            searchParams.delete('access_token')
            setSearchParams(searchParams)
        },
        onError: (error) => {
            if ((error as unknown as Response)?.status === 403) {
                localStorage.removeItem('refresh_token')
            }
        },
        queryKey: [ 'auth' ],
    })
    // Load auth using refresh_token item in local storage
    const { data: refreshTokenData, isLoading: refreshTokenIsLoading } = useAgerasApi<AuthResource>(
        AuthApiFetchParamCreator({ apiKey: localStorage.getItem('refresh_token') ?? undefined }).authTokenUpdate({}  ),
        {
            enabled: !searchParams.get('access_token') && !accessTokenData && !accessTokenIsLoading && Boolean(localStorage.getItem('refresh_token')),
            queryKey: [ 'auth' ],
            onError: (error) => {
                if ((error as unknown as Response)?.status === 401) {
                    localStorage.removeItem('refresh_token')
                }
            },
        }
    )

    if (accessTokenIsLoading || refreshTokenIsLoading) {
        return (
            <Content variant="white" graphics="primary" as={Flex} justifyContent="center" alignItems="center">
                <Heading size="medium">{t('authenticating')}</Heading>
            </Content>
        )
    }

    if (accessTokenData === undefined && refreshTokenData === undefined) {
        return <LogIn/>
    }

    return <ContextProvider value={accessTokenData ?? refreshTokenData ?? {}}>
        {props.children}
    </ContextProvider>
}

export default AuthProvider
