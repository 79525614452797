import {
    useMutation,
    useQuery,
    UseMutationOptions,
    UseQueryOptions,
} from '@tanstack/react-query'
import { camelizeKeys, decamelizeKeys } from 'humps'
import {
    AuthResource,
    FetchArgs,
} from './api'
import { useAuth } from './auth'

async function queryApi<ApiResult>(fetchArgs: FetchArgs, headers: Headers) {
    const response = await fetch(process.env.REACT_APP_AGERAS_API_URL + fetchArgs.url, {
        ...fetchArgs.options,
        body: fetchArgs.options.body && JSON.stringify(decamelizeKeys(JSON.parse(fetchArgs.options.body))),
        headers,
    })

    if (response.status === 204) {
        return {} as ApiResult
    }

    const json = camelizeKeys(await response.json()) as unknown as Readonly<ApiResult>

    if (response.status < 200 || response.status >= 300) {
        throw json
    }

    if ([ '/auth', '/auth/token' ].includes(fetchArgs.url.split('?')[0]) && [ 'POST', 'PUT' ].includes(fetchArgs.options.method)) {
        localStorage.setItem('refresh_token', (json as AuthResource).token || '')
    }

    return json
}

function useAgerasApi<ApiResult>(fetchArgs: FetchArgs, options?: UseQueryOptions<ApiResult, Error>) {
    const auth: AuthResource = useAuth()

    const headers = new Headers()
    if (auth?.token) {
        headers.set('Authorization', `Bearer ${auth.token}`)
    }
    if (options?.meta?.authorization) {
        headers.set('Authorization', options.meta.authorization as string)
    }

    return useQuery<ApiResult, Error>(
        options?.queryKey ?? [ fetchArgs.url, fetchArgs.options ],
        async () => queryApi<ApiResult>(fetchArgs, headers),
        options
    )
}

function useAgerasApiMutation<ApiResource>(mutation: (resource: ApiResource) => FetchArgs, options?: UseMutationOptions<ApiResource, Error, ApiResource>) {
    const auth: AuthResource = useAuth()

    const headers = new Headers()
    if (auth?.token) {
        headers.set('Authorization', `Bearer ${auth.token}`)
    }
    if (options?.meta?.authorization) {
        headers.set('Authorization', options.meta.authorization as string)
    }

    return useMutation<ApiResource, Error, ApiResource, any>(
        [],
        async data => queryApi<ApiResource>(mutation(data), headers),
        options
    )
}

export {
    useAgerasApi,
    useAgerasApiMutation,
}
