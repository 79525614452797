import styled from 'styled-components'
import { variant } from 'styled-system'

type HeaderTypes = 'base' | 'primary'

interface HeaderProps {
    variant?: HeaderTypes
}

const Header = styled.div<HeaderProps>`
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;

    ${variant({
        scale: 'headers.variants',
    })}
`

Header.defaultProps = {
    variant: 'base',
}

export default Header
