import styled, { css } from 'styled-components'
import { variant, VariantArgs, space, SpaceProps, typography, TypographyProps } from 'styled-system'
import { ReactNode } from 'react'
import { TextSizeTypes, TextColorTypes } from '../index'
import Flex from '../../layout/Flex'

export interface TextProps extends VariantArgs, SpaceProps, TypographyProps {
    size?: TextSizeTypes
    color?: TextColorTypes
    bold?: boolean
    underline?: boolean
    children: ReactNode
}

const Text = styled(Flex).attrs({ as: styled.span`` })<TextProps>`
    display: inline-block;
    vertical-align: baseline;
    ${space};
    ${typography};

    ${({ bold }) => bold && css`
        font-weight: 700;
    `}

    ${({ underline }) => underline && css`
        text-decoration: underline;
    `}

    ${variant({
        prop: 'size',
        scale: 'text.sizes',
    })}

    ${variant({
        prop: 'color',
        scale: 'text.colors',
    })}
`

Text.defaultProps = {
    color: 'default',
    size: 'medium',
}

export default Text
