import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react'
import Button from 'components/src/Button'
import Flex from 'components/src/layout/Flex'
import styled from 'styled-components'
import Card from 'components/src/Card'

interface MessageFormProps {
    onSubmit: (text: string) => void
}

const MessageInput = styled.textarea`
    outline: none;
`

function MessageForm({ onSubmit }: MessageFormProps) {
    const [ message, setMessage ] = useState<string>('')

    const handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void = useCallback(event => {
        setMessage(event.currentTarget.value)
    }, [])

    const handleSubmit: (event: FormEvent) => void = useCallback(event => {
        onSubmit(message)
        setMessage('')
        event.preventDefault()
    }, [ message, onSubmit ])

    return <form onSubmit={handleSubmit}>
        <Card.Line/>
        <Flex alignItems="center" p={2}>
            <Flex
                as={MessageInput}
                value={message}
                onChange={handleChange}
                resize="none"
                flexGrow={1}
                border="none"
                p={1}
                placeholder="Type something..."
            />
            <Button type="submit" variant="secondary" disabled={!message}>Send</Button>
        </Flex>
    </form>
}

export default MessageForm
